import React, { useState } from 'react';
import './HomePage.css';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import SearchInput from './SearchByName/SearchInput';
import Recommendations from './Recomendation/Recommendations.js';
import { useDispatch, useSelector } from 'react-redux';
import BannerActionButtons from './bannerActionButtons/BannerActionButtons.js';
import Popin from './Recomendation/popin/Popin.js';
const strings = new LocalizedStrings(localeFile);

function Mainbanner(props) {
  strings.setLanguage(props.languageCode);

  const isButtonsVisible = useSelector(state => state.isButtonsVisible);
  const isRecommendationVisible = useSelector(state => state.isRecommendationVisible);
  const isSearchInputVisible = useSelector(state => state.isSearchInputVisible);
  const isSubCategorySelected = useSelector(state => state.isSubCategorySelected);
  const [searching, setSearching] = useState(false);
  const [subCategory, setsubCategory] = useState();




  return (
    <section className='main-banner'  loading="lazy">
        <div className='main-banner__content'>
          {!isSubCategorySelected && <div className='main-banner__content-title'>
            <h1 className='main-banner__title'>{strings.mainBanner.title}  </h1>
          </div>
          }

          {isButtonsVisible  && <div className={`main-banner__buttons_container  ${isButtonsVisible && !isRecommendationVisible && !isSearchInputVisible ? 'show' : 'hide'}`}>

            <BannerActionButtons
              strings={strings}

            />
          </div>}
          {isSearchInputVisible &&
            <div className={`search-container ${isSearchInputVisible      ? 'show' : 'hide'}`}>
              <SearchInput setSearching={setSearching} show={true}  />
            </div>}

           {isRecommendationVisible &&
            <Recommendations

              subCategory={subCategory}
              setsubCategory={setsubCategory}
            />
            // <Popin/>
          }



        </div>
    
      <div className='main-banner-container-text'>
        <p>{strings.mainBanner.description}</p>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  languageCode: state.languageCode,
});

export default connect(mapStateToProps)(Mainbanner);
