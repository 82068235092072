import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams, useNavigate  } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import './DetailBlog.css';
import localeFile from "../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import { baseURL, apiBlog } from '../../../api/api.js';
import userInteractionTracker from '../../../Google Analytics/userInteractionTracker.js';


const strings = new LocalizedStrings(localeFile);
function BlogDetails({ languageCode }) {

  const { trackButtonClick } = userInteractionTracker();

  strings.setLanguage(languageCode);
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();
  const fetchDetailBlogs = async (id) => {
    if (id) {
      try {
        const response = await apiBlog.getBlog(id);
        setBlog(response.data.blog);
      } catch (error) {
        console.error('Failed to fetch blog:', error);
      }
    }
  };
  useEffect(() => {
    fetchDetailBlogs(id);
  }, [id]);
  useEffect(() => {
    if (blog && blog.language !== languageCode) {
      if (languageCode === "fr") {
        navigate(`/fr/Articles/`);
  
  
      } else if (languageCode === "en") {
        navigate(`/en/Blogs/`);
  
  
      }
    }
  }, [blog, languageCode, navigate]);
  const formatContent = (content) => {
    const formattedContent = content.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); // Formatting the content
    return formattedContent.split('\n').map((line, index) => (
        <span key={index}>
            <span dangerouslySetInnerHTML={{ __html: line }} />
            <br />
        </span>
    ));
  }
  const blogUrl = blog ? `${baseURL}Blog-Detail/${id}` : '';
  const title = blog?.titre || "Check this out!";
  const summary = blog?.contenu ? `${blog.contenu.slice(0, 100)}...` : "";
  const imageUrl = blog?.interiorImage || '';
  if (!blog) {
    return <div>Loading...</div>;
  }
  const formatDate = (dateStr, lang) => {
    const date = new Date(dateStr);
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString(lang === 'en' ? 'en-US' : 'fr-FR', options);
    const cleanedDate = formattedDate.replace(/,/, '');
    if (lang === 'fr') {
      const parts = cleanedDate.split(' ');
      const capitalizedParts = parts.map((part, index) => {
        if (index === 0 || index === 2) {
          return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
        return part;
      });
      return capitalizedParts.join(' ');
    }
    return cleanedDate;
  };
  return (
    <div className="pagedetails-container">
      <Helmet>
        <title>{title}</title>
        {/* Open Graph / Facebook meta tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={summary} />
        <meta property="og:url" content={blogUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:alt" content={title} />
        {/* LinkedIn meta tags */}
        <meta name="title" property="og:title" content={title}></meta>
        <meta property="og:type" content="Article"></meta>
        <meta name="image" property="og:image" content={imageUrl}></meta>
        <meta name="description" property="og:description" content={summary}></meta>
        <meta name="author" content="[Author name here]"></meta>
        {/* Twitter meta tags */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={summary} />
        <meta name="twitter:url" content={blogUrl} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="blog-details-container">
        <div className="bannerdetails-section">
          <div className="bannerdetails-content">
            <div className="blogdetails-tags isNotMobile">
              {blog.tags?.map((tag, index) => (
                <a key={index} href="#">{tag}</a>
              ))}
            </div>
            <h1 className="bannerdetails-title isNotMobile">{blog.titre}</h1>
            <div className="authordetails-container isNotMobile">
              <div className="articledetails-meta">
                <img src={blog.authorImage} alt="Author" className="authordetails-image" />
                <span className="authordetails-name">{blog.auteur}</span>
                <span className="publishdetails-date">{formatDate(blog.dateAjout, languageCode)}</span>
              </div>
            </div>
            <div className="flexdetails-container">
              <div className="sharedetails-section">
                <div className="socialdetails-media-buttons">
                  <FacebookShareButton
                    url={blogUrl}
                    quote={`${title}: ${summary}`}
                    className="sharedetails-button"
                    onClick={() => trackButtonClick('Social Share', 'Facebook Share', 'Blog Details')}>
                    <FacebookIcon size={25} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={blogUrl}
                    title={title}
                    hashtags={["blog"]}
                    className="sharedetails-button"
                    onClick={() => trackButtonClick('Social Share', 'Twitter Share', 'Blog Details')}>
                    <TwitterIcon size={25} />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={blogUrl}
                    title={title}
                    summary={summary}
                    source={baseURL}
                    className="sharedetails-button"
                    onClick={() => trackButtonClick('Social Share', 'LinkedIn Share', 'Blog Details')}>
                    <LinkedinIcon size={25} />
                  </LinkedinShareButton>
                </div>
              </div>
              <div className="imagedetails-column">
                <img
                  className="blogdetails-image"
                  src={blog.interiorImage}
                  alt="Slogan Illustration"
                />
              </div>
              {/* <div className="statsdetails-section">
                <div className="likes">
                  <i className="fas fa-heart" style={{ fontSize: '25px', color: 'pink' }}></i>
                  <span>{blog.likes || "0"}</span>
                </div>
                <div className="views">
                  <i className="fas fa-eye" style={{ fontSize: '25px', color: 'black' }}></i>
                  <span>{blog.views || "0"}</span>
                </div>
              </div> */}
            </div>
            <div className="blogdetails-tags isMobile">
              {blog.tags?.map((tag, index) => (
                <a key={index} href="#">{tag}</a>
              ))}
            </div>
            <h1 className="bannerdetails-title isMobile">{blog.titre}</h1>
            <div className="authordetails-container isMobile">
              <div className="articledetails-meta">
                <img src={blog.authorImage} alt="Author" className="authordetails-image" />
                <span className="authordetails-name">{blog.auteur}</span>
                <span className="publishdetails-date">{formatDate(blog.dateAjout, languageCode)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="contentdetails-container">
          <div className="contentdetails-row">
            <div className="contentdetails-column">
              <article className="blogdetails-article">
                <p>{formatContent(blog.contenu)}</p>
              </article>
            </div>
          </div>
        </div>
        {/* <div className="relateddetails-blogs">
          <div className="populardetails-blogs-container">
            <div className="titledetails-container">
              <h2 className="titledetails">{strings.detailBlog.popular}</h2>
              <div className="showdetails-all">
                <a href="#">{strings.detailBlog.displayButton}</a>
              </div>
            </div>
            <div className="blogdetails-section">
              <div className="big-blogdetails-card">
                <img src={blog.previewImage} alt="Big Blog" className="big-blogdetails-image" />
                <div className="big-blogdetails-content">
                  <p className="big-blogdetails-author">{strings.detailBlog.author}: Claire Devaux</p>
                  <h2 className="big-blogdetails-title">Personnalisation et Recommandations : Comment l'IA Transforme le Parcours d'Achat</h2>
                </div>
              </div>
              <div className="small-blogsdetails">
                {[
                  { author: 'Marc LeMoine', title: 'Nombre moyen de sites consultés avant un achat' },
                  { author: 'Sophie Laroche-Bellevue', title: 'La fatigue décisionnelle dans les choix impliquants' },
                  { author: 'Barry Schwartz', title: 'Quand Trop d’Options Tue la Décision : Comprendre l\'Anxiété de Choix' },
                  { author: 'Louis Garnier', title: 'L’abondance des plateformes de décision : aide ou surcharge ?' }
                ].map((blog, index) => (
                  <div className="blogdetails-card" key={index}>
                    <p className="small-blogdetails-author">{blog.author}</p>
                    <h2 className="small-blogdetails-title">{blog.title}</h2>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  languageCode: state.languageCode,
});
export default connect(mapStateToProps)(BlogDetails);