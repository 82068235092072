export const LANG_ACTION = 'LANG_ACTION';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SEARCH_RECOMMEND = 'SET_SEARCH_RECOMMEND';
export const RESET_SEARCH_RECOMMEND = 'RESET_SEARCH_RECOMMEND';


export const RESET_SELECTED_PRODUCT = 'RESET_SELECTED_PRODUCT';
export const SET_SELECTED_PRODUCT_POINT_LIST = 'SET_SELECTED_PRODUCT_POINT_LIST';
export const RESET_SELECTED_PRODUCT_POINT_LIST = 'RESET_SELECTED_PRODUCT_POINT_LIST';



export const SET_SHOW_BUTTONS = 'SET_SHOW_BUTTONS';
export const SET_SHOW_RECOMMENDATION = 'SET_SHOW_RECOMMENDATION';
export const SET_SHOW_SEARCH_INPUT = 'SET_SHOW_SEARCH_INPUT';

export const SET_SUBCATEGORY_SELECTED = 'SET_SUBCATEGORY_SELECTED';


export const SET_SELECTED_CRITERE_LIST = 'SET_SELECTED_CRITERE_LIST';
export const RESET_SELECTED_CRITERE_LIST = 'RESET_SELECTED_CRITERE_LIST';

export const SET_RECOMMENDED_RESULT = 'SET_RECOMMENDED_RESULT';
export const RESET_RECOMMENDED_RESULT = 'RESET_RECOMMENDED_RESULT';


export const SET_LOADING = 'SET_LOADING';
export const RESET_LOADING = 'RESET_LOADING';



export const SET_RECOMMEND_RESULT_NUMBE = 'SET_RECOMMEND_RESULT_NUMBE';
export const RESET_RECOMMEND_RESULT_NUMBER = 'RESET_RECOMMEND_RESULT_NUMBER';