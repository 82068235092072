import React, { useEffect, useRef, useState } from 'react';
import { scroller } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import AboutUs from '../../components/sections/homePage/AboutUs/AboutUs';
import ClientVoice from '../../components/sections/homePage/ClientVoice/ClientVoice';
import Mainbanner from '../../components/sections/homePage/Mainbanner/Mainbanner';
import ResultSearchName from '../../components/sections/homePage/ResultSearchName/ResultSearchName';
import ResultRecommendation from '../../components/sections/homePage/ResultRecommendation/ResultRecommendation';
import OurMission from '../../components/sections/homePage/OurMissionVision/OurMission';
import OurService from '../../components/sections/homePage/OurServices/OurService';
import { resetSearchRecommend,setSubCategorySelected,resetSelectedcriteresLIST,RESET_RECOMMEND_RESULT_NUMBER, resetSelectedProduct, SET_LOADING, setSelectedProduct, setShowSearchInput } from '../../store/actions/actionCreators';
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import localeFile from "../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);
function HomePage(props) {
  strings.setLanguage(props.languageCode);
  const languageCode = props.languageCode;
  const selectedProductName = useSelector(state => state.selectedProductName);
  const [selectedProductNameLocal, setSelectedProductName] = useState(null);
  
  const loading = useSelector(state => state.loading);

  const searchRecommend = useSelector(state => state.searchRecommend);
  const sectionsRef = useRef([]);
  let scrolling = false;
  const isRecommendationVisible = useSelector(state => state.isRecommendationVisible);
  const location = useLocation();
  const navigate = useNavigate();

  const resultRef = useRef(null);
  const recommendRef = useRef(null);
  const homeref = useRef(null);
  const dispatch = useDispatch();
  


  if (selectedProductName && resultRef.current) {
    resultRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToSection = (sectionId) => {
    if (!scrolling) {
      scrolling = true;
      scroller.scrollTo(sectionId, {
        duration: 100,
        smooth: true,
        offset: 0,
      });
      setTimeout(() => (scrolling = false), 600); // Prevent repeated scrolling
    }
  };

  
  


  if (searchRecommend && recommendRef.current) {
    console.log('scroll')
    recommendRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    dispatch(resetSelectedProduct());
  }, [dispatch]);
  useEffect(() => {
    dispatch(setSubCategorySelected(false));
    dispatch(resetSearchRecommend());
    dispatch(resetSelectedcriteresLIST())
  }, [dispatch]);

  useEffect(() => {
    dispatch(RESET_RECOMMEND_RESULT_NUMBER());
  }, [dispatch]);
  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.3,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionIndex = sectionsRef.current.indexOf(entry.target);
          if (sectionIndex !== -1 && !scrolling) {
            scrollToSection(entry.target.id);
          }
        }
      });
    }, observerOptions);

    sectionsRef.current.forEach((section) => section && observer.observe(section));

    return () => observer.disconnect();
  }, []);


  useEffect(() => {
    if (!isRecommendationVisible) {
      document.body.classList.remove('no-scroll');
    }
  }, []);

  useEffect(() => {
    if(window.location.pathname=='/en'||window.location.pathname=='/fr'||window.location.pathname=='/' ){
      dispatch(resetSelectedProduct());
      dispatch(setSelectedProduct());
      dispatch(setShowSearchInput(false)); 
        homeref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [window.location.pathname]);


 useEffect(() => {

    const pathSegments = location.pathname.split('/');
    const productName = decodeURIComponent(pathSegments[pathSegments.length - 1] || '');
  
    if (productName && selectedProductName) {
      setSelectedProductName(productName);
    } 
  }, [selectedProductName] );
  return (
    <>
      {languageCode == 'fr' ? <Helmet>
        <title>Mayview - Avis Produits et Comparatifs pour des Achats Éclairés</title>
        <meta name="description" content="Explorez Mayview pour découvrir des comparatifs de produits, des conseils d'achat high-tech et des avis fiables. Prenez des décisions éclairées grâce à notre plateforme d'agrégation d'avis utilisateurs." />
        <meta name="keywords" content="Avis produits e-commerce, Comparatif de produits, Agrégateur d'avis utilisateurs, Conseils d'achat high-tech, Recherches collectives d’avis, Guide d'achat, outils de recommandation d'achat" />
      </Helmet> :
        <Helmet>
          <title>Mayview - Product Reviews & Comparison for Smarter Shopping </title>
          <meta name="description" content="Discover Mayview for reliable product reviews, comparisons, and purchase recommendations. Make informed shopping decisions with our user review aggregator." />
          <meta name="keywords" content=" Avis produits e-commerce , Comparatif de produits , Agrégateur d'avis utilisateurs, Conseils d'achat high-tech, Recherches collectives d’avis, Guide d'achat, outils de recommandation d'achat" />
        </Helmet>
      }

      <section className='homepage'>
        <div id="mainbanner" ref={homeref}>
          <Mainbanner />
        </div>
        <div id="result-section" ref={resultRef}>
          { selectedProductName &&<ResultSearchName /> }
        </div>
        <div id="recommend-section" ref={recommendRef}>
          {searchRecommend && <ResultRecommendation />}
        </div>
        <div id="about-us" >
          <AboutUs />
          <OurMission />
        </div>
        <div id="our-services-section" ref={(el) => (sectionsRef.current[2] = el)}>
          <OurService />
        </div>
        <div id="client-voice-section" ref={(el) => (sectionsRef.current[3] = el)}>
          <ClientVoice />
        </div>
      </section>   
      
       </>
  );
}


const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};
export default connect(mapStateToProps)(HomePage);