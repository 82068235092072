import React from 'react';
import Stroke from '../../../../assets/images/HomePage/Vector (1).webp'
import Stroke2 from '../../../../assets/images/HomePage/ticker-star-svgrepo-com 1.webp'
import Stroke1 from '../../../../assets/images/HomePage/Vector@2x.webp'
import '../../homePage/OurServices/OurService.css'
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect, useDispatch, useSelector } from "react-redux";


const strings = new LocalizedStrings(localeFile);
function OurService(props) {
    strings.setLanguage(props.languageCode);
    return (
        <section className='Ourservice_sectiontow '>

            <div className='Ourservice_section_container  '>
                <div className=' Ourservicetow_content'>
                    <div className='Ourservice__header_container'>

                                          
                      
                        <div className='Ourservice__heading'>

                        
                        <h2 className=' display1'>{strings.ourService.heading}</h2> </div>
                     
                    </div>
                    <div className='Ourservice__description'>
                        <p className='text-large '>
                        {strings.ourService.description}
                        </p>
                    </div>


                </div>
                <div className='services_itemstow'>
                <div className='service_item'>
                    <div className='service_image_container'>
                        <img src={Stroke}/>
                    </div>
                    <div className='service_content'>
                    <h6>{strings.ourService.serviceItems[0].name}</h6>
                    <p>{strings.ourService.serviceItems[0].description}</p>
                    </div>
                </div>
                <div className='service_item'>
                    <div className='service_image_container'>
                        <img src={Stroke1}/>
                    </div>
                    <div className='service_content'>
                    <h6>{strings.ourService.serviceItems[1].name}</h6>
                    <p>{strings.ourService.serviceItems[1].description}</p> 
                    </div>
                </div>
                <div className='service_item'>
                    <div className='service_image_container'>
                        <img src={Stroke2}/>
                    </div>
                    <div className='service_content'>
                    <h6>{strings.ourService.serviceItems[2].name}</h6>
                    <p>{strings.ourService.serviceItems[2].description}</p>
                    </div>
                </div>
                
            </div>
            </div>

          
            


          


        </section>
    );
}




const mapStateToProps = (state) => {
    return {
      languageCode: state.languageCode,
    };
  };
  
  export default connect(mapStateToProps)(OurService);