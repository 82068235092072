import React, { useState, useEffect, useMemo } from 'react';
import { Chart } from 'primereact/chart';
import './Charts.css';
import { useSelector } from 'react-redux';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import { IoEyeSharp } from "react-icons/io5";

const strings = new LocalizedStrings(localeFile);

function Charts(props) {
    strings.setLanguage(props.languageCode);
    const [chartOptions, setChartOptions] = useState({});
    const recomendedResult = useSelector(state => state.recomendedResult);
    const criteresListSelected = useSelector(state => state.criteresListSelected);
    const RecommendResultNumber = useSelector(state => state.RecommendResultNumber);
    const [selectedProduct, setSelectedProduct] = useState();

    useEffect(() => {
        console.log(recomendedResult)


        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');

        const options = {
            responsive: true,

            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: textColor
                    }
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function (tooltipItem) {
                            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                        }
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        circular: true,
                        color: 'rgba(0, 0, 0, 0.1)',
                        lineWidth: 1.5
                    },
                    angleLines: {
                        color: 'rgba(0, 0, 0, 0.1)'
                    },
                  
                    ticks: {
                        display: false,
                        max:30,  // Limite maximum du rayon (par exemple, ajuster la taille maximale du cercle)
                        min: 30,    // Limite minimale du rayon
                        stepSize: 15 // Espace entre les ticks du rayon
                    },
                    pointLabels: {
                        width: 5,
                        color: textColor,
                        font: {

                            size: 10, 
                            family: 'Arial',
                            weight: 'bold', 
                            lineHeight: 1.4, 
                        },
                        callback: function (label) {
                            const maxLength = 10;  
                            if (label.length > maxLength) {
                                const midPoint = Math.floor(label.length / 2);
                                const firstLine = label.slice(0, midPoint);  
                                const secondLine = label.slice(midPoint);  
                                return `${firstLine}\n${secondLine}`;  
                            }
                            return label; 
                        },
                        padding: 2,
                    },
                }
            }
        };

        setChartOptions(options);
    }, []);
    const produits = useMemo(() => {
        const globalLabels = Array.from(
            new Set(
                recomendedResult.flatMap(result => Object.keys(result.scores || {}))
            )
        );
        console.log('globalLabels',globalLabels)
        return recomendedResult.map((result, index) => ({
            id: index + 1,
            name: result.product_name,
            labels: globalLabels,
            data: globalLabels.map(label => result.scores?.[label] || 0),
        }));
    }, [recomendedResult]);

    useEffect(() => {
        console.log(produits)

    }, [])
    useEffect(() => {
        setSelectedProduct(produits[0])
      }, [])
      const handleProductClick = (produit) => {
        setSelectedProduct(produit);
      };

    return (
        <>
<section className="product-chart-section-mobile ">

<div className="product-chart-card">
        <div className="list-products-container">
          {produits.slice(0, RecommendResultNumber).map((produit, index) => (
            <div
              className={`product-item ${selectedProduct && selectedProduct.name === produit.name ? 'selecteditem' : ''}`}
              key={produit.id}
              onClick={() => handleProductClick(produit)}
            >
              <div className="product-item-index">
                <span>{index + 1}</span>
              </div>
              <span>{produit.name}</span>
            </div>
          ))}
        </div>

        {selectedProduct && (
     <div className="product-chart-wrapper">
     {/* Lien enveloppant tout le contenu du graphique */}
     <a 
    target="_blank" 
    rel="noopener noreferrer" 
    href={'https://mayview.fr/' + props.languageCode + '/' + encodeURIComponent(selectedProduct.name)}
    className="chart-link"
  >
    <span>{strings.recommednation.See_Reviews} </span> <IoEyeSharp />
  </a>
   
       {/* Le graphique radar */}
       <Chart
         type="radar"
         data={{
           labels: selectedProduct.labels,
           datasets: [
             {
               label: selectedProduct.name,
               data: selectedProduct.data,
               backgroundColor: function (context) {
                 const chart = context.chart;
                 const { ctx, chartArea } = chart;
   
                 if (!chartArea) {
                   return 'rgba(107, 146, 181, 0.49)';
                 }
   
                 const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                 gradient.addColorStop(0.2311, 'rgba(107, 146, 181, 0.49)');
                 gradient.addColorStop(0.8732, 'rgba(0, 137, 94, 0.49)');
   
                 return gradient;
               },
               borderColor: function (context) {
                 const chart = context.chart;
                 const { ctx, chartArea } = chart;
   
                 if (!chartArea) {
                   return 'rgba(107, 146, 181, 0.49)';
                 }
   
                 const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                 gradient.addColorStop(0.2311, 'rgba(107, 146, 181, 0.49)');
                 gradient.addColorStop(0.8732, 'rgba(0, 137, 94, 0.49)');
   
                 return gradient;
               },
               pointBackgroundColor: function (context) {
                 const chart = context.chart;
                 const { ctx, chartArea } = chart;
   
                 if (!chartArea) {
                   return 'rgba(107, 146, 181, 0.49)';
                 }
   
                 const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                 gradient.addColorStop(0.2311, 'rgba(107, 146, 181, 0.49)');
                 gradient.addColorStop(0.8732, 'rgba(0, 137, 94, 0.49)');
   
                 return gradient;
               },
               pointBorderColor: function (context) {
                 const chart = context.chart;
                 const { ctx, chartArea } = chart;
   
                 if (!chartArea) {
                   return 'rgba(107, 146, 181, 0.49)';
                 }
   
                 const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                 gradient.addColorStop(0.2311, 'rgba(107, 146, 181, 0.49)');
                 gradient.addColorStop(0.8732, 'rgba(0, 137, 94, 0.49)');
   
                 return gradient;
               },
               pointHoverBackgroundColor: '#FFFFFF',
               pointHoverBorderColor: function (context) {
                 const chart = context.chart;
                 const { ctx, chartArea } = chart;
   
                 if (!chartArea) {
                   return 'rgba(107, 146, 181, 0.49)';
                 }
   
                 const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                 gradient.addColorStop(0.2311, 'rgba(107, 146, 181, 0.49)');
                 gradient.addColorStop(0.8732, 'rgba(0, 137, 94, 0.49)');
   
                 return gradient;
               },
               fill: true,
             },
           ],
         }}
         options={chartOptions}
         style={{
            marginTop:'15px',
           minWidth: '100%',
           maxWidth: '100%',
           height: '100%', // Ajustez la hauteur selon vos besoins
         }}
       />

   </div>
        )}
      </div>
    </section>

     <section className="product-chart-section">
                <div className="product-chart-grid">
                    {produits.slice(0,RecommendResultNumber).map((produit,index) => {
                        const chartData = {
                            labels: produit.labels,
                            datasets: [
                                {
                                    label: produits && produit.name,
                                    borderColor: function (context) {
                                        const chart = context.chart;
                                        const { ctx, chartArea } = chart;

                                        if (!chartArea) {

                                            return "rgba(107, 146, 181, 0.49)";
                                        }

                                        const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                                        gradient.addColorStop(0.2311, "rgba(107, 146, 181, 0.49)");
                                        gradient.addColorStop(0.8732, "rgba(0, 137, 94, 0.49)");

                                        return gradient;
                                    },
                                    pointBackgroundColor: function (context) {
                                        const chart = context.chart;
                                        const { ctx, chartArea } = chart;

                                        if (!chartArea) {

                                            return "rgba(107, 146, 181, 0.49)";
                                        }

                                        const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                                        gradient.addColorStop(0.2311, "rgba(107, 146, 181, 0.49)");
                                        gradient.addColorStop(0.8732, "rgba(0, 137, 94, 0.49)");

                                        return gradient;
                                    },
                                    pointBorderColor: function (context) {
                                        const chart = context.chart;
                                        const { ctx, chartArea } = chart;

                                        if (!chartArea) {

                                            return "rgba(107, 146, 181, 0.49)";
                                        }

                                        const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                                        gradient.addColorStop(0.2311, "rgba(107, 146, 181, 0.49)");
                                        gradient.addColorStop(0.8732, "rgba(0, 137, 94, 0.49)");

                                        return gradient;
                                    },
                                    pointHoverBackgroundColor: '#FFFFFF',
                                    pointHoverBorderColor: function (context) {
                                        const chart = context.chart;
                                        const { ctx, chartArea } = chart;

                                        if (!chartArea) {

                                            return "rgba(107, 146, 181, 0.49)";
                                        }

                                        const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                                        gradient.addColorStop(0.2311, "rgba(107, 146, 181, 0.49)");
                                        gradient.addColorStop(0.8732, "rgba(0, 137, 94, 0.49)");

                                        return gradient;
                                    },
                                    data:  produit.data,
                                    backgroundColor: function (context) {
                                        const chart = context.chart;
                                        const { ctx, chartArea } = chart;

                                        if (!chartArea) {

                                            return "rgba(107, 146, 181, 0.49)";
                                        }

                                        const gradient = ctx.createLinearGradient(0, chartArea.top, chartArea.right, chartArea.bottom);
                                        gradient.addColorStop(0.2311, "rgba(107, 146, 181, 0.49)");
                                        gradient.addColorStop(0.8732, "rgba(0, 137, 94, 0.49)");

                                        return gradient;
                                    }, fill: true
                                }
                            ]
                        };

                        return (
                            <div className="product-chart-card" key={produit.id}>
                                <a target='_blank'    rel="noopener noreferrer"   href={'https://mayview.fr/'+props.languageCode+'/'+encodeURIComponent(produit.name)}>
                              
                                <div className="product-chart-title">
                                 <div className='product-index'><span>{index+1} </span> </div>   <h6>{produit.name}</h6>
                                </div>
                                      
                                </a>
                                <div className="product-chart-wrapper">
                                    <Chart type="radar" data={chartData} options={chartOptions}       style={{
     
        minWidth: '100%',
        maxWidth: '100%',
        // Utilisation de 100% pour la largeur
        height: '100%'  // Ajustez la hauteur selon vos besoins
    }}
                                    />                            </div>
                            </div>
                        );
                    })}
                </div>
                {/* <Button title="Voir plus de résultats" color="black" /> */}
            </section>
        </>
    );
}

const mapStateToProps = (state) => ({
    languageCode: state.languageCode,
  });
  
  export default connect(mapStateToProps)(Charts);
  


