import React from 'react'
import {  IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);
function Pagination(props) {
    strings.setLanguage(props.languageCode);

    const { currentPage, totalPages, setCurrentPage } = props;

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

  return (
    <div className='pagination-footer'>

<div
            onClick={handlePrevPage}
            className={`pagination-button mobile Back  ${currentPage === 1 ? 'disabled' : ''}`}
        >
            <IoIosArrowBack />
        </div>
    <p>
    {strings.searchEngine.display} {currentPage || 0} / {totalPages || 0} {strings.searchEngine.result} {totalPages > 1 ? '' : ''}    </p>
    
    <div
            onClick={handleNextPage}
            className={`pagination-button mobile forward ${currentPage === totalPages ? 'disabled' : ''}`}
        >
            <IoIosArrowForward />
        </div>
    <div className='pagination-controls'>
        <div
            onClick={handlePrevPage}
            className={`pagination-button Back  ${currentPage === 1 ? 'disabled' : ''}`}
        >
            <IoIosArrowBack />
        </div>
        <div
            onClick={handleNextPage}
            className={`pagination-button forward ${currentPage === totalPages ? 'disabled' : ''}`}
        >
            <IoIosArrowForward />
        </div>
    </div>
</div>
  )
}


const mapStateToProps = (state) => {
    return {
      languageCode: state.languageCode,
    };
  };
  
  export default connect(mapStateToProps)(Pagination);