const DataRequest = {
    eng: {
        main: `If you would like to request a copy of the data we have collected about you or exercise your rights related to your personal data, please follow the steps below.`,
        whatDataRequest: {
            main: `You can request access to any personal data we hold about you, including:`,
            point1: `Your contact information (name, email, etc.).`,
            point2: `Your browsing data (products viewed, categories explored).`,
            point3: `Your interaction data (reviews, comments, and preferences).`,
        },
        howToSubmit: {
            main: `To submit a data request, please send us a mail on contact@maydena.fr  with the following information:`,
            point1: `<strong>Your Full Name:</strong> So we can identify your account.`,
            point2: `<strong>Email Address:</strong> So we can send you the data you’ve requested.`,
            point3: `<strong>Type of Request:</strong> Choose from the options below (Access, Correction, Deletion, or Objection).`,
        },
        whatHappen: {
            main: `Once we receive your request, we will:`,
            point1: `Confirm receipt of your request within 7 business days.`,
            point2: `Process your request and send you the requested information within 7 business days, depending on the nature of your request.`
        },
        needHelp: `If you have any questions about this process, or if you need assistance with your data request, please contact us at contact@maydena.fr .`,
        
        titles:{
            What_Data_Request:`1. What Data Can You Request?`,
            How_To_Submit:`2. How to Submit Your Request ?`,
            What_HAPPEN:`3. What Happens After You Submit Your Request?`,
            Need_HELP:`4. Need Help?`
        },

        request:`Data Request`,
        lastmodif: "Last modification :"
},      

    fr: {
        main: `Si vous souhaitez demander une copie des données que nous avons collectées à votre sujet ou exercer vos droits concernant vos données personnelles, veuillez suivre les étapes ci-dessous.`,
        whatDataRequest: {
            main: `Vous pouvez demander l'accès à toutes les données personnelles que nous détenons à votre sujet, y compris :`,
            point1: `Vos informations de contact (nom, email, etc.).`,
            point2: `Vos données de navigation (produits consultés, catégories explorées).`,
            point3: `Vos données d’interaction (avis, commentaires, préférences).`,
        },
        howToSubmit: {
            main: `Pour soumettre une demande de données, veuillez envoyer nous un mail sur notre courrier contact@maydena.fr  avec les informations suivantes :`,
            point1: `Votre nom complet : Pour que nous puissions identifier votre compte.`,
            point2: `Adresse e-mail : Pour que nous puissions vous envoyer les données demandées.`,
            point3: `Type de demande : Choisissez parmi les options ci-dessous (Accès, Correction, Suppression, Objection).`,
        },
        whatHappen: {
            main: `Une fois que nous aurons reçu votre demande, nous :`,
            point1: `Confirmerons la réception de votre demande dans un délai de 7 jours ouvrables.`,
            point2: `Traiterons votre demande et vous enverrons les informations demandées dans un délai de 7 jours ouvrables, en fonction de la nature de la demande.`
        },
        needHelp: `Si vous avez des questions concernant ce processus ou si vous avez besoin d’assistance pour votre demande de données, n’hésitez pas à nous contacter à l'adresse suivante : contact@maydena.fr .`,

        titles:{
            What_Data_Request:`1. Quelles données pouvez-vous demander ?`,
            How_To_Submit:`2. Comment soumettre votre demande ?`,
            What_HAPPEN:`3. Que se passe-t-il après avoir soumis votre demande ?`,
            Need_HELP:`4. Besoin d'aide ?`
        },

        request:`Demande de données`,
        lastmodif: "Dernière modification :"
    
    
    }

};

export default DataRequest;
