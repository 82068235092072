import React, { useEffect } from 'react';
import './App.css';
import './assets/styles/Style.css';
import { RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
import { router } from "./router";
import getCookie from './components/common/popCookies/service';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchRecommend, setSelectedProduct, setShowSearchInput } from './store/actions/actionCreators';

function App() {
  const isRecommendationVisible = useSelector(state => state.isRecommendationVisible);
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(resetSearchRecommend());
    if (isRecommendationVisible) {
      document.body.classList.remove('no-scroll');
    }
    // dispatch(resetSelectedProduct());

  }, []);

 

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const productName = decodeURIComponent(pathSegments[pathSegments.length - 1] || '');
    if(productName=='en'||productName=='fr'||productName=='/' ){
      dispatch(setShowSearchInput(false));

    }
   else if (productName) {
      dispatch(setSelectedProduct(true));
      console.log('Set productName to:', productName);
      dispatch(setShowSearchInput(true));

    } else {
      dispatch(setShowSearchInput(false));
      console.log('Product name cleared');
    }
  }, [decodeURIComponent( window.location.pathname.split('/')[ window.location.pathname.split('/').length - 1] || ''), dispatch]);
  





  useEffect(() => {
    const initializeAnalytics = () => {
      const googleAnalyticsConsent = getCookie("googleAnlytics");
      const hotjarConsent = getCookie("hotjar");

      if (googleAnalyticsConsent === 'true') {
        ReactGA.initialize('G-J305XN1017');
      }

      if (hotjarConsent === 'true') {
        (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
          h._hjSettings = { hjid: 5122424, hjsv: 6 };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script'); r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      }
    };

    initializeAnalytics();
  }, []);

  return (

    <RouterProvider router={router} />
  );
}

export default App;