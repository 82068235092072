const content = {
    eng: {
        hreflangCode: "eng",
        header: {
            aboutUs: "About Us",
            Acceuil: "Home",
            Articles: "Blogs",
            login: "Log In"
        },
        mainBanner: {
            title: "Discover our AI-powered search engine",
            searchPlaceholder: "Give me the name of a product and discover its strengths and weaknesses!",
            searchButton: "Search Engine",
            recommendButton: "Recommendation Engine",
            backButton: "Back",
            description: "Search for any product across categories like PCs, phones, appliances, accessories, and more. Check out real user experiences to help you decide. Not sure what you’re looking for? Our recommendation engine will guide you based on your preferences.",


        },
        aboutUs: {
            whoAreWe: "Who Are We",
            description: "We've all been in your shoes: tired of reading fake reviews, navigating between comparators and review sites, and not knowing who to trust. Mayview is not just another review platform; it's your trusted friend, the one who gives you honest advice on products, without filters. Mayview delivers real experiences, not just reviews."


        },
        ourMissionAndVision: {
            mission: {
                title: "Our Mission",
                description: "At Mayview, we stand for Transparency, Accessibility, and the Power of the Collective. To always stay up-to-date with user experiences and reviews, we use machine learning to ensure we don't miss a thing. Don't trust random reviews; trust Mayview."
            },
            vision: {
                title: "Our Vision",
                description: "During the COVID period, we asked ourselves this question: why not gather all reviews on a single platform, without having to switch tabs, without intrusive ads, and without overwhelming users? Our vision is to create a space where everyone can find authentic information and experiences shared by real people."

            }
        },
        LearnMore: "Learn More",
        ourService: {
            title: "What we do",
            heading: "Our Services",
            description: <p>We use Artificial Intelligence to collect people’s experiences with tech and electronic products, so you can relate to their stories and avoid making the wrong choice. <br /> It’s all about building a community where we support and listen to each other, just like friends do.</p>,
            serviceItems: [
                {
                    name: "Product Recommendation Engine",
                    description: "Our platform connects you with real user experiences, helping you find the perfect tech and electronic products tailored to your needs."
                },
                {
                    name: "Fake Review Detection",
                    description: "We use Artificial Intelligence to filter out fake reviews, ensuring you get authentic experiences."
                },
                {
                    name: "Evaluation and Reviews",
                    description: "Share your thoughts and experiences with products, and read what others have to say to make informed choices."
                }
            ],
            counter: {
                dailySearchQueries: "Total number of comments collected",
                activeUsers: "Total Users",
                dollarsSaved: "Number of Listed Products"
            }
        },
        clientVoices: {
            client_voices: 'Testimonials'
        },

        contactUs: {
            title: "Contact Us",
            description: <p>Contact Us anytime, anywhere, every time.<br />We will listen.</p>,
            supportLink: "Customer Support",
            formTitle: "Let's Connect!",
            formDescription: <p> We're here to help.<br /> Fill out the form, and we'll get back to you soon.</p>,
            firstName: "First Name",
            lastName: "Last Name",
            yourEmail: "Your Email",
            yourPhone: "Your Phone",
            helpText: "How can we help?",
            submitButton: "Submit",
            termsText: "By contacting us, you agree to our",
            termsLink: "Terms of service",
            privacyLink: "Privacy policy",
            and: "and"
        },
        footer: {
            company: "Company",
            solutions: "Solution",
            others: "Others",
            whyChoose: "Why choose the Mayview search engine?",
            evaluationAndReviews: "Evaluation and reviews",
            productRecommendation: "Product recommendation engine",
            carbonCalculator: "Carbon footprint calculator",
            fakeReviewDetection: "Fake review detection",
            terms: "Terms & Conditions",
            userPrivacy: "User Privacy Policy",
            datapolicy: "Data policy",
            dataRequest: "Data Request",
            mayviewIntro1: "You want to save time, and we want to give you that privilege—simple as that. This is why Mayview should be your go-to search engine.",
            mayviewIntro2: "Unlike traditional search engines, Mayview isn’t just a platform—it’s powered by people. While Google may flood you with endless options and ads, Mayview gets straight to the point by tapping into real experiences and genuine feedback from our community. It’s not about algorithms trying to guess what you need, but about real people who’ve been in your shoes and are ready to share their advice.",
            mayviewIntro3: "Why trust an engine that doesn’t know you when you can rely on the wisdom of a community that does? Choose Mayview—because when it comes to making informed decisions, no one understands your needs better than the people who’ve been there.",
            mayviewButton: "Start Now",
            joinCommunity: "Join the Mayview community today. Make informed choices, backed by real experiences. Because your decisions matter.",
            copyright: "Copyright 2024",
            copyright_platform: "| Platform - Mayview"

        },
        searchEngine: {
            positive_points: 'Positive points',
            negative_points: 'Negative points',
            display: 'Display',
            result: 'result',
            productAvailability: "You can find this product at retailers like",
            history_search: 'Your search history',
            Results_for: 'Results for',
            loginMessage: 'Please log in to see more results.'


        },
        recommednation: {
            criteriaPrompt: 'Please choose your criteria',
            chooseCriteriaMessage: "Please select the criteria in order of importance based on your preferences. You can also add additional criteria beyond the 5 suggested options. Our algorithm will then provide you with the best options that suit your needs.",
            Search_your_criteria: 'Search your criteria',
            prodct_match: 'Products matching your criteria',
            See_Reviews: 'See Reviews'


        },
        search: 'Search',
        back: 'Back',
        back: 'Back',
        button_disabled: "Please choose at least one of the criteria",

        detailBlog: {
            popular: "Our popular blogs",
            displayButton: "Show all",
            author: "Author"
        },
        blog: {
            blog: "Blog",
            actualite: "Recent news and articles",
            chercher: "Search blog / Author / Keyword ..."
        },
        community: {
            community_description: "At Mayview, we strongly believe in three things: community, community, and more community! The power of the collective is something we take very seriously. For us, community is truly at the heart of everything we do. We don’t just share reviews, we share experiences—whether positive or negative. That’s the true spirit of community: listening to one another, offering advice, and being there for each other. Together, we are stronger!",
            communit_title: "Community First.."
        },
        CoreValues: {
            Transparence: 'Transparency',
            Accessibilite: 'Accesibility',
            Pouvoir_du_collectif: 'Power of the collectif'
        },
        popin: {
            comming_soon: 'WE ARE COMING SOON!',
            comming_soon_description: 'Stay tuned for something amazing',
            notified: 'Get notified when we launch',
            subscribe: 'Subscribe'

        },
        Cookies: {
            cookie_Policy: "Cookie Policy",
            manage_cookies: "Manage Cookies",
            manage_consent_preferences: "Manage Consent Preferences",
            accept_Cookies:
                "Accept Cookies",
            cookies_description:
                "We use cookies and other tracking technologies to enhance your browsing experience on our website, analyze site traffic, and understand where our visitors are coming from.",
            cookies_title: 'We use cookies',

            performance_Cookies:
                "Performance Cookies",
            performance_Cookies_desc:
                "These cookies allow us to count visits and traffic sources to measure and improve the performance of our site. They help us understand which pages are the most and least popular and see how visitors navigate through the site. All information collected by these cookies is aggregated and therefore anonymous. If you do not allow them, we will not know when you visited our site and will not be able to track its performance.",
            necessary_cookies: 'Decline cookies',
            Confirm_my_choices: "Confirm my choices",
            always_active: 'Always active',
            strictly_necessary_cookies_desc:
                "These cookies are necessary for the functioning of the website and cannot be disabled in our systems. They are usually set only in response to actions you have taken that correspond to a request for services, such as setting your privacy preferences, logging in, or filling out forms. You can configure your browser to block or alert you about these cookies, but some parts of the site will not function properly. These cookies do not store any information that can personally identify you.",
            Cookie_Consent_reference: 'Cookie Consent Preference',
            strictly_necessary_cookies: "Strictly Necessary Cookies",
            metadescrip: "These cookies are used to gather information about your use of our website to enhance your experience, tailor the content displayed, and ensure smoother navigation. They enable us to analyze traffic and user behavior to gain better insights into how our platform is being used. This data is also leveraged to improve the relevance of ads shown on Meta platforms, providing you with more targeted content. Furthermore, it helps optimize advertising campaigns and measure their effectiveness, allowing us to refine our advertising strategies on Meta. All data collected is handled in accordance with personal data protection regulations."
        },
        chatbot: {
            greetmessage: "🙌 Share your feedback and suggestions with our chatbot 🤖. Your opinion matters!",

        },
        navigation: {
            aboutUs: 'About-us',
            blogs: 'Blogs',
            blog_details: 'Blog',
            termsConditions: 'Terms-conditions',
            userPrivacy: "User-privacy",
            datapolicy: "Data-policy",
            dataRequest: "Data-request"
        },
        productUnavailableMessage: "This product is not available right now, but it will be available soon."

    },
    fr: {
        hreflangCode: "fr",
        header: {
            aboutUs: "À Propos",
            Acceuil: "Acceuil",
            Articles: "Articles",
            login: "Se Connecter"
        },
        mainBanner: {
            title: "Découvrez notre moteur de recherche boosté à l’IA",
            searchPlaceholder: "Entrez le nom d'un produit et découvrez ses points forts et ses faiblesses en un click !",
            searchButton: "Moteur de Recherche",
            recommendButton: "Moteur de Recommandation",
            backButton: "Retour",
            description: "Recherchez n’importe quel produit dans des catégories variées comme PC, téléphones, électroménagers, accessoires, et plus encore. Consultez les expériences des utilisateurs pour vous aider à décider. Pas de produit en tête ?  Notre moteur de recommandation vous guide selon vos préférences",

        },
        aboutUs: {
            whoAreWe: "Qui Sommes Nous",
            description: "Nous avons tous été à ta place : fatigués de lire des faux avis, de naviguer entre comparateurs et sites d'avis, et de ne pas savoir à qui faire confiance. Mayview n'est pas une simple plateforme d'avis ; c'est ton ami de confiance, celui qui te donne des conseils honnêtes sur les produits, sans filtre. Mayview te livre de vraies expériences, pas juste des avis."
        },
        ourMissionAndVision: {
            mission: {
                title: "Notre Mission",
                description: "Chez Mayview, nous défendons la Transparence, l'Accessibilité et le Pouvoir du Collectif. Pour toujours rester à jour avec les expériences et avis des utilisateurs, nous utilisons le machine learning afin de ne rien manquer. Ne fais plus confiance aux avis aléatoires ; fais confiance à Mayview."
            },
            vision: {
                title: "Notre Vision",
                description: "Pendant la période du COVID, nous nous sommes posé cette question : pourquoi ne pas regrouper tous les avis sur une seule plateforme, sans avoir à changer d'onglet, sans pubs envahissantes et sans surcharger les utilisateurs ? Notre vision est de créer un espace où chacun peut trouver des informations authentiques et des expériences partagées par de vraies personnes."
            }
        },
        LearnMore: "En Savoir Plus",
        ourService: {
            title: "Ce que nous faisons",
            heading: "Nos Services",
            description: "On utilise l'Intelligence Artificielle pour rassembler les expériences des gens avec des produits technologiques et électroniques, afin que tu puisses te connecter à leurs histoires et éviter de faire le mauvais choix. C’est tout sur la création d’une communauté où l'on se soutient et s’écoute, un peu comme des amis.",
            serviceItems: [
                {
                    name: "Moteur de recommandations de produits",
                    description: "Notre plateforme te connecte à de vraies expériences d'utilisateurs, t'aidant à trouver les produits technologiques et électroniques parfaits adaptés à tes besoins."
                },
                {
                    name: "Détection des faux avis",
                    description: "Nous utilisons l'Intelligence Artificielle pour filtrer les faux avis, garantissant ainsi que tu obtiens des expériences authentiques."
                },
                {
                    name: "Évaluations et avis",
                    description: "Partage tes pensées et tes expériences sur les produits, et lis ce que les autres en pensent pour prendre des décisions éclairées."
                }
            ],
            counter: {
                dailySearchQueries: "Nombre total des commentaires collectés",
                activeUsers: "Nombre Total d'Utilisateurs",
                dollarsSaved: "Nombre de Produits Référencés"
            }
        },
        clientVoices: {
            client_voices: 'Témoignages'
        },
        contactUs: {
            title: "Contactez Nous",
            description: "Contactez-nous à tout moment, n'importe où, à chaque instant. Nous vous écouterons.",
            supportLink: "Customer Support",
            formTitle: "Restons en contact !",
            formDescription: "Nous sommes là pour vous aider. Remplissez ce formulaire et nous vous répondrons bientôt.",
            firstName: "Prénom",
            lastName: "Nom",
            yourEmail: "Adresse Email",
            yourPhone: "Numéro de teléphone",
            helpText: "Votre message",
            submitButton: "Envoyer",
            termsText: "En nous contactant, vous acceptez nos ",
            termsLink: "Termes & Conditions",
            privacyLink: "Politique de confidentialité",
            and: "et notre"
        },
        footer: {
            company: "Entreprise",
            solutions: "Solution",
            others: "Autres",
            whyChoose: "Pourquoi choisir le moteur de recherche Mayview ?",
            evaluationAndReviews: "Évaluations et avis",
            productRecommendation: "Moteur de recommandations de produits",
            carbonCalculator: "Calculateur d'empreinte carbone",
            fakeReviewDetection: "Détection des faux avis",
            terms: "Termes et conditions",
            userPrivacy: "Politique de confidentialité des utilisateurs",
            datapolicy: "Politique des données",
            dataRequest: "Demande de données",
            mayviewIntro1: "Vous voulez gagner du temps, et nous voulons vous donner ce privilège—c'est aussi simple que cela. C'est pourquoi Mayview doit être votre moteur de recherche préféré.",
            mayviewIntro2: "Contrairement aux moteurs de recherche traditionnels, Mayview n'est pas seulement une plateforme—elle est alimentée par les gens. Alors que Google peut vous noyer dans des options et des publicités interminables, Mayview va droit au but en s'appuyant sur les expériences réelles et les retours authentiques de notre communauté. Ce n'est pas une question d'algorithmes qui essaient de deviner ce dont vous avez besoin, mais de vraies personnes qui sont passées par là et sont prêtes à partager leurs conseils.",
            mayviewIntro3: "Pourquoi faire confiance à un moteur qui ne vous connaît pas quand vous pouvez compter sur la sagesse d'une communauté qui vous comprend ? Choisissez Mayview—parce que, pour prendre des décisions éclairées, personne ne comprend mieux vos besoins que ceux qui y sont déjà passés.",
            mayviewButton: "Commencer Maintenant",
            joinCommunity: "Rejoignez dès aujourd’hui la communauté Mayview. Faites des choix éclairés, soutenus par des expériences réelles. Parce que vos décisions comptent.",
            copyright: "Copyright 2024",
            copyright_platform: "| Plateforme - Mayview"

        },
        searchEngine: {
            positive_points: 'Points positifs',
            negative_points: 'Points négatifs',
            display: 'Affichage ',
            result: 'résultat',
            productAvailability: 'Vous pouvez trouver ce produit chez des enseignes comme',
            history_search: 'Votre Historique de recherche',
            Results_for: 'Résultats pour',
            loginMessage: 'Veuillez vous connecter pour avoir plus de résultats.'

        },

        recommednation: {
            criteriaPrompt: 'Choisissez vos critères',
            chooseCriteriaMessage: "Veuillez choisir les critères dans l'ordre d'importance selon vos préférences. Vous pouvez également ajouter d'autres critères en plus des 5 critères proposés. Notre algorithme se chargera alors de vous proposer les meilleures options qui vous conviendront.",
            Search_your_criteria: 'Recherchez vos critères',
            prodct_match: 'Produits correspondant à vos critères',
            See_Reviews: 'Voir les avis'
        },

        search: 'Rechercher',
        back: 'Retour',
        button_disabled: "Veuillez choisir au moins un critère.",

        blog: {
            blog: "Article",
            actualite: "Actualités et articles récents",
            chercher: "Chercher Article / Auteur / Mot clé ..."
        },
        detailBlog: {
            popular: "Nos articles populaires",
            displayButton: "Afficher tous",
            author: "Auteur"
        },
        community: {
            community_description: " Chez Mayview, nous croyons fermement en trois choses : la communauté, la communauté et encore la communauté !  La force du collectif, c'est quelque chose que nous prenons très au sérieux. Pour nous, la communauté est vraiment au cœur de tout. Nous ne faisons pas que partager des avis, nous partageons des expériences, qu'elles soient positives ou négatives. C'est ça, l'esprit de la communauté : écouter les uns les autres, se conseiller et être là les uns pour les autres. Ensemble, nous sommes plus forts ! ",
            communit_title: "La communauté d'abord."
        },
        CoreValues: {
            Transparence: 'Transparence',
            Accessibilite: 'Accessibilité',
            Pouvoir_du_collectif: 'Pouvoir du collectif'
        },
        popin: {
            comming_soon: 'ON ARRIVE BIENTÔT',
            comming_soon_description: "Restez connecté pour découvrir quelque chose d'incroyable.",
            notified: 'Recevez une notification dès notre lancement !',
            notified: 'Recevez une notification dès notre lancement !',
            subscribe: 'Abonnez-vous '
        },
        Cookies: {
            cookies_title: "Nous utilisons des cookies.",
            cookies_description:
                "Nous utilisons des cookies et d'autres technologies de suivi pour améliorer votre expérience de navigation sur notre site Web, analyser le trafic du site et comprendre d'où viennent nos visiteurs.",
            accept_Cookies: "Accepter les cookies",
            manage_cookies: "Gérer les cookies",
            cookie_Policy: "Politique de cookies",
            Cookie_Consent_reference: "Préférence de consentement aux cookies",
            manage_consent_preferences: "Gérer les préférences de consentement",
            strictly_necessary_cookies: "Cookies strictement nécessaires",
            always_active: "Toujours actifs",
            strictly_necessary_cookies_desc:
                "Ces cookies sont nécessaires au fonctionnement du site Web et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement définis uniquement en réponse aux actions que vous avez effectuées et qui correspondent à une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur pour qu'il bloque ou vous avertisse de l'existence de ces cookies, mais certaines parties du site ne fonctionneront alors pas. Ces cookies ne stockent aucune information permettant de vous identifier personnellement.",
            performance_Cookies: "Cookies de performance",
            performance_Cookies_desc:
                "Ces cookies nous permettent de comptabiliser les visites et les sources de trafic afin de mesurer et d'améliorer les performances de notre site. Ils nous aident à savoir quelles pages sont les plus et les moins populaires et à voir comment les visiteurs se déplacent sur le site. Toutes les informations collectées par ces cookies sont regroupées et donc anonymes. Si vous ne les autorisez pas, nous ne saurons pas quand vous avez visité notre site et ne serons pas en mesure de suivre ses performances.",
            necessary_cookies: "Refuser les cookies",
            Confirm_my_choices: "Confirmer mes choix",
            metadescrip: "Ces cookies sont utilisés pour collecter des informations sur votre utilisation de notre site afin d'améliorer votre expérience, de personnaliser le contenu affiché et de rendre la navigation plus fluide. Ils nous permettent d'analyser le trafic et les comportements des utilisateurs afin de mieux comprendre l'interaction avec notre plateforme. Ces données sont également utilisées pour améliorer la pertinence des publicités affichées sur les plateformes Meta, en vous offrant un contenu plus ciblé. Elles servent aussi à optimiser les campagnes publicitaires et mesurer leur efficacité, ce qui nous permet d'ajuster nos stratégies publicitaires sur Meta. Toutes les données collectées sont traitées conformément aux réglementations sur la protection des données personnelles."
        },
        chatbot: {
            greetmessage: "🙌 Partagez vos retours et suggestions avec notre chatbot 🤖. Votre avis compte!",

        },
        navigation: {
            aboutUs: 'A-propos',
            blogs: 'Articles',
            blog_details: 'Article',
            termsConditions: 'Terms-conditions',
            userPrivacy: "Politique-de-confidentialite-des-utilisateurs",
            datapolicy: "Politique-des-donnees",
            dataRequest: "Demande-de-donnees"
        },
        productUnavailableMessage: "Ce produit n'est pas disponible pour le moment, mais il le sera bientôt."



    }
};

export default content;
