import { apiSearch } from "../../../../../api/api";

export const Autocomplete = async (value) => {
  try {
    const response = await apiSearch.searchByName(value); 
  
    if (response.data && response.data.results) {
      console.log(response.data.results);
      return response.data.results;
    } else {
      throw new Error('Unexpected response structure');
    }
  } catch (error) {
    console.error('Error fetching autocomplete results:', error);
    throw error; 
  }
};
