import axios from "axios";

export let questions = [

  // Add more questions following the same structure
];
let display = false;

export function addquestion(questionsList) {
  questions=questionsList
}

export function getdisplay() {
  console.log(display)
  return display;
}



let counter = 0;

export function incrementCounter() {
  counter++;
}
export function zeroCounter() {
  counter=0;
}
export function editCounter(newValue) {
  counter=newValue;
}

export function getCounterValue() {
  return counter;
}

let type = "";

export function updatetype(updatetype) {
  type=updatetype;
}

export function getTypeValue() {
  return type;
}
// {
//   _id: 0,
//   question:
//     "Comment avez-vous découvert notre site de synthèse de retours utilisateurs ?",
//   options: [
//     {
//       value: "Moteur de recherche (Google, Bing, etc.)",
//     },
//     {
//       value: "Réseaux sociaux (Facebook, Twitter, Instagram, etc.)",
//     },
//     {
//       value: "Un lien partagé par un ami",
//     },
//     {
//       value: "Par une publicité en ligne",
//     },
//     {
//       value: "En naviguant sur un forum",
//     },
//   ],
// },
// {
//   _id: 1,

//   question: "Quelles étaient vos attentes en visitant notre site ? ",
//   options: [],
// },
// {
//   _id: 2,

//   question:
//     "Trouvez-vous la navigation sur notre site intuitive et facile ? ",
//   options: [],
// },
// {
//   _id: 3,

//   question:
//     "Avez-vous rencontré des difficultés pour trouver les informations que vous recherchiez ? ",
//   options: [],
// },
// {
//   _id: 4,

//   question: "Que pensez-vous du design et de l'ergonomie de notre site ?  ",
//   options: [],
// },
// {
//   _id: 5,

//   question:
//     "Avez-vous des suggestions pour améliorer la navigation ou le design de notre site ? ",
//   options: [],
// },
// {
//   _id: 6,

//   question:
//     "Les résultats présentés sur notre site vous semblent ils pertinents et utiles ?",
//   options: [
//     {
//       value:
//         "Plutôt non, les résultats ne m'ont pas apporté grand-chose de nouveau ou d'utile",
//     },
//     {
//       value:
//         "Ni oui ni non, les résultats étaient partiellement pertinents, mais j'aurais aimé plus d'informations",
//     },
//     {
//       value:
//         "Plutôt oui, les résultats m'ont apporté des informations intéressantes",
//     },
//   ],
// },
// {
//   _id: 7,

//   question:
//     "Avez-vous trouvé des informations sur les produits e-commerce que vous recherchiez ?  ",
//   options: [],
// },
// {
//   _id: 8,

//   question:
//     "Pensez-vous que les retours utilisateurs présentés sont suffisamment objectifs ?  ",
//   options: [],
// },
// {
//   _id: 9,

//   question:
//     "Avez-vous des idées pour enrichir ou améliorer le contenu de notre site ?  ",
//   options: [],
// },
// {
//   _id: 10,

//   question:
//     "Trouvez-vous ces fonctionnalités utiles et faciles à utiliser ?         ",
//   options: [
//     {
//       value:
//         "Plutôt non, je trouve ces fonctionnalités peu utiles ou difficiles à utiliser",
//     },
//     {
//       value:
//         "Ni oui ni non, je n'ai pas encore bien exploré ces fonctionnalités pour me prononcer",
//     },
//     {
//       value:
//         "Oui, tout à fait, ces fonctionnalités sont très utiles et simples à utilise",
//     },
//   ],
// },
// {
//   _id: 11,

//   question:
//     "Avez-vous des suggestions pour ajouter ou améliorer les fonctionnalités et les outils de notre site ?   ",
//   options: [],
// },
// {
//   _id: 12,

//   question:
//     "Dans l'ensemble, êtes-vous satisfait(e) de votre expérience sur notre site de synthèse de retours utilisateurs ?",
//   options: [],
// },
// {
//   _id: 13,

//   question:
//     "Recommanderiez vous notre site à vos amis ou à votre famille pour obtenir des informations sur les produits e-commerce ? ",
//   options: [],
// },
// {
//   _id: 14,

//   question:
//     "Avez-vous des remarques, des suggestions ou des idées supplémentaires pour améliorer notre site et mieux répondre à vos attentes ?",
//   options: [],
// },