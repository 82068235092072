import React, { useState, useEffect } from 'react';
import './Popin.css';
import { useDispatch,useSelector } from 'react-redux';
import { setShowRecommend, SETSHOWBUTTONS } from '../../../../../../store/actions/actionCreators'
import popinimage from '../../../../../../assets/images/Popin/undraw_On_the_way_re_swjt 1.png'
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoLinkedin } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import localeFile from "../../../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const Popin = (props) => {
    strings.setLanguage(props.languageCode);
    const isRecommendationVisible = useSelector(state => state.isRecommendationVisible);

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const togglePopin = () => {
        setIsOpen(!isOpen);
        document.body.classList.remove('no-scroll');

        dispatch(setShowRecommend(false));
        dispatch(SETSHOWBUTTONS(true));
    };
    useEffect(() => {
       
       if(isRecommendationVisible){
        document.body.classList.add('no-scroll');

       }
    else{
        document.body.classList.remove('no-scroll');

    }
        

       
    }, [isRecommendationVisible]);

    return (
        <div className="overlay" onClick={togglePopin}>
            <div className="popin-content_container" onClick={(e) => e.stopPropagation()}>
                <div className='popin-content_icon'>
                <span className="close-btn" onClick={togglePopin}><MdOutlineCancel color='#CB6843'/>
                </span>
                </div>
               
                <div className="popin-content">
                    <div className="popin-content_texts">

             
                    <h2>{strings.popin.comming_soon} </h2>
                    <p>{strings.popin.comming_soon_description} </p>
                    <img src={popinimage} alt="Popin illustration" />
                    </div>  
                    <div className='popin_contact_us_container'>
                        <div className='popin_social_media'>
                            <a target="_blank"  href='https://www.facebook.com/profile.php?id=61564532073774'>
                            <FaFacebookF color='#CB6843'  /></a>
                            <a  target="_blank" href='https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fmayviewfr%3Figsh%3Da3JkcmNrOGI3d3g3%26fbclid%3DIwZXh0bgNhZW0CMTAAAR2KCv4HFVDEFm-fJFKdn6iZmJOyTjODIlja4GbmFWhoenF-ESgmawl6NtI_aem_785fuitYzrwpKhVZvGZLDw&h=AT168ydZ7BxONL9oreahkUQ4OGdZmeOOXGJ3KOXbt9BhP6WUgOV-5qpNOoFVuo-VjtJV7sYop-qNnCMGOMuEC5YXLIH5QLGKUTzoeoRNaLeMD90r9_Xl4bBTxRk97mFiG5h9aw'>
                            <FiInstagram color='#CB6843' /></a>
                            <a target="_blank" href='https://www.linkedin.com/showcase/mayview-fr'>
                            <IoLogoLinkedin color='#CB6843' /></a>
                        </div>
                        {/* <div className='popin_contact_email'>
                            <p>{strings.popin.notified} </p>
                            <div className='popin_contact_email_input'>
                                <input placeholder='Email' />
                                <button> {strings.popin.subscribe} </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
      languageCode: state.languageCode,
    };
  };
  
  export default connect(mapStateToProps)(Popin);
  