import * as actionTypes from './actionTypes';

export const changeLanguage = (langCode) => {
  return {
    type: actionTypes.LANG_ACTION,
    langCode: langCode
  }
}

export const setSelectedProduct = (productName) => {
  return {
      type: actionTypes.SET_SELECTED_PRODUCT,
      payload: productName,
  };
};
export const resetSelectedProduct = () => ({
  type: actionTypes.RESET_SELECTED_PRODUCT,
});

export const setSearchRecommend = (productName) => {
  return {
      type: actionTypes.SET_SEARCH_RECOMMEND,
      payload: productName,
  };
};
export const resetSearchRecommend = () => ({
  type: actionTypes.RESET_SEARCH_RECOMMEND,
});

export const setSelectedProductPOINTLIST = (list) => {
  return {
      type: actionTypes.SET_SELECTED_PRODUCT_POINT_LIST,
      payload: list,
  };
};
export const resetSelectedProductPOINTLIST = () => ({
  type: actionTypes.RESET_SELECTED_PRODUCT_POINT_LIST,
});



export const SETSHOWBUTTONS = (value) => {
  return {
      type: actionTypes.SET_SHOW_BUTTONS,
      payload: value,
  };
};



export const setShowRecommend = (value) => {
  return {
      type: actionTypes.SET_SHOW_RECOMMENDATION,
      payload: value,
  };
};

export const setShowSearchInput = (value) => {
  return {
      type: actionTypes.SET_SHOW_SEARCH_INPUT,
      payload: value,
  };
};

export const setSubCategorySelected = (value) => {
  return {
      type: actionTypes.SET_SUBCATEGORY_SELECTED,
      payload: value,
  };
};


export const setSelectedcriteresLIST = (list) => {
  return {
      type: actionTypes.SET_SELECTED_CRITERE_LIST,
      payload: list,
  };
};
export const resetSelectedcriteresLIST = () => ({
  type: actionTypes.RESET_SELECTED_CRITERE_LIST,
});



export const setRECOMMENDED_RESULT= (list) => {
  return {
      type: actionTypes.SET_RECOMMENDED_RESULT,
      payload: list,
  };
};
export const resetRECOMMENDED_RESULT = () => ({
  type: actionTypes.RESET_RECOMMENDED_RESULT,
});



export const SET_LOADING= (list) => {
  return {
      type: actionTypes.SET_LOADING,
      payload: list,
  };
};
export const RESET_LOADING = () => ({
  type: actionTypes.RESET_LOADING,
});



export const SET_RECOMMEND_RESULT_NUMBE= (list) => {
  return {
      type: actionTypes.SET_RECOMMEND_RESULT_NUMBE,
      payload: list,
  };
};
export const RESET_RECOMMEND_RESULT_NUMBER = () => ({
  type: actionTypes.RESET_RECOMMEND_RESULT_NUMBER,
});
