import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import sal from "sal.js";

import Sidebar from "./Sidebar";
import "./UserPolicy.css";
import localeFile from "../../content/UserPolicy.js";
import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings(localeFile);

const UserPolicy = (props) => {
  strings.setLanguage(props.languageCode);

  useEffect(() => {
    sal();
  }, []);

  const [selectedId, setSelectedId] = useState("Maydena");

  const handleClick = (event, id) => {
    if (event) {
      event.preventDefault();
    }

    if (id) {
      setSelectedId(id);
    }

    const clickedListItem = event.currentTarget;
    const selectedClasses = document.querySelectorAll(".selected");
    const selectedSousClasses = document.querySelectorAll(".show");
    const nextElement = clickedListItem.nextElementSibling;

    selectedClasses.forEach((element) => {
      if (element !== clickedListItem) {
        element.classList.remove("selected");
      }
    });
    selectedSousClasses.forEach((element) => element.classList.remove("show"));

    if (!clickedListItem.classList.contains("selected")) {
      clickedListItem.classList.add("selected");
      nextElement?.classList.add("show");
    }
  };

  const formatTextAsHtml = (text) => {
    
    const html = text
      .split('\n')
      .map(line => line.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')) 
      .join('<br />'); 
  
    
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
  
    
    const clickableHtml = html.replace(
      emailRegex,
      (match) => `<a href="mailto:${match}">${match}</a>`
    );
  
    return clickableHtml;
  };
  



  const renderContent = () => { 
    const personaldataContent = (
      <div>
        <>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.main) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point1) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point2) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point3) }} />
          </ul>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.main2) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point4) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point5) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point6) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point7) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point8) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.personaldata.point9) }} />
          </ul>
        </>
      </div>
    );
  
    const dataRetentionContent = (
      <div>
        <>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataretention.main) }} />
          <br />
          <p className="bold-text">4.1 <span dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.titles.accessing) }} /></p>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataretention.accessing) }} />
          <br />
          <p className="bold-text">4.2 <span dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.titles.review) }} /></p>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataretention.review) }} />
          <br />
          <p className="bold-text">4.3 Cookies</p>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataretention.cookies.main) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataretention.cookies.point1) }} />
            <br />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataretention.cookies.point2) }} />
          </ul>
        </>
      </div>
    );
  
    const disclosureContent = (
      <div>
        <>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.disclosure.main) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.disclosure.point1) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.disclosure.point2) }} />
          </ul>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.disclosure.main2) }} />
        </>
      </div>
    );
  
    const correctionContent = (
      <div>
        <>
          <p className="bold-text">
            <span dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.titles.infoaccess) }} />
          </p>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.correction.access) }} />
          <br />

          <p className="bold-text">
            <span dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.titles.correction) }} />
          </p>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.correction.deletion) }} />
        </>
      </div>
    );
  
    if (selectedId) {
      switch (selectedId) {
        case "Maydena":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.maydena) }} />;
        case "PERSONAL_DATA":
          return personaldataContent;
        case "LEGAL_BASE":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.legalbasis) }} />;
        case "DATA_RETENTION":
          return dataRetentionContent;
        case "IP_SETTINGS":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.adresses) }} />;
        case "DISCLOSURE":
          return disclosureContent;
        case "DATA_CONTROLLER":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataprocessor) }} />;
        case "SECURITY_MEASURES":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.security) }} />;
        case "CORRECTION_DELETION":
          return correctionContent;
        case "BANK_INFO":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.bank) }} />;
        case "AGE_CONDITIONS":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.age) }} />;
        case "RIGHT_TO_FORGET":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.right) }} />;
        case "EXTERNAL_LINKS":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.links) }} />;
        case "PRIVACY_POLICY_CHANGES":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.changes) }} />;
        case "ENQUIRIES":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.enquiries) }} />;
        default:
          return <div>Select an item to view its content</div>;
      }
    }
    return <div>Select an item to view its content</div>;
  };
  

  return (
    <div className="userpolicy-conditions-page">

      <div className="userpolicy-content-area section-padding">
      <div className="banner">
          <h2>{strings.userPolicy}</h2>
        </div>
        <div className="content-container-fluid">
          <div className="content-row">
            <div className="sidebar-column">
              <Sidebar selectedId={selectedId} handleClick={handleClick} />
            </div>
            <div className="content-column">
              <div className="banner-content">
                <h1 className="banner-title">{strings.userPolicy}</h1>
                <p className="banner-slogan">{strings.lastmodif} : 22 Oct. 2024</p>
              </div>
              <div className="info-content-box">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};
export default connect(mapStateToProps)(UserPolicy);