import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import sal from "sal.js";

import Sidebar from "./Sidebar.jsx";
import "./DataPolicy.css";
import localeFile from "../../content/DataPolicy.js";
import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings(localeFile);

const DataPolicy = (props) => {
  strings.setLanguage(props.languageCode);

  useEffect(() => {
    sal();
  }, []);

  const [selectedId, setSelectedId] = useState("Maydena");

  const handleClick = (event, id) => {
    if (event) {
      event.preventDefault();
    }

    if (id) {
      setSelectedId(id);
    }

    const clickedListItem = event.currentTarget;
    const selectedClasses = document.querySelectorAll(".selected");
    const selectedSousClasses = document.querySelectorAll(".show");
    const nextElement = clickedListItem.nextElementSibling;

    selectedClasses.forEach((element) => {
      if (element !== clickedListItem) {
        element.classList.remove("selected");
      }
    });
    selectedSousClasses.forEach((element) => element.classList.remove("show"));

    if (!clickedListItem.classList.contains("selected")) {
      clickedListItem.classList.add("selected");
      nextElement?.classList.add("show");
    }
  };

  const formatTextAsHtml = (text) => {

    const html = text
      .split('\n')
      .map(line => line.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'))
      .join('<br />');


    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;


    const clickableHtml = html.replace(
      emailRegex,
      (match) => `<a href="mailto:${match}">${match}</a>`
    );

    return clickableHtml;
  };




  const renderContent = () => {
    const whatdataContent = (
      <div>
        <>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.whatdata.main) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.whatdata.point1) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.whatdata.point2) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.whatdata.point3) }} />
          </ul>
        </>
      </div>
    );

    const howToUseContent = (
      <div>
        <>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.howToUse.main) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.howToUse.point1) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.howToUse.point2) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.howToUse.point3) }} />
          </ul>
        </>
      </div>
    );

    const rightsContent = (
      <div>
        <>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.rights.main) }} />
          <br />
          <ul>
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.rights.point1) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.rights.point2) }} />
            <li dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.rights.point3) }} />
          </ul>
          <br/>
          <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.rights.main2) }} />
        </>
      </div>
    );


    if (selectedId) {
      switch (selectedId) {
        case "WHAT_DATA":
          return whatdataContent;
        case "HOW_TO_USE":
          return howToUseContent;
        case "DATA_SHARE":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.datashare) }} />;
        case "DATA_PROTECTION":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.dataprotection) }} />;
        case "DATA_KEEPING":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.datakeep) }} />;
        case "RIGHTS":
          return rightsContent;
        case "EDIT_POLICY":
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.editpolicy) }} />;
        default:
          return <div dangerouslySetInnerHTML={{ __html: formatTextAsHtml(strings.main) }}/>;
      }
    }
    return <div>Select an item to view its content</div>;
  };


  return (
    <div className="dataProtection-page">

      <div className="dataProtection-content-area section-padding">
        <div className="banner">
          <h2>{strings.policy}</h2>
        </div>
        <div className="content-container-fluid">
          <div className="content-row">
            <div className="sidebar-column">
              <Sidebar selectedId={selectedId} handleClick={handleClick} />
            </div>
            <div className="content-column">
              <div className="banner-content">
                <h1 className="banner-title">{strings.policy}</h1>
                <p className="banner-slogan">{strings.lastmodif} 22 Oct. 2024</p>
              </div>
              <div className="info-content-box">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};
export default connect(mapStateToProps)(DataPolicy);