import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import './containerWithStars.css'
function ContainerWithStars({sendOption,  index}) {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
   
 
  const [finalrating, setFinalRating] = useState(null);

  const handleOptionClick = (optionValue) => {
    setRating(optionValue);
    sendOption(optionValue,index)
    setFinalRating(optionValue)
  };

  return (
    <div className="stars-options-container">
      {[...Array(5)].map((star, index) => {
        const currentRating = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={currentRating}
              onClick={() => handleOptionClick(currentRating)}
            />
            <FaStar 
            
            className={finalrating!=null ? 'star-main-disabled':'star' }
            size={30} 
            color={currentRating <= (hover || rating) ? "#ffc107" : "#666666"}
            onMouseEnter={()=>setHover(currentRating)}
            onMouseLeave={()=>setHover(null)}

            />
          </label>
        );
      })}
      {/* your rating is {rating} */}
    </div>
  );
}

export default ContainerWithStars;
