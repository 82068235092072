import React, { useEffect } from "react";
import * as actionCreators from "../../../store/actions/actionCreators";
import "./LanguageSwitch.css";
import flagfr from "../../../assets/images/flagfr.png";
import flagUS from "../../../assets/images/flageng.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Importez useNavigate pour la redirection
import userInteractionTracker from "../../../Google Analytics/userInteractionTracker";

const LanguageSwitch = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialiser useNavigate
  const { trackButtonClick } = userInteractionTracker();
  const lang = useSelector((data) => data.languageCode);

  const handleLanguageChange = (langCode) => {

    actionCreators.changeLanguage(langCode)

    trackButtonClick("Changement de Langue", "Changer de Langue", `Langue: ${langCode}`);

 
    let currentPath = window.location.pathname;
    const currentSearch = window.location.search;
    const currentHash = window.location.hash;


    const translations = {
      en: {
        "/fr": "/en",
        "/A-propos": "/About-us",
        "/Articles": "/Blogs",
        "/Politique-de-confidentialite-des-utilisateurs": "/User-privacy",
        "/Politique-des-donnees": "/Data-policy",
        "/Demande-de-donnees": "/Data-request",

      },
      fr: {
        "/en": "/fr",
        "/About-us": "/A-propos",
        "/Blogs": "/Articles",
        "/User-privacy": "/Politique-de-confidentialite-des-utilisateurs",
        "/Data-policy": "/Politique-des-donnees",
        "/Data-request": "/Demande-de-donnees",

      },
    };


    const currentTranslations = translations[langCode];
    Object.keys(currentTranslations).forEach((key) => {
      currentPath = currentPath.replace(key, currentTranslations[key]);
    });

    navigate(currentPath + currentSearch + currentHash, { replace: true });
  };

  useEffect(() => {


    let currentPath = window.location.pathname;

    if (currentPath.includes('fr')) {
      actionCreators.changeLanguage('fr')
    }

    if (currentPath.includes('en')) {
      actionCreators.changeLanguage('en')
    }

    if (currentPath.includes('/About-us') && lang == 'fr') {
      currentPath = currentPath.replace("/fr", "/en");
      actionCreators.changeLanguage('en')
    } else if (currentPath.includes('/A-propos') && lang == 'en') {
      currentPath = currentPath.replace("/en", "/fr");
      actionCreators.changeLanguage('fr')
    }
    if (currentPath.includes('/Blogs') && lang == 'fr') {
      currentPath = currentPath.replace("/fr", "/en");
      actionCreators.changeLanguage('en')
    } else if (currentPath.includes('/Articles') && lang == 'en') {
      currentPath = currentPath.replace("/en", "/fr");
      actionCreators.changeLanguage('fr')
    }


    if (currentPath.includes('/User-privacy') && lang == 'fr') {
      currentPath = currentPath.replace("/fr", "/en");
      actionCreators.changeLanguage('en')
    } else if (currentPath.includes('/Politique-de-confidentialite-des-utilisateurs') && lang == 'en') {
      currentPath = currentPath.replace("/en", "/fr");
      actionCreators.changeLanguage('fr')
    }
    if (currentPath.includes('/Data-policy') && lang == 'fr') {
      currentPath = currentPath.replace("/fr", "/en");
      actionCreators.changeLanguage('en')
    } else if (currentPath.includes('/Politique-des-donnees') && lang == 'en') {
      currentPath = currentPath.replace("/en", "/fr");
      actionCreators.changeLanguage('fr')
    }
    if (currentPath.includes('/Data-request') && lang == 'fr') {
      currentPath = currentPath.replace("/fr", "/en");
      actionCreators.changeLanguage('en')
    } else if (currentPath.includes('/Demande-de-donnees') && lang == 'en') {
      currentPath = currentPath.replace("/en", "/fr");
      actionCreators.changeLanguage('fr')
    }

    navigate(currentPath, { replace: true });

  }, [window.location.pathname, navigate]);



  return (
    <div className="flag-container">
      {lang === "en" && (
        <div className="flag-icon" onClick={() => handleLanguageChange("fr")}>
          <img src={flagUS} alt="English Flag" />
          <span className="tooltip">Click to switch to French</span>
        </div>
      )}
      {lang === "fr" && (
        <div className="flag-icon" onClick={() => handleLanguageChange("en")}>
          <img src={flagfr} alt="French Flag" />
          <span className="tooltip">Cliquez pour passer à l'anglais</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    langHandler: (langCode) => dispatch(actionCreators.changeLanguage(langCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);
