import React from 'react';
import rectangleCommunity from '../../../../assets/images/AboutUs/Rectangle 11.png';
import './Communities.css'
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect, useDispatch, useSelector } from "react-redux";

const strings = new LocalizedStrings(localeFile);
 function Communities(props) {
  strings.setLanguage(props.languageCode);
  return (
    <section className="community-section">
      <div className="community-container">
        <img src={rectangleCommunity} alt="Community" className="community-image" />
        <div className="community-content">
          <div className="community-title">
          <h2 >    {strings.community.communit_title}</h2>

          </div>
          <p className="community-description">
            {strings.community.community_description}
           </p>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Communities);