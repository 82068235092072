import React, { useEffect, useState } from 'react';
import { apiMTRecom, apiSearch } from '../../../../../../api/api';
import SearchBarCritere from './SearchBarCritere';
import BackButton from '../../common/BackButton';
import { SET_LOADING,resetSearchRecommend,SET_RECOMMEND_RESULT_NUMBE, setSearchRecommend, setSubCategorySelected,setSelectedcriteresLIST,setRECOMMENDED_RESULT, resetSelectedcriteresLIST  } from '../../../../../../store/actions/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import localeFile from "../../../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import './criteres.css'
import axios from 'axios';

const strings = new LocalizedStrings(localeFile);
function Criteres(props) {
  strings.setLanguage(props.languageCode);

  const { category, subCategory } = props;

  const [criteresOptions, setCriteresOptions] = useState([]);
  const [critereSelectedList, setCritereSelectedList] = useState([]);
  const [newSelectedList, setnewSelectedList] = useState([]);
  const dispatch = useDispatch();
  const [recommendedProducts, setRecommendedProducts] = useState(null);
  const subCategorySelected = useSelector(state => state.isSubCategorySelected);
  const [loading, setIsLoading] = useState(false);
  // const loading = useSelector(state => state.loading);
  const RecommendResultNumber = useSelector(state => state.RecommendResultNumber);

  const [similarity_thresholdNumber, setsimilarity_thresholdNumber] = useState(null);
  const [desactive, setDesactive] = useState(true);



  useEffect(() => {
    fetchCriteres(category, subCategory);
  }, []);

  const fetchCriteres = async (category, subCategory) => {
    try {
      const response = await apiMTRecom.fetchtopCriteres(category, subCategory);
      console.log('form fetchCriteres')
      console.log(response)

      setCriteresOptions(response.data);
    } catch (error) {
      console.error("Error fetching criteres:", error);
    }
  };

  const critereSelected = (critere, fromSearch) => {
    const critereExists = critereSelectedList.find(item => item.critere === critere);
    let updatedList = [...critereSelectedList];

    if (!critereExists) {
      updatedList = fromSearch
        ? [...[{ critere, fromSearch: true }], ...updatedList]
        : [...updatedList, { critere, fromSearch: false }];
    } else {
      updatedList = updatedList.filter(item => item.critere !== critere);
    }

    updatedList = updatedList.map((item, index) => ({ ...item, order: index + 1 }));
    console.log('updatedList',updatedList)
    if(updatedList.length>0){
      setDesactive(false)
    }else{
      setDesactive(true)

    }
    setCritereSelectedList(updatedList);
console.log(critereSelectedList)

  };
  useEffect(() => {
    if(critereSelectedList.length>0){
      setDesactive(false)
    }else{
      setDesactive(true)

    }

  
    
  }, [critereSelectedList])
  
 
  useEffect(() => {

    const getvalues = async () => {
   try {
        const response = await apiSearch.getvalues();
  
    
        dispatch(SET_RECOMMEND_RESULT_NUMBE(response.data.recommendResultNumber))
        // setrecommendResultCritersNumber(response.data.recommendResultCritersNumber)
        setsimilarity_thresholdNumber(response.data.similarity_threshold)
  
      } catch (error) {
        console.error('Error updating tauxImpression:', error);
      }
    
    };
    getvalues()

   
  }, [])
  
  const handelSearch = () => {
    if(critereSelectedList.length>0){ 

    document.body.classList.add('no-scroll');

    setIsLoading(true);

    const result = critereSelectedList.map(item =>item.critere.name?item.critere.name: item.critere).join(",");

    if (critereSelectedList.length > 0) {
     
      const requestData = {
        query: result, 
        n_results:RecommendResultNumber ?RecommendResultNumber:10 ,
        similarity_threshold: similarity_thresholdNumber ?similarity_thresholdNumber:0.4 ,
        sub_category: subCategorySelected,
      };
  
      axios
        .post(
          'https://mayview.fr/milvus/similar-docs?collection=collection_three',
          requestData,    {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('Data stored successfully:', response.data.data);
          setRecommendedProducts(response.data.data);
  
          if (response.data.data) {
            dispatch(setSelectedcriteresLIST(critereSelectedList));
            dispatch(setRECOMMENDED_RESULT(response.data.data));
            dispatch(SET_LOADING(true));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // dispatch(SET_LOADING(false));


          console.error('There was an error storing the data:', error);
        })
        .finally(() => {
          setIsLoading(false);
          document.body.classList.remove('no-scroll');
          dispatch(setSearchRecommend(true));



        });
    } }
  };
  const handleBackButtonClick = () => {
    dispatch(setSubCategorySelected(false));
    dispatch(resetSearchRecommend());
    dispatch(resetSelectedcriteresLIST())



  };


  return (
    <>
     {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
 
    <div className="main-banner__content_criteria ">
      <div className="main-banner__content-title_criteria">
        <h1 className="main-banner__title_criteria"> {strings.recommednation.criteriaPrompt} </h1>
      </div>
      <p>
        {strings.recommednation.chooseCriteriaMessage}
      </p>
      <div className='criteria-search-wrapper'>
          <div className="criteria-list">
            {criteresOptions.map((critere, index) => (
              <div
                key={index}
                className={critereSelectedList.find(item => item.critere === critere.critere) ? "criteria-item selected" : "criteria-item"}
                onClick={() => critereSelected(critere.critere, false)}
              >
                {critereSelectedList.find(item => item.critere === critere.critere) && (
                  <div className="criteria-order-number">
                    <span>
                    {critereSelectedList.find(item => item.critere === critere.critere).order}

                    </span>
                  </div>
                )}
                <div className='criteria-item-name'>
                  {strings.hreflangCode=='fr' ? critere.critereFr:critere.critere}</div>
              </div>
            ))}
          </div>

          {/* <div className="criteria-search-container">
          <SearchBarCritere
            category={category}
            subCategory={subCategory}
            criteresOptions={criteresOptions}
            critereSelected={critereSelected}
            newSelectedList={newSelectedList}
            setnewSelectedList={setnewSelectedList}
          />
        </div> */}
    
          <div className="selected-criteria-list">
            {newSelectedList.map((critere, index) => (
              <div
                className={
                  critereSelectedList.find(item => item.critere === critere)
                    ? "criteria-item selected"
                    : "criteria-item"
                }
                key={index}
                onClick={() => critereSelected(critere)}
              >
                {critereSelectedList.find(item => item.critere === critere) ? (
                  <div className="criteria-order-number">
                          <span>
                          {critereSelectedList.find(item => item.critere === critere).order} 

                    </span>
                    
                  </div>
                ) : null}
                     <div className='criteria-item-name'>{critere.name}</div>
              </div>
            ))}
          </div>
      </div>
            
      <BackButton desactive={desactive}   show={true} onClick={handleBackButtonClick} action={handelSearch} search={true} />

    </div>  </>
  );
}



const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Criteres);