import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "./popup.css";
import { IoAddCircleOutline } from "react-icons/io5";
import { CiCircleMinus } from "react-icons/ci";
import { InputSwitch } from "primereact/inputswitch";
import localeFile from "../../../content/content";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
const strings = new LocalizedStrings(localeFile);

const ManageCookies = (props) => {
  strings.setLanguage(props.languageCode);

  const [switchHotjarChecked, setHotjarChecked] = useState(true);
  const [switchGaChecked, setGaChecked] = useState(true);

  const [cookies, setCookie, removeCookie] = useCookies(["cookiesAccepted"]);
  const initializeAnalytics = () => {
    ReactGA.initialize('G-9F2HJ9VSK3');
  };
  useEffect(() => {
    setHotjarChecked(true)
    setGaChecked(true)
  }, [])

  const loadHotjar = () => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 5197660, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    
  };

  const acceptCookies = () => {
    // Cookie pour l'acceptation des cookies (expire dans 1 an)
    setCookie("cookiesAccepted", true, { path: "/", maxAge: 365 * 24 * 60 * 60 }); 
  
    if (switchHotjarChecked) {
      loadHotjar();
      // Cookie pour Hotjar (expire dans 6 mois)
      setCookie("hotjar", true, { path: "/", maxAge: 180 * 24 * 60 * 60 }); 
    }
  
    if (switchGaChecked) {
      // Cookie pour Google Analytics (expire dans 1 an)
      setCookie("googleAnlytics", true, { path: "/", maxAge: 365 * 24 * 60 * 60 }); 
      initializeAnalytics();
    }
  };
  const cancelCookies = () => {
    removeCookie("cookiesAccepted", { path: "/" });
    removeCookie("hotjar", true, { path: "/" });
    removeCookie("googleAnlytics", true, { path: "/" });

    props.setshowPop(false)
  };

  const handleSwitchChange = (value) => {

    setHotjarChecked((prevChecked) => !prevChecked);
    // console.log(props.switchHotjarChecked)
  };
  const handleSwitchGaChange = () => {
    setGaChecked((prevChecked) => !prevChecked);


  };
  const openDetails = (event) => {
    event.preventDefault();

    const clickedListItem = event.currentTarget;
    const iconplus = clickedListItem.firstElementChild;
    const iconminus = iconplus.nextElementSibling;

    const parentElement = clickedListItem.parentNode;
    const nextElement = parentElement.nextElementSibling;

    if (nextElement.classList.contains("show")) {
      nextElement?.classList.remove("show");
      iconplus?.classList.remove("show");
      iconminus?.classList.add("show");
    } else {
      iconplus?.classList.add("show");
      nextElement?.classList.add("show");
      iconminus?.classList.remove("show");
    }
  };

  if (cookies.cookiesAccepted) {
    return null;
  }

  return (
    <div>
      <p>
        {strings.Cookies.cookies_description}
        <Link className="cookie-policy" to={`${props.languageCode}/${strings.navigation.userPrivacy}`}>
          {strings.Cookies.cookie_Policy}
        </Link>
      </p>
      <span> {strings.Cookies.manage_consent_preferences}</span>

      <div className="manage-cookies" >
        <div className="manage-cookies-item">
          <div>
            <div className="manage-cookies-item-head">
              <div className="manage-cookies-item-title" onClick={openDetails}>
                <div className="manage-cookies-item-button">
                  <CiCircleMinus className="icon " />
                </div>
                <div className="manage-cookies-item-button show">
                  <IoAddCircleOutline className="icon " />
                </div>
                {strings.Cookies.strictly_necessary_cookies}
              </div>
              <div className="manage-cookies-item-prev">
                {strings.Cookies.always_active}
              </div>
            </div>
            <div className={"manage-cookies-item-details "}>
              {strings.Cookies.strictly_necessary_cookies_desc}
            </div>
          </div>
        </div>
        
        <div className="line"></div>
        <div className="manage-cookies-item">
          <div>
            <div className="manage-cookies-item-head">
              <div className="manage-cookies-item-title" onClick={openDetails}>
                <div className="manage-cookies-item-button">
                  <CiCircleMinus className="icon " />
                </div>
                <div className="manage-cookies-item-button show">
                  <IoAddCircleOutline className="icon " />
                </div>
                Meta
              </div>
              <div className="manage-cookies-item-prev">
                {strings.Cookies.always_active}
              </div>
            </div>
            <div className={"manage-cookies-item-details "}>
              {strings.Cookies.metadescrip}
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="manage-cookies-item">
          <div className="manage-cookies-item-head">
            <div className="manage-cookies-item-title" onClick={openDetails}>
              <div className="manage-cookies-item-button">
                <CiCircleMinus className="icon " />
              </div>
              <div className="manage-cookies-item-button show">
                <IoAddCircleOutline className="icon " />
              </div>
              Hotjar
            </div>

            <InputSwitch
              checked={switchHotjarChecked}

              onChange={(e) => handleSwitchChange(e.value)}
              className={switchHotjarChecked ? "custom-switch-checked" : "custom-switch-unchecked"}

            />


          </div>
          <div className={"manage-cookies-item-details "}>
            {strings.Cookies.performance_Cookies_desc}
          </div>
        </div>
        <div className="line"></div>
        <div className="manage-cookies-item">
          <div className="manage-cookies-item-head">
            <div className="manage-cookies-item-title" onClick={openDetails}>
              <div className="manage-cookies-item-button">
                <CiCircleMinus className="icon " />
              </div>
              <div className="manage-cookies-item-button show">
                <IoAddCircleOutline className="icon " />
              </div>
              Google analytics
            </div>


            <InputSwitch
              checked={switchGaChecked}
              onChange={(e) => handleSwitchGaChange(e.value)}
              className={switchGaChecked ? "custom-switch-checked" : "custom-switch-unchecked"}

            />


            {/* {switchChecked ? (
                <Switch checked={0} />
              ) : (
                <Switch
                checked={1}
                color="primary"
                style={{ color: "#0774FF" }}
              />
                
              )} */}

          </div>
          <div className={"manage-cookies-item-details "}>
            {strings.Cookies.performance_Cookies_desc}
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-between mx-3">
        <button
          className="cancel-cookies  col-md-5  col-sm-5 "
          onClick={cancelCookies}
        >
          {strings.Cookies.necessary_cookies}
        </button>
        <button
          className="accpet-cookies col-md-5 col-sm-5 "
          onClick={acceptCookies}
        >
          {strings.Cookies.Confirm_my_choices}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(ManageCookies);
