import React, { useState, useEffect, useRef } from 'react';
import { IoSearch } from "react-icons/io5";
import { Autocomplete } from '../services/service';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedProduct, setSelectedProduct, setSelectedProductPOINTLIST, setShowSearchInput } from '../../../../../store/actions/actionCreators';
import { apiSearch } from '../../../../../api/api';
import BackButton from '../common/BackButton';
import { SETSHOWBUTTONS } from '../../../../../store/actions/actionCreators';
import localeFile from "../../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import './SearchInput.css';
import { useNavigate } from 'react-router-dom';

const strings = new LocalizedStrings(localeFile);

const SearchInput = (props) => {
    strings.setLanguage(props.languageCode);

    const containerRef = useRef(null);
    const firstResultRef = useRef(null); // Reference to the first result item
    const selectedProductName = useSelector(state => state.selectedProductName);

    const { setSearching } = props;
    const [autocompleteResults, setAutocompleteResults] = useState([]);
    const [searchingValue, setSearchingValue] = useState('');
    const [loading, setLoading] = useState(false);
    const abortControllerRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSearchIconActive, setIsSearchIconActive] = useState(false);
    const [productNotFoundMessage, setProductNotFoundMessage] = useState(false); 

    const handleSearch = async (value) => {
        if (!value) {
            setAutocompleteResults([]);
            setLoading(false);
            setSearching(false);
            setProductNotFoundMessage(false)
            return;
        }

        setLoading(true);
        setSearching(true);

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        try {
            const results = await Autocomplete(value, { signal });
            setAutocompleteResults(results);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error fetching autocomplete results:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    const debouncedSearch = debounce(handleSearch, 300);

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchingValue(value);
        debouncedSearch(value);
    };

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
    const productName = decodeURIComponent(pathSegments[pathSegments.length - 1] || '');
  
    if (productName && selectedProductName) {
        handleProductSelect(productName)
    } 
      
    }, [])

    const handleProductSelect = async (selectedProduct) => {
        setSearchingValue(selectedProduct);
        setAutocompleteResults([]);
        setSearching(false);
        setLoading(false);
        let currentPath = window.location.pathname;

        try {
            const response = await apiSearch.getPoints(selectedProduct, null);
            dispatch(setSelectedProductPOINTLIST(response.data));
            dispatch(setSelectedProduct(selectedProduct));

            console.log('Realme Narzo N53',selectedProduct)
            if(response.data) {
                if (currentPath.includes('en')) {
                    const newPath = `/en/${encodeURIComponent(selectedProduct)}`;
                    window.history.replaceState(null, '', newPath);
            
                } else if (currentPath.includes('fr')) {
                    const newPath = `/fr/${encodeURIComponent(selectedProduct)}`;
                    window.history.replaceState(null, '', newPath);
            
                }
                
            }
        } catch (error) {
            console.error("Error fetching criteres:", error);
        }


    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    useEffect(() => {
        setSearching(autocompleteResults.length > 0);
        
        if (autocompleteResults.length > 0 && firstResultRef.current) {
            // Ajoutez un léger délai pour défilement en douceur
            const timer = setTimeout(() => {
                firstResultRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 100); // délai de 100 ms pour un défilement plus doux
    
            return () => clearTimeout(timer); // Nettoyez le timer pour éviter les fuites
        }
    }, [autocompleteResults, setSearching]);

    const handleBackButtonClick = () => {
        dispatch(SETSHOWBUTTONS(true));
        dispatch(setShowSearchInput(false));
        dispatch(resetSelectedProduct());
        dispatch(setSelectedProduct(false));

        setSearchingValue('');
        navigate('/', { replace: true });

    };

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setAutocompleteResults([]);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleSearchIconClick = async () => {
        if (isSearchIconActive && searchingValue.trim()) {
            try {
                const response = await apiSearch.saveNotFoundProduct(searchingValue);
                if (response.status === 200) {
                    console.log("Product saved successfully:", response.data);
                    setProductNotFoundMessage(true)
                } else {
                    console.error("Failed to save the product. Response:", response);
                    setProductNotFoundMessage(true)
                }
            } catch (error) {
                console.error("Error saving not-found product:", error);
                setProductNotFoundMessage(true)
            }
        }
    };

    useEffect(() => {
        if (searchingValue != null) {
            setIsSearchIconActive(autocompleteResults.length === 0);
        }
    }, [autocompleteResults]);

    return (
        <div className='search--input-back-container'>
            <div className='info-search-mobile'>
                <p>{strings.mainBanner.searchPlaceholder}</p>
            </div>

            <div className={`search--input-container`}>
                <input
                    className='search--input search--input-mobile'
                    placeholder='Rechercher'
                    value={searchingValue}
                    onChange={handleChange}
                />
                <input
                    className="search--input not"
                    placeholder={strings.mainBanner.searchPlaceholder}
                    value={searchingValue }
                    onChange={handleChange}
                />
                 <div
                    className={`search-icon-container ${isSearchIconActive ? "clickable" : ""}`}
                    onClick={handleSearchIconClick}
                >
                    <IoSearch className="icon" />
                </div>
                
                <div className="result-container" ref={containerRef}>
                    {autocompleteResults.length > 0 && (
                        <div className="autocomplete-results" >
                            {autocompleteResults.slice(0, 6).map((item, index) => (
                            
                                <div
                                    key={item._id}
                                    ref={index === 1 ? firstResultRef : null} // Reference only for the first item
                                    className="autocomplete-item"
                                    onClick={() => handleProductSelect(item.composedName)}
                                >
                                    {item.composedName}
                                </div>
                               
                            ))}
                        </div>
                    )}
                    {loading && <div className="autocomplete-results">Loading...</div>}
                </div>
            </div>
            {productNotFoundMessage && (
                    <div className="product-unavailable-notification">
                        {strings.productUnavailableMessage}
                    </div>
                )}
            <BackButton show={autocompleteResults.length > 0 || loading  ? false : true} onClick={handleBackButtonClick} search={false} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    languageCode: state.languageCode,
});

export default connect(mapStateToProps)(SearchInput);
