const TermsConditions = {
    eng: {
        maydena: "Maydena, a business consulting and services company, is the creator and operator of Mayview. With expertise in digital transformation, data analysis, and customer engagement, we aim to drive change and innovation through our platform. In other words , the factory is Maydena and the cake is Mayview .",
        acceptance: `These terms of use explain how you can use our services, including posting reviews on the Maydena website. When we say "we," "us," or "our," we mean Maydena. If you don't agree with these terms, you can't use our website or services. By using them, you agree to these terms.`,
        registration: "To use our services fully, you need to register on our website. During registration, pick a password that you’ll use with your email to log in. Keep your password private; don’t share it. We can delete your account anytime without notice. As a registered user, you can view our content but can’t copy or sell it.",
        competitors: `We don’t allow competitors like Trustpilot or their employees to access our client dashboard. Unauthorized access will incur a fee of $100,000 USD per month. By signing up, you agree this fee is fair and represents our loss.`,
        contributions: {
            main: `When you post reviews or comments (we call these 'contributions') on our site, they’re considered non-confidential, and you won’t receive any compensation. You allow Maydena to use, publish, and display your contributions in any media worldwide.
You’re responsible for the content you post. Maydena isn’t liable for any defamatory comments you make. We strive for fairness and transparency and offer procedures to address issues with reviews.
You cannot post contributions that are:`,
            point1: "Sexist, racist, or offensive",
            point2: "Plagiarized",
            point3: "Violate intellectual property rights",
            point4: "Unlawful or defamatory",
            point5: "Contain personal data or contact details",
            point6: "Attempt to sell or redirect traffic",

            main2: `If you violate these rules, we’ll remove your contribution and may ban you from further posting. Both the reviewer and the reviewed party agree to indemnify Maydena against any claims or expenses resulting from violating these terms.
We can delete contributions anytime without explanation. If you contact gdpr@Maydena.com , we’ll try to explain why. We may also send review requests on behalf of companies if asked by a real customer.`,

        },

        thirdparty: `You can use our services with third-party platforms (like Google), but that’s subject to their terms. We aren’t responsible for these platforms, including their security or how they use your data. If you link them with our services, you allow us to share your data with them. Always read and follow their terms and privacy policies. We can’t take responsibility for their content or opinions.`,
        rights: {
            main: " Once a review is submitted, it’s under Maydena’s control.",
            point1: "We may edit or remove content when necessary, ensuring fairness.",
            point2: "Contributors are responsible for their content, and any issues arising from it are not Mayview’s responsibility.",
        },
        indemnity: {
            main: `As a Mayviewer , you agree to protect Mayview  and Maydena from any losses or damages if a third party claims that:`,
            point1: "The content you provided infringes on intellectual property rights or violates laws or guidelines.",
            point2: "Mayview's use of your data breaches data protection laws.",
            point3: "An email sent by Mayview on your behalf breaks any laws.",
            main2: "You won't bring claims against Mayview related to user content, like claims that it’s defamatory or harmful. You’ll also cover any losses or damages Mayview incurs from such claims, whether they come from you, your affiliates, or related parties.",
        },
        titles: {
            acceptance: "Acceptance",
            registration: "Registration",
            competitors: "Competitors",
            contributions: "Contributions",
            thirdparty: "Third-Party Platforms",
            rights: "Rights",
            indemnity: "Indemnity"

        },
        terms: "Terms & conditions",
        lastmodif: "Last modification :"
    },

    fr: {

        maydena: "Maydena, une entreprise de conseil et de services, est le créateur et opérateur de Mayview. Avec une expertise en transformation numérique, analyse de données, et engagement client, nous visons à favoriser le changement et l'innovation à travers notre plateforme. En d'autres termes, l'usine est Maydena et le gâteau est Mayview",
        acceptance: `Ces conditions d'utilisation expliquent comment vous pouvez utiliser nos services, y compris publier des avis sur le site Maydena. Quand nous disons "nous," "notre," ou "nos," nous faisons référence à Maydena. Si vous n'êtes pas d'accord avec ces termes, vous ne pouvez pas utiliser notre site ou nos services. En les utilisant, vous acceptez ces conditions.`,
        registration: "Pour utiliser pleinement nos services, vous devez vous inscrire sur notre site. Lors de l'inscription, choisissez un mot de passe que vous utiliserez avec votre e-mail pour vous connecter. Gardez votre mot de passe privé ; ne le partagez pas. Nous pouvons supprimer votre compte à tout moment sans préavis. En tant qu'utilisateur enregistré, vous pouvez consulter notre contenu mais ne pouvez pas le copier ou le vendre.",
        competitors: `Nous n'autorisons pas les concurrents comme Trustpilot ou leurs employés à accéder à notre tableau de bord client. Un accès non autorisé entraînera des frais de 100 000 USD par mois. En vous inscrivant, vous convenez que ces frais sont équitables et représentent notre perte.`,
        contributions: {
            main: `Lorsque vous publiez des avis ou des commentaires (nous appelons cela des 'contributions') sur notre site, ils sont considérés comme non confidentiels, et vous ne recevrez aucune compensation. Vous permettez à Maydena d'utiliser, publier, et afficher vos contributions dans tout média mondial. Vous êtes responsable du contenu que vous publiez. Maydena n'est pas responsable des commentaires diffamatoires que vous faites. Nous nous efforçons d'être équitables et transparents et offrons des procédures pour résoudre les problèmes liés aux avis.
Vous ne pouvez pas publier de contributions qui sont :`,
            point1: "Sexistes, racistes, ou offensantes",
            point2: "Plagiées",
            point3: "Violent les droits de propriété intellectuelle",
            point4: "Illégales ou diffamatoires",
            point5: "Contiennent des données ou coordonnées personnelles",
            point6: "Tentent de vendre ou rediriger le trafic",

            main2: `Si vous enfreignez ces règles, nous supprimerons votre contribution et pouvons vous interdire de poster à nouveau. Le critique et la partie évaluée acceptent d'indemniser Maydena contre toute réclamation ou dépense résultant de la violation de ces termes. Nous pouvons supprimer des contributions à tout moment sans explication. Si vous contactez gdpr@Maydena.com , nous essaierons de vous expliquer pourquoi. Nous pouvons également envoyer des demandes d'avis au nom des entreprises si un client réel le demande.`
        },

        thirdparty: `Vous pouvez utiliser nos services avec des plateformes tierces (comme Google), mais cela est soumis à leurs conditions. Nous ne sommes pas responsables de ces plateformes, y compris leur sécurité ou comment elles utilisent vos données. Si vous les reliez à nos services, vous nous autorisez à partager vos données avec elles. Lisez toujours et suivez leurs conditions et politiques de confidentialité. Nous ne pouvons pas assumer la responsabilité de leur contenu ou de leurs opinions.`,
        rights: {
            main: "Une fois qu'un avis est soumis, il est sous le contrôle de Maydena.",
            point1: "Nous pouvons modifier ou supprimer du contenu si nécessaire, en veillant à l'équité. ",
            point2: "Les contributeurs sont responsables de leur contenu, et tout problème en découlant n'est pas la responsabilité de Mayview.",
        },
        indemnity: {
            main: `En tant que Mayviewer, vous acceptez de protéger Mayview et Maydena contre toute perte ou dommage si un tiers prétend que :`,
            point1: "Le contenu que vous avez fourni enfreint des droits de propriété intellectuelle ou viole des lois ou directives.",
            point2: "L'utilisation de vos données par Mayview enfreint les lois sur la protection des données.",
            point3: "Un e-mail envoyé par Mayview en votre nom enfreint des lois.",
            main2: "Vous ne ferez pas de réclamations contre Mayview liées au contenu utilisateur, comme des réclamations selon lesquelles il est diffamatoire ou nuisible. Vous couvrirez également toute perte ou dommage que Mayview subit suite à de telles réclamations, qu'elles proviennent de vous, de vos affiliés, ou de parties liées.",
        },

        titles: {
            acceptance: "Acceptation",
            registration: "Inscription",
            competitors: "Concurrents",
            contributions: "Contributions",
            thirdparty: "Plateformes tierces",
            rights: "Droits",
            indemnity: "Indemnité"

        },
            terms: "Termes & conditions",
            lastmodif: "Dernière modification :"


    }
}

export default TermsConditions;