import React, { useEffect, useRef, useState } from "react";
import "./chat.css";
import { MdOutlineCancel } from "react-icons/md";
import { TbMessageChatbot } from "react-icons/tb";
import { v4 as uuidv4 } from "uuid";
import { connect, useSelector } from "react-redux";
import localeFile from "../../../content/content";
import LocalizedStrings from "react-localization";
import {
  addquestion,
  editCounter,
  getCounterValue,
  incrementCounter,
  questions,
} from "./components/questionList";
import SelectType from "./components/selectType";
import ContainerWithStars from "./components/containerWithStars";
import GreetMessage from "./utilities/greetMessage";
import Header from "./utilities/Header";
import HeaderBotMessage from "./utilities/HeaderBotMessage";
import WritingLoading from "./utilities/WritingLoading";
import { apiChat } from "../../../api/api";
import chat from '../../../assets/images/Ellipse 258.png'
import chatcncel from '../../../assets/images/Trigger.png'
import axios from "axios";
import { LuSendHorizonal } from "react-icons/lu";


const strings = new LocalizedStrings(localeFile);

function Tchat(props) {
  const languageCode = props.languageCode ?? "fr";
  const [blockit, setblockit] = useState(false);
  const [inputText, setInputText] = useState("");
  const [messagesBD, setMessagesBD] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const userData = useSelector((data) => data.user);
  const [messagegreeting, setMessagegreeting] = useState(false);
  const [reponsesComplete, setreponsesComplete] = useState(false);
  const messagesEndRef = useRef(null);
  const [intervalTime, setIntervalTime] = useState(60000); // Intervalle initial de 1 minute
  const [isLoading, setIsLoading] = useState(false); // Ajout de l'état `isLoading`
  strings.setLanguage(languageCode);

  useEffect(() => {
    axios.get('https://mayview.fr/chat/private/getAll')
      .then(response => {
        addquestion(response.data);
      })
      .catch(error => {
        console.error('Une erreur s\'est produite :', error);
      });
  }, []);

  useEffect(() => {
    const messageContainer = document.getElementById("messageContainer");
    if (messageContainer) {
      messageContainer.addEventListener("MutationObserver", function (event) {
        const { currentTarget } = event;
        currentTarget.scrollTop = currentTarget.scrollHeight;
      });
    } else {
      console.error("Element with id 'messageContainer' not found.");
    }
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!userData) {
      let userId = getCookie('userId');
      if (!userId) {
        userId = uuidv4();
        document.cookie = `userId=${userId}; expires=${new Date(
          Date.now() + 50 * 24 * 60 * 60 * 1000
        ).toUTCString()}; path=/`;
      }
    }
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    const userId = userData ? userData._id : getCookie('userId');
    if (userId) {
      apiChat.getMessages(userId)
        .then((response) => {
          const chatData =response.data? response.data.chat : [];
          setMessagesBD(chatData);
          if (chatData.length === questions.length) {
            setreponsesComplete(true);
          }
          if (messages.length > 2 && messages[messages.length - 1].sender == "bot") {
            messages.pop();
          }
          const newMessages = [];
          chatData.forEach((element) => {
            newMessages.push({
              text: element.question,
              textAng: element.questionAng,
              sender: "bot",
            });
            if (element.reponseUtilisateur.trim() !== "") {
              newMessages.push({
                text: element.reponseUtilisateur,
                sender: "user",
                show: true,
              });
            }
          });
          setMessages(newMessages);
          editCounter(chatData.length);
          if (questions[getCounterValue()].type == 'star' || questions[getCounterValue()].type == 'select') {
            // setblockit(true)
          } else {
            // setblockit(false)
          }
        })
        .catch((error) => {
          console.error("Une erreur s'est produite :", error);
        });


      const intervalId = setInterval(() => {
        if (!reponsesComplete) {
          setMessagegreeting(true);
        }

      }, intervalTime);

      return () => clearInterval(intervalId);
    }
  }, [userData]);
  const findFirstIndexNotInMessages = () => {
    for (let i = 0; i < questions.length; i++) {
      const questionText = questions[i].questionFr;

      // Vérifier si le texte de la question n'existe pas dans messagesBD
      const existsInMessages = messages.some(message => message.text === questionText);

      if (!existsInMessages) {
        // Retourner l'index de la première question qui n'existe pas dans messagesBD
        return i;
      }
    }
    return -1; // Si toutes les questions existent dans messagesBD
  };
  const findQuestionIndexNotInMessages = (question) => {


    // Vérifier si le texte de la question n'existe pas dans messagesBD
    const existsInMessages = messages.some(message => message.text === question);

    if (!existsInMessages) {
      // Retourner l'index de la première question qui n'existe pas dans messagesBD
      return true;
    }

    return false; // Si toutes les questions existent dans messagesBD
  };
  const openChat = () => {
    setIsChatOpen(true);
    const userId = userData ? userData._id : getCookie('userId');
    console.log('open')
    if (!messagesBD.length && userId) {
      apiChat.getMessages(userId)
        .then((response) => {
          const chatData = response.data.chat || [];
          setMessagesBD(chatData); console.log('openchat')



          if (chatData.length === questions.length) {
            editCounter(chatData.length);
          }

          const newMessages = [];
          chatData.forEach((element) => {
            newMessages.push({
              text: element.question,
              textAng: element.questionAng,
              sender: "bot",
            });
            if (element.reponseUtilisateur.trim() !== "") {
              newMessages.push({
                text: element.reponseUtilisateur,
                sender: "user",
                show: true,
              });
            }
          });
          setMessages(newMessages);
          editCounter(chatData.length);

        })
        .catch((error) => {
          console.error("Une erreur s'est produite :", error);
        });
    }

    if (getCounterValue() < questions.length) {

      console.log(messages)

      console.log(questions)


      console.log('index', findFirstIndexNotInMessages())
      if (findFirstIndexNotInMessages() >= 0) {
        editCounter(findFirstIndexNotInMessages())
      }
      if (questions[findFirstIndexNotInMessages()].type == 'star' || questions[findFirstIndexNotInMessages()].type == 'select') {
        setblockit(true)
      } else {
        setblockit(false)
      }
      if (messages.length == 0 || messages[messages.length - 1].sender != "bot") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: questions[findFirstIndexNotInMessages()]._id,
            text: questions[findFirstIndexNotInMessages()].questionFr,
            textAng: questions[findFirstIndexNotInMessages()].questionAng,
            sender: "bot",
            type: questions[findFirstIndexNotInMessages()].type,
            options: questions[findFirstIndexNotInMessages()].optionsFr,
            optionsAng: questions[findFirstIndexNotInMessages()].optionsAng,
          },
        ]);
      }
    }
    if (findFirstIndexNotInMessages() == questions.length) {
      setblockit(true)
    }

    setMessagegreeting(false);
    scrollToBottom();
  };

  const closeChat = () => {
    setIsChatOpen(false);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const closeMessage = () => {
    setMessagegreeting(!messagegreeting);
  };

  const sendMessage = async (event) => {
    event.preventDefault();

    const userId = getCookie("userId");
    if (getCounterValue() < questions.length) {
      if (inputText.trim() !== "") {
        try {
          await apiChat.sendMessage({
            userId: userData ? userData._id : userId,
            chat: [
              {
                question: questions[getCounterValue()].questionFr,
                questionAng: questions[getCounterValue()].questionAng,
                reponseUtilisateur: inputText,
                id: questions[getCounterValue()]._id,
              },
            ],
          });
        } catch (error) {
          console.error("Error:", error);
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            text: inputText,
            sender: "user",
            show: true,
          },
        ]);
        setInputText("");
        incrementCounter();
        if (findFirstIndexNotInMessages() >= 0) {




          setTimeout(() => {
            if (getCounterValue() < questions.length) {
              if (questions[findFirstIndexNotInMessages()].type != 'open') {
                setblockit(true)
              }
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  id: questions[findFirstIndexNotInMessages()]._id,
                  text: questions[findFirstIndexNotInMessages()].questionFr,
                  textAng: questions[findFirstIndexNotInMessages()].questionAng,
                  sender: "bot",
                  type: questions[findFirstIndexNotInMessages()].type,
                  options: questions[findFirstIndexNotInMessages()].optionsFr,
                  optionsAng: questions[findFirstIndexNotInMessages()].optionsAng,
                },
              ]);
              if (questions[findFirstIndexNotInMessages()].type == 'star' || questions[findFirstIndexNotInMessages()].type == 'select') {
                setblockit(true)
              } else {
                setblockit(false)
              }
              console.log(getCounterValue())
            }
          }, 900); // Exemple de délai de 1 seconde avant de désactiver l'animation
        }
      }
    }
  };

  const sendOption = async (message, index) => {
    const userId = getCookie("userId");

    try {
      await axios.post(`https://mayview.fr/chat/private/addUserReponses`, {
        userId: userData ? userData._id : userId,
        chat: [
          {
            question: questions[getCounterValue()].questionFr,
            questionAng: questions[getCounterValue()].questionAng,
            reponseUtilisateur: message,
            id: questions[getCounterValue()]._id,
          },
        ],
      });
    } catch (error) {
      console.error("Error:", error);
    }
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, sender: "user" },
    ]);
    setInputText("");


    incrementCounter()
    if (findFirstIndexNotInMessages() >= 0) {



      if (getCounterValue() < questions.length || messages.length < questions.length) {
        if (questions[findFirstIndexNotInMessages()].type != 'open') {
          setblockit(true)
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: questions[findFirstIndexNotInMessages()]._id,
            text: questions[findFirstIndexNotInMessages()].questionFr,
            textAng: questions[findFirstIndexNotInMessages()].questionAng,
            sender: "bot",
            type: questions[findFirstIndexNotInMessages()].type,
            options: questions[findFirstIndexNotInMessages()].optionsFr,
            optionsAng: questions[findFirstIndexNotInMessages()].optionsAng,
            selected: true,
          },
        ]);

        console.log('from chat',questions[findFirstIndexNotInMessages()].type )
        if (questions[findFirstIndexNotInMessages()].type == 'star' || questions[findFirstIndexNotInMessages()].type == 'select') {
          setblockit(true)
        } else {
          setblockit(false)
        }
      }
    }
  };

  const onChangeInput = (event) => {
    setInputText(event.target.value);
  };

  return (
    <>
      <div className={`chat-wrap ${isChatOpen ? "open" : ""}`} id="chat-wrap">
        <div className="icon" onClick={isChatOpen ? closeChat : openChat}>
          {isChatOpen ? (
            <img alt='close Chat' className="closeChat" src={chatcncel}  />
          ) : (
            <div className="maybot-icon_container">
              <div className="maybot-icon">
                <img alt='chat' src={chat}/>
              </div>
        
            </div>
          )}
        </div>
      </div>
      {!isChatOpen && messagegreeting && !reponsesComplete && <GreetMessage closeMessage={closeMessage} strings={strings} />}

      <div className={isChatOpen ? "chat-container" : "chat-container-none"}>
        <div className="react-chatbot-kit-chat-container">
          <div className="react-chatbot-kit-chat-inner-container">
            <Header closeChat={closeChat} />
            <div
              className="react-chatbot-kit-chat-message-container"
              id="messageContainer"
            >
              {messages.map((message, index) => (

                <React.Fragment key={index}>
                  {message.sender === "bot" ? (
                    <>
            
                      {index === messages.length - 1 && isLoading ? (
                        <WritingLoading />
                      ) : (
                        <>
                          <div className="react-chatbot-kit-chat-bot-message" ref={messagesEndRef}>
                            <span>{languageCode === "fr" ? message.text : message.textAng}</span>
                          </div>
                          <div className="chatbot_options">

                        
                          {message.type === "select" ? (
                            <SelectType
                              myList={languageCode === "fr" ? message.options : message.optionsAng}
                              sendOption={sendOption}
                              index={index}
                            />
                          ) : message.type === "star" ? (
                            <ContainerWithStars
                              sendOption={sendOption}
                              index={index}
                            />
                          ) : (
                            ""
                          )}  </div>
                        </>
                      )}
                    </>
                  ) : (
                    message.show && (
                      <div
                        className="react-chatbot-kit-user-chat-message-container"
                        key={message.key}
                      >
                        <div>
                          {/* <div className="react-chatbot-kit-chat-user-avatar">
                            <div
                              className="react-chatbot-kit-chat-user-avatar-container"
                              style={{ background: " none;" }}
                            >
                              <div className="avatar">
                                <img
                                  className="avatar-img"
                                  src={userData ? userData.avatar : "/static/media/empty.3722fc9cdacc2dbed06e.png"}
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="react-chatbot-kit-user-chat-message">
                            {message.text}
                            <div className="react-chatbot-kit-user-chat-message-arrow"></div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </React.Fragment>
              ))}
              <div style={{ paddingBottom: " 15px;" }} ref={messagesEndRef}></div>
            </div>

            <div className="react-chatbot-kit-chat-input-container">
              <form
                className="react-chatbot-kit-chat-input-form"
                data-gtm-form-interact-id="0"
              >
                <input
                  disabled={blockit}
                  className="react-chatbot-kit-chat-input"
                  placeholder="Type a reply..."
                  value={inputText}
                  onChange={(event) => onChangeInput(event)}
                  data-gtm-form-interact-field-id="0"
                  onKeyPress={(event) =>
                    event.key === "Enter" ? sendMessage(event) : null
                  }
                />
                <div className="send-icon">
                <LuSendHorizonal color="#00895E"
                onClick={(event) =>
                   sendMessage(event) 
                }
                />

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Tchat);