import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import sal from "sal.js";
import Sidebar from "./Sidebar";
import "./TermsConditions.css";
import localeFile from "../../content/TermsConditions.js";
import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings(localeFile);

const TermsConditions = (props) => {
  const scrollToRef = useRef(null);
  const registeredRef = useRef(null);
  const competitorsRef = useRef(null);
  const contributionRef = useRef(null);
  const thirdpartyRef = useRef(null);
  strings.setLanguage(props.languageCode);

  useEffect(() => {
    sal();
  }, []);

  const [selectedId, setSelectedId] = useState("Maydena");
  const [selectedDetail, setSelectedDetail] = useState(null);

  const handleClick = (event, id) => {
    if (event) {
      event.preventDefault();
    }
  
    if (id && ["Maydena", "ACCEPTANCE", "RIGHTS", "INDEMNITY"].includes(id)) {
      setSelectedId(id);
      setSelectedDetail(null);
    } else {
      setSelectedDetail(id);
    }
  
    // Ensure the scrolling happens within the `.info-content-box`
    const scrollOptions = { behavior: "smooth", block: "start" };
  
    const infoContentBox = document.querySelector(".info-content-box");
    let targetRef = null;
  
    switch (id) {
      case "REGISTERED":
        targetRef = registeredRef.current;
        break;
      case "COMPETITORS":
        targetRef = competitorsRef.current;
        break;
      case "CONTRIBUTIONS":
        targetRef = contributionRef.current;
        break;
      case "THIRD_PARTY":
        targetRef = thirdpartyRef.current;
        break;
      default:
        break;
    }
  
    if (targetRef && infoContentBox) {
      // Calculate the offset of the target element relative to the scroll container
      const offsetTop = targetRef.offsetTop - infoContentBox.offsetTop;
      infoContentBox.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  
    const clickedListItem = event.currentTarget;
    const selectedClasses = document.querySelectorAll(".selected");
    const selectedSousClasses = document.querySelectorAll(".show");
    const nextElement = clickedListItem.nextElementSibling;
  
    selectedClasses.forEach((element) => {
      if (element !== clickedListItem) {
        element.classList.remove("selected");
      }
    });
    selectedSousClasses.forEach((element) => element.classList.remove("show"));
  
    if (!clickedListItem.classList.contains("selected")) {
      clickedListItem.classList.add("selected");
      nextElement?.classList.add("show");
    }
  };
  
  
  

  const renderContent = () => {
    const acceptanceContent = (
      <div>
        <>
          {strings.acceptance} <br /> <br />
          <p className="bold-text" ref={registeredRef}>
            2.1 {strings.titles.registration}
          </p>
          {strings.registration} <br /> <br />
          <p className="bold-text" ref={competitorsRef}>
            2.2 {strings.titles.competitors}
          </p>
          {strings.competitors} <br /> <br />
          <p className="bold-text" ref={contributionRef}>
            2.3 {strings.titles.contributions}</p>
          {strings.contributions.main}
          <br /> <br />
          <ul>
            <li>{strings.contributions.point1}</li>
            <li>{strings.contributions.point2}</li>
            <li>{strings.contributions.point3}</li>
            <li>{strings.contributions.point4}</li>
            <li>{strings.contributions.point5}</li>
            <li>{strings.contributions.point6}</li>
          </ul>
          {strings.contributions.main2} <br /> <br />
          <p className="bold-text" ref={thirdpartyRef}>
            2.4 {strings.titles.thirdparty}</p>
          {strings.thirdparty} <br /> <br />
        </>
      </div>
    );

    if (selectedDetail) {
      switch (selectedDetail) {
        case "REGISTERED":
        case "COMPETITORS":
        case "CONTRIBUTIONS":
        case "THIRD_PARTY":
          return acceptanceContent;
        default:
          return <div>Select a detail item to view its content</div>;
      }
    } else if (selectedId) {
      switch (selectedId) {
        case "Maydena":
          return <div>{strings.maydena}</div>;
        case "ACCEPTANCE":
          return acceptanceContent;
        case "RIGHTS":
          return (
            <div>
              {strings.rights.main} <br /> <br />
              <ul>
                <li>{strings.rights.point1}</li>
                <li>{strings.rights.point2}</li>
              </ul>
            </div>
          );
        case "INDEMNITY":
          return (
            <div>
              {strings.indemnity.main} <br /> <br />
              <ul>
                <li>{strings.indemnity.point1}</li>
                <li>{strings.indemnity.point2}</li>
                <li>{strings.indemnity.point3}</li>
              </ul>
              {strings.indemnity.main2} <br /> <br />
            </div>
          );
        default:
          return <div>Select an item to view its content</div>;
      }
    }
    return <div>Select an item to view its content</div>;
  };

  return (
    <div className="terms-conditions-page">
      <div className="terms-content-area section-padding">
        <div className="banner">
          <h2>{strings.terms}</h2>
        </div>
        <div className="content-container-fluid">
          <div className="content-row">
            <div className="sidebar-column">
              <Sidebar
                selectedId={selectedId}
                selectedDetail={selectedDetail}
                handleClick={handleClick}
              />
            </div>
            <div className="content-column">
              <div className="banner-content">
                <h1 className="banner-title">{strings.terms}</h1>
                <p className="banner-slogan">{strings.lastmodif} 22 Oct. 2024</p>
              </div>
              <div className="info-content-box">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(TermsConditions);
