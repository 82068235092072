import React from 'react';
import profile from '../../../../assets/images/HomePage/profile.webp';
import profile2 from '../../../../assets/images/HomePage/profile2 (2).webp';
import profile3 from '../../../../assets/images/HomePage/profile3.webp';
import './ClientVoisce.css';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styled from "styled-components";

const strings = new LocalizedStrings(localeFile);

function ClientVoice(props) {
  const clientVoices = [
    {
      id: 1,
      image: profile,
      text: "Mayview is an indispensable tool! The platform is intuitive and saved me a considerable amount of time. I highly recommend this service to anyone looking to simplify their online shopping.",
      author: "Lukas Müller",
      authorProfession: "IT Expert"
    },
    {
      id: 2,
      image: profile2,
      text: "I recently used Mayview to purchase an electronic product. The synthesized reviews were incredibly relevant and allowed me to make a confident decision. Instead of getting lost in a multitude of comments, I found exactly what I was looking for. An exceptional user experience!",
      author: "Pierre LECLERC",
      authorProfession: "Merchant"
    },
    {
      id: 3,
      image: profile3,
      text: "Mayview has transformed the way I shop online. Thanks to this platform, I make more informed and quicker decisions. Thank you for this innovation!",
      author: "Sophie LOPEZ",
      authorProfession: "Sales Manager"
    },
  ];

  const clientVoicesFr = [
    {
      id: 1,
      image: profile,
      text: "Mayview est un outil indispensable ! La plateforme est intuitive et m'a fait gagner un temps précieux. Je recommande vivement ce service à tous ceux qui veulent simplifier leurs achats en ligne.",
      author: "Lukas Müller",
      authorProfession: "Expert en Systèmes d'Information"
    },
    {
      id: 2,
      image: profile2,
      text: "J'ai récemment utilisé Mayview pour acheter un produit électronique. Les avis synthétisés étaient incroyablement pertinents et m'ont permis de prendre une décision en toute confiance. ",
      author: "Pierre LECLERC",
      authorProfession: "Commerçant"
    },
    {
      id: 3,
      image: profile3,
      text: "Mayview a transformé ma façon de faire des achats en ligne. Grâce à cette plateforme, je prends des décisions plus éclairées et plus rapides. Merci pour cette innovation !",      author: "Sophie LOPEZ",
      authorProfession: "Responsable Vente"
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className='client_voices_section'>
      <div className='client_voices_title'>
        <h2>{strings.clientVoices.client_voices}</h2>
      </div>
      <div className='big_triangle_container'>
        <div className='big_triangle'></div>
        <div className='big_triangle'></div>
      </div>
      <StyledSlider className="slider-container">
        <Slider {...settings}>
          {(props.languageCode === 'eng' ? clientVoices : clientVoicesFr).map(({ id, image, text, author, authorProfession }) => (
           
           <div className='client_voice_item_container'>
                    
           <div key={id} className='client_voice_item'>
              <div className='images_container'>
                <div className='logo'>
                  <div></div>
                  <div className='triangle_container'>
                    <div className='triangle'></div>
                    <div className='triangle'></div>
                  </div>
                </div>
                <div className='profile_image'>
                  <img src={image} alt={`Profile of ${author}`} />
                </div>
              </div>
              <div className='description_container'>
                <p>{text}</p>
                <div className='auteur_container'>
                  <span className='auteur'>{author}</span>
                  <span className='auteur_prof'>{authorProfession}</span>
                </div>
                <div className='triangle_container_inverse'>
                  <div className='triangle_inverse'></div>
                  <div className='triangle_inverse'></div>
                </div>
              </div>
            </div>
            </div>
    
          ))}
        </Slider>
      </StyledSlider>
    </section>
  );
}

const StyledSlider = styled.div`


  overflow: hidden;
 .slick-track {
    display: flex !important;
  
    justify-content: space-evenly;!important;
    padding: 0 !important; /* Supprime le padding des côtés */
    margin: 0 !important; /* Supprime toute marge */
   
  }

  .slick-slide {
   
    margin: 0 23px !important; /* Ajoute l'espacement entre les éléments */
    display: flex;
    justify-content: center;
 
  }


  .slick-arrow {
    display: none; // Masquer les flèches
  }

  .slick-dots {
    bottom: -58px;
    left: 50%;
    transform: translateX(-50%);
    li {
      button {
        &::before {
          font-size: 16px;
          color: blue; // Changez la couleur ici
          opacity: 1; // Assurez-vous que les dots sont visibles
        }
      }
      &.slick-active button::before {
        color: gray; // Couleur du dot actif
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  languageCode: state.languageCode,
});

export default connect(mapStateToProps)(ClientVoice);
