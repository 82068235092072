import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import menuIcon from "../../../assets/images/navbar/Menu.png";
import logo from "../../../assets/images/logo.webp";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import localeFile from "../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import debounce from 'lodash/debounce';
import { setSubCategorySelected,resetSearchRecommend,resetSelectedcriteresLIST, resetSelectedProduct } from "../../../store/actions/actionCreators.js";

const strings = new LocalizedStrings(localeFile);

const Header = ({ languageCode }) => {
    strings.setLanguage(languageCode);
    const dispatch = useDispatch();

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenIcon, setMenuOpenIcon] = useState(false);

    const [closing, setClosing] = useState(false);
    const location = useLocation();
    const menuRef = useRef(null);
    const tohome=() => {
        dispatch(setSubCategorySelected(false));
        dispatch(resetSearchRecommend());
        dispatch(resetSelectedcriteresLIST())
      }
    const toggleMenu = (isMobile = false) => {
        dispatch(resetSelectedProduct());

        dispatch(setSubCategorySelected(false));
        dispatch(resetSearchRecommend());
        dispatch(resetSelectedcriteresLIST())
        if (menuOpen) {
            setClosing(true);
            setTimeout(() => {
                setMenuOpen(false);
                setClosing(false);
                if (isMobile) setMenuOpenIcon(false);
            }, 300);
        } else {
            setMenuOpen(true);
            if (isMobile) setMenuOpenIcon(true);
        }
    };

    const debouncedHandleClickOutside = useRef(
        debounce((event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }, 200)
    ).current;

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener("mousedown", debouncedHandleClickOutside);
        } else {
            document.removeEventListener("mousedown", debouncedHandleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", debouncedHandleClickOutside);
        };
    }, [menuOpen]);
    return (
        <nav className="header" role="navigation" aria-label="Main Navigation">
            <div className="header_nav">


                <div className="mayview-fr-logo">
                    <Link
                    onClick={tohome}
                        to={`/${languageCode}`}
                        role="menuitem"
                    >
                        <img src={logo} alt="Mayview Logo" loading="lazy" width="235" height="41" />
                    </Link>
                </div>
                <div ref={menuRef} className={`menu-main ${menuOpen ? "active" : ""} ${closing ? "closing" : ""}`}>
                    <div className={`${menuOpen ? "menu-main-mobile" : ""}`}>
                        <div className="menu" role="menu">
                            <Link
                                to={`/${languageCode}`}
                                className={`menu-item ${location.pathname === `/${languageCode}` ? "active" : ""}`}
                                role="menuitem"
                                onClick={toggleMenu}
                            >
                                {strings.header.Acceuil}
                            </Link>
                            <Link
                                to={`${languageCode}/${strings.navigation.aboutUs}`}
                                className={`menu-item ${location.pathname === `/${languageCode}/${strings.navigation.aboutUs}` ? "active" : ""}`}
                                role="menuitem"
                                onClick={toggleMenu}
                            >

                                {strings.header.aboutUs}
                            </Link>
                            <Link
                                to={`${languageCode}/${strings.navigation.blogs}`}
                                className={`menu-item ${location.pathname === `/${languageCode}/${strings.navigation.blogs}` ? "active" : ""}`}
                                role="menuitem"
                                onClick={toggleMenu}
                            >
                                {strings.header.Articles}
                            </Link>
                        </div>
                        {menuOpen && <IoMdClose size={38} className="closeIcon" onClick={toggleMenu} />}
                    </div>

                    {menuOpenIcon ? (
                        <div className="menu-buttons">
                            {/* <Button title={strings.header.login} /> */}
                            <LanguageSwitch />
                        </div>
                    ) : (
                        <>
                            {/* <Button title={strings.header.login} /> */}
                            <LanguageSwitch />
                        </>
                    )}
                </div>
                <div
                    className="menu-btn"
                    onClick={toggleMenu}
                    aria-label={menuOpen ? "Close menu" : "Open menu"}
                >
                    <img src={menuIcon} alt="Menu Icon"  loading="lazy" />
                </div>       </div>
        </nav>
    );
};

Header.propTypes = {
    languageCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
    languageCode: state.languageCode,
});

export default connect(mapStateToProps)(Header);
