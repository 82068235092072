import React from 'react'
import { MdOutlineCancel } from "react-icons/md";
import chat from '../../../../assets/images/Ellipse 258.png'
function Header({ closeChat }) {
  return (
    <div class="react-chatbot-kit-chat-bot-header">
   
        <div
          className="react-chatbot-kit-chat-bot-header-container"

        >
          <div className="avatar">
            <img
              className="avatar-img"
              src={chat}
            />
          </div>
          <div className="chat-Bot-header">
            <div className="chat-Bot-header-Mayview">
              <span>Mayview</span>
            </div>
            <div className="chat-Bot-header-items">
              <span className="chat-Bot-header-item-chat">Chat</span> <span className="chat-Bot-header-item-bot">Bot</span>

            </div>
          </div>
        </div>
        <div className="iconclosechat">
          <MdOutlineCancel size={25} className='m-1 iconclosechat' onClick={closeChat} /></div>


    </div>
  )
}

export default Header