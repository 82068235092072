import React, { useEffect } from "react";
import { createBrowserRouter, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, resetSelectedProduct, setSelectedProduct, setShowSearchInput } from '../store/actions/actionCreators';
import Layout from "../layouts/layout";
import HomePage from "../pages/HomePage/HomePage";
import AboutUs from "../pages/AboutUs/AboutUs";
import Blog from "../pages/Blog/Blog";
import TermsConditions from "../pages/Terms & Conditions/TermsConditions";
import BlogDetails from "../pages/Blog/DetailsBlog/DetailBlog";
import UserPolicy from "../pages/User Policy/UserPolicy";
import PageTracker from "../Google Analytics/PageTracker";
import DataPolicy from "../pages/Data Policy/DataPolicy";
import DataRequest from "../pages/Data Request/DataRequest";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lang = useSelector((state) => state.languageCode);


  useEffect(() => {
    const langFromPath = location.pathname.includes('/fr') ? 'fr' : location.pathname.includes('/en') ? 'en' : null;
    if (langFromPath && langFromPath !== lang) {
      dispatch(changeLanguage(langFromPath));
    }
  }, [location, lang, dispatch]);

  useEffect(() => {
    const langRegex = /^(\/fr|\/en)/;
    const match = location.pathname.match(langRegex);
    if (!match) {
      const languagePrefix = lang === 'fr' ? '/fr' : '/en';
      const newLocation = `${languagePrefix}${location.pathname}`;
      navigate(newLocation, { replace: true });
    }
  }, [location, lang, navigate]);


  const reservedPaths = [
    "/en/A-propos",
    "/fr/About-us",
    "/en/Articles",
    "/fr/Blogs",
    "/en/Terms-conditions",
    "/fr/Terms-conditions",
    "/en/Politique-de-confidentialite-des-utilisateurs",
    "/fr/User-privacy",

  ];

  const isReservedPath = (path) => {
    return reservedPaths.some((reservedPath) => path.startsWith(reservedPath));
  };


  useEffect(() => {
    console.log(window.location.pathname)
    if (isReservedPath(window.location.pathname)) {
      console.warn("Chemin réservé détecté : aucune recherche de produit effectuée.");
      navigate('/', { replace: true });
    }
  }, );

  // useEffect(() => {
  //   const pathSegments = location.pathname.split('/');
  //   const productName = decodeURIComponent(pathSegments[pathSegments.length - 1] || '');
  
  //   if (productName) {
  //     dispatch(setSelectedProduct(productName));
  //   } else {
  //     dispatch(setShowSearchInput(false));
  //   }
  // }, [location, dispatch]);




  return (
    <PageTracker>
      <Layout />
    </PageTracker>
  );
};

export const router = createBrowserRouter([
  {
    element: <AppRoutes />,
    children: [
      { path: "fr/:produit", element: <HomePage /> },
      { path: "en/:produit", element: <HomePage /> },

      { path: "/fr", element: <HomePage /> },
      { path: "/en", element: <HomePage /> },
      { path: "/fr/A-propos", element: <AboutUs /> },
      { path: "/en/About-us", element: <AboutUs /> },
      { path: "/fr/Articles", element: <Blog /> },
      { path: "/en/Blogs", element: <Blog /> },
      { path: "/fr/Terms-conditions", element: <TermsConditions /> },
      { path: "/en/Terms-conditions", element: <TermsConditions /> },
      { path: "/fr/Blog-Detail/:id", element: <BlogDetails /> },
      { path: "/en/Blog-Detail/:id", element: <BlogDetails /> },
      { path: "/fr/Politique-de-confidentialite-des-utilisateurs", element: <UserPolicy /> },
      { path: "/en/User-privacy", element: <UserPolicy /> },
      { path: "/en/Data-policy", element: <DataPolicy /> },
      { path: "/fr/Politique-des-donnees", element: <DataPolicy /> },
      { path: "/en/Data-request", element: <DataRequest /> },
      { path: "/fr/Demande-de-donnees", element: <DataRequest /> },
      { path: "*", element: <Navigate to="/" replace /> },

    ],
  },
]);

