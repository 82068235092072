import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import blogMainBanner from '../../assets/images/Blog/blogmainbanner.png';
import { IoIosArrowForward } from "react-icons/io";
import { FaRegArrowAltCircleRight, FaRegArrowAltCircleLeft } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { connect } from "react-redux";
import './Blog.css';
import { apiBlog } from '../../api/api';
import localeFile from "../../content/content.js";
import LocalizedStrings from "react-localization";
import userInteractionTracker from '../../Google Analytics/userInteractionTracker.js';
import { useDispatch,useSelector } from 'react-redux';
import { resetSearchRecommend } from '../../store/actions/actionCreators.js';
import { Helmet } from 'react-helmet';

const strings = new LocalizedStrings(localeFile);
const Blog = (props) => {
  const { languageCode } = props;
  strings.setLanguage(languageCode);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const indexOfLastBlog = currentPage * itemsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(blogs.length / itemsPerPage);
  const { trackButtonClick } = userInteractionTracker();

  const isRecommendationVisible = useSelector(state => state.isRecommendationVisible);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(resetSearchRecommend());


       document.body.classList.remove('no-scroll');

   
 }, []);
  const fetchBlogs = async () => {
    try {
      const response = await apiBlog.getBlogs();
     
      const filteredBlogs = response.data.blogs.filter(blog => blog.language === languageCode);
      setBlogs(filteredBlogs);
      console.log(filteredBlogs)
    } catch (error) {
      console.error('Failed to fetch blogs:', error);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, [languageCode]);

  const handleBlogClick = (blog) => {
    // Track the click event with category, action, and label
    trackButtonClick('Détails article', 'Button Click', `Titre du blog: ${blog.titre}`);

    if (languageCode === "fr") {
      navigate(`/fr/Blog-Detail/${blog._id}`);


    } else if (languageCode === "en") {
      navigate(`/en/Blog-Detail/${blog._id}`);


    }
  };

  return (
    <>
       {languageCode=='fr'? <Helmet>
    <title>Articles et Conseils d'Achat - Comparatifs et Avis Mayview    </title>
    <meta name="description" content="Retrouvez des articles détaillés, des conseils d'achat high-tech, et des comparatifs de produits pour vous aider à faire les meilleurs choix sur Mayview." />
    <meta name="keywords" content="Avis produits e-commerce, Comparatif de produits, Agrégateur d'avis utilisateurs, Conseils d'achat high-tech, Recherches collectives d’avis, Guide d'achat, outils de recommandation d'achat" />
  </Helmet>:
 <Helmet>
 <title>Mayview Blogs - Reviews, Comparisons & Purchase Advice
 </title>
 <meta name="description" content="Explore in-depth articles, high-tech buying tips, and product comparisons on Mayview. Your go-to source for smarter shopping decisions." />
 <meta name="keywords" content=" Avis produits e-commerce , Comparatif de produits , Agrégateur d'avis utilisateurs, Conseils d'achat high-tech, Recherches collectives d’avis, Guide d'achat, outils de recommandation d'achat" />
</Helmet>
}

    <section className="blog-section">
      <div className="blog-banner-container">
        <div className="blog-banner">
          <img src={blogMainBanner} alt="Blog Banner" />
        </div>
        <div className="blog-header">
          <div className="blog-tag-container"><span className="blog-tag">{strings.blog.blog}</span></div>
          <h3 className="blog-mannertitle">{strings.blog.actualite}</h3>
          <div className="blog-navigation">
            <IoIosArrowForward size={25} />
          </div>
        </div>
      </div>
      {/* <div className="blog-search">
        <input type="text" placeholder={strings.blog.chercher} />
        <div className="blog-search-icon-container">
          <CiSearch className="blog-search-icon" />
        </div>
      </div> */}
      <div className="blog-list">
        {currentBlogs.map((blog) => (
          <div key={blog.id} className="blog-item" onClick={() => handleBlogClick(blog)}>
            <div className="blog-image">
              <img src={blog.previewImage} alt={blog.titre} />
            </div>
            <div className="blog-content">
              {/* <div className="blog-tags">
                {blog.tags.map((tag, index) => (
                  <span key={index} className="tag-label">
                    {tag}
                  </span>
                ))}
              </div> */}
              <h5 className="blog-title">{blog.titre}</h5>
            </div>
            <div className="blog-author">
              <div className="author-image">           
                    <img  src={blog.authorImage} alt={blog.auteur} />
              </div>
              <span className="author-name">{blog.auteur}</span>
              <span className="blog-date">
                {new Date(blog.dateAjout).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="blog-pagination">
        <FaRegArrowAltCircleLeft
          size={40}
          className={`blog-pagination-icon ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        />
        <span>Page {currentPage} sur {totalPages}</span>
        <FaRegArrowAltCircleRight
          size={40}
          className={`blog-pagination-icon ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        />
      </div>
    </section>   </>
  );
}
const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};
export default connect(mapStateToProps)(Blog);