import React, { useEffect, useState } from 'react';
import teamImage from '../../../../assets/images/HomePage/87d854544c5d4ae07353adaa29ee85e7.webp';
import './About_us.css'
import Button from '../../../ui/Button.js';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const strings = new LocalizedStrings(localeFile);
function AboutUs(props) {

    strings.setLanguage(props.languageCode);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Vous pouvez définir une largeur seuil
    const navigate = useNavigate(); // Initialiser useNavigate

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Définir à nouveau si mobile lors du redimensionnement
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleClick = () => {
        navigate(`${strings.navigation.aboutUs}`);
    };
    
 
    return (
        <section className={` ${props.visible == true ? 'about-us' : 'about-us tow'}`}>

            <div className='about-us__content'  >
                <div className='about-us__heading'>
                    <h2 className=' display1'>{strings.aboutUs.whoAreWe} </h2>
                </div>

                {isMobile && <div className='about-us__image'>
                    <img src={teamImage} alt="Team" />
                </div>}
                <div className='about-us__description '>
                    <p className=''>
                        {strings.aboutUs.description}
                    </p>
                    <div className='button-container '>
                        {props.visible}
                      
                        
                            
                         <Button title={strings.LearnMore} visible={props.visible} color="#CB6843" action={handleClick} />    

                </div>

                </div>
            </div>
            {isMobile == false && <div className='about-us__image'>
                <img src={teamImage} alt="Team" />
            </div>}


        </section>
    );
}


const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(AboutUs);