import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Sidebar.css";
import { connect } from "react-redux";
import localeFile from "../../content/DataRequest.js";
import LocalizedStrings from "react-localization";
import PropTypes from "prop-types";
const strings = new LocalizedStrings(localeFile);
const SidebarItem = ({ id, isSelected, handleClick }) => (
    <p className={`item ${isSelected ? "selected" : ""}`} onClick={(event) => handleClick(event, id)}>
        <Link to={`#${id}`} smooth>
            {strings.titles[id]}
        </Link>
    </p>
);
const Sidebar = ({ selectedId, handleClick, languageCode }) => {
    const sidebarItems = [
        "What_Data_Request",
        "How_To_Submit",
        "What_HAPPEN",
        "Need_HELP",
    ];
    strings.setLanguage(languageCode);
    return (
        <div className="sidebar">
            {sidebarItems.map((item) => (
                <SidebarItem
                    key={item}
                    id={item}
                    isSelected={selectedId === item}
                    handleClick={handleClick}
                />
            ))}
        </div>
    );
};
const mapStateToProps = (state) => ({
    languageCode: state.languageCode,
});
Sidebar.propTypes = {
    selectedId: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    languageCode: PropTypes.string.isRequired,
};
export default connect(mapStateToProps)(Sidebar);