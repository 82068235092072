import React, { useEffect, useState } from 'react';
import './ResultSearchName.css'; // Ensure your CSS includes the provided styles
import { useSelector } from 'react-redux';
import PointsContainer from './PointsContainer';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import userInteractionTracker from '../../../../Google Analytics/userInteractionTracker.js';

const strings = new LocalizedStrings(localeFile);

function ResultSearchName(props) {
    const selectedProductName = useSelector(state => state.selectedProductName);
    strings.setLanguage(props.languageCode);
    const { trackButtonClick } = userInteractionTracker();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (selectedProductName) {
            // Simulate a delay to showcase loading (adjust as needed)
            setTimeout(() => setLoading(false), 1000);
            // Track product search interaction
            trackButtonClick('Recherche de Produit', 'Produit Cherché', `Nom du Produit: ${selectedProductName}`);
        } else {
            setLoading(false);
        }
    }, [selectedProductName, trackButtonClick]);

    return (
        <>
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <section className={`result-search-section ${loading ? 'blurred' : ''}`}>
                <h1 className='search-title'>
                    {strings.searchEngine.Results_for}: <span className='product-name'>{selectedProductName}</span>
                </h1>
                <PointsContainer />
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(ResultSearchName);
