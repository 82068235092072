import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const PageTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const pageTitleMap = {
      "/": "Home Page",
      "/About-us": "About Us",
      "/blog": "Blog",
      "/Blog-Detail": "Blog Details",
      "/Terms-conditions": "Terms & Conditions",
      "/User-privacy": "User Policy",
      "/Data-policy": "Data Policy"
    };

    const pagePath = location.pathname;
    const pageTitle = pageTitleMap[pagePath] || "Default Title";

    // Track page view
    ReactGA.send({ hitType: "pageview", page: pagePath, title: pageTitle });
  }, [location]);

  return children;
};

export default PageTracker;