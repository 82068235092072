import React from 'react';
import { MdArrowBackIosNew } from "react-icons/md";
import Button from '../../../../ui/Button';
import localeFile from "../../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import './BackButton.css'

const strings = new LocalizedStrings(localeFile);
const ButtonsBackAndSerach = (props) => {
  const { show, onClick ,search , action ,desactive} = props;
  strings.setLanguage(props.languageCode);
  return (
    show && (
      <div className={`row-buttons show`}>
        <button className="back-button" onClick={onClick}>
          <span className='back-icon'>
          <MdArrowBackIosNew  />
          </span>
     
              <span className='back-title'>
              {strings.back}
              </span>
         
      
        </button>
          { search  && <Button desactive={desactive} title={strings.search} action={action} color='#CB6843'/>}
      </div>
    )
  );
};


const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(ButtonsBackAndSerach);
