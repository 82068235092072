import React, { useEffect, useState } from 'react';
import OurMission from '../../../../assets/images/HomePage/image.webp'
import Ourvision from '../../../../assets/images/HomePage/Ourvision.webp'
import './OurMissionAndvision.css'
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect, useDispatch, useSelector } from "react-redux";
const strings = new LocalizedStrings(localeFile);
function Ourmission(props) {

    strings.setLanguage(props.languageCode);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Vous pouvez définir une largeur seuil
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Définir à nouveau si mobile lors du redimensionnement
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
    <>
    <section className='OurMission_section'>
       { isMobile==false && <div className='image_container'>
            <img alt='Our Mission' src={OurMission}/>
        </div>}

        <div className='description_container'>
        <div className='title_description'>
            <h2>{strings.ourMissionAndVision.vision.title}</h2>
        </div>
        { isMobile&& <div className='image_container'>
            <img alt='Our Mission' src={OurMission}/>
        </div>}
        <div className='description'>
                <p>{strings.ourMissionAndVision.vision.description}</p>
        </div>
        </div>
    </section>
       <section className='OurVision_section'>
      

       <div className='description_container'>
       <div className='title_description'>
       <h2>{strings.ourMissionAndVision.mission.title}</h2>
       </div>
       { isMobile && <div className='image_container'>
        <img alt='Our vision'  src={Ourvision}/>
        </div>}
       <div className='description'>
       <p>{strings.ourMissionAndVision.mission.description}</p>
       </div>
       </div>
       {  isMobile==false &&  <div className='image_container'>
       <img alt='Our vision'  src={Ourvision}/>
       </div>}
      
   </section></>
  )
}




const mapStateToProps = (state) => {
    return {
      languageCode: state.languageCode,
    };
  };
  
  export default connect(mapStateToProps)(Ourmission);