import React, { useEffect, useState } from 'react';
import Proposition from './Proposition'
import Charts from './Charts'
import './ResultRecommendation.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

 function ResultRecommendation(props) {
  const [recommendedProducts, setRecommendedProducts] = useState(null);
  const criteresListSelected = useSelector(state => state.criteresListSelected);
  const subCategorySelected = useSelector(state => state.isSubCategorySelected);
  const recomendedResult = useSelector(state => state.recomendedResult);
  const RecommendResultNumber = useSelector(state => state.RecommendResultNumber);

  const loading = useSelector(state => state.loading);
  strings.setLanguage(props.languageCode);
  useEffect(() => {
   console.log('RecommendResultNumber',RecommendResultNumber)
  }, [])
  
  return (
    <>
  
    <section className='comparison-section'>

      <div className='product-comparison-content'>


        <div className='list-products-title'>
          <h4> {subCategorySelected} {/* <span> 140 produits</span>  */}
          
          </h4>
        </div>
        <div className='list-products-criteres'>
          {criteresListSelected
            .sort((a, b) => a.order - b.order) // Trier par l'attribut 'order'
            .map((criterium, index) => (
              <div key={index} className='criteres-item'>
                {criterium.critere.name ?criterium.critere.name : criterium.critere}
              </div>
            ))}
        </div>
        <div className='list-product-description'>
               <p> <span> {recomendedResult.length>10 ? RecommendResultNumber:recomendedResult.length}  </span> {strings.recommednation.prodct_match} </p>
         

        </div>

      </div>

      <Charts setRecommendedProducts={setRecommendedProducts} recommendedProducts={recommendedProducts} />

      {/* <Proposition  /> */}
    </section> </>
  )
}
const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(ResultRecommendation);