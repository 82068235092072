import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { apiMTRecom } from '../../../../../../api/api';
import localeFile from "../../../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";


const strings = new LocalizedStrings(localeFile);
 function SearchBarCritere(props) {
  const { category, subCategory, criteresOptions, critereSelected, newSelectedList, setnewSelectedList } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  strings.setLanguage(props.languageCode);

 
  const [inputValue, setInputValue] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState();
  const searchBarStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: isMobile ? '7px' :'15px',
    width:isMobile ? '174px' :'256px' ,
    height: '52px',
    background: '#000000',
    borderRadius: '200px',
   
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: isMobile ? '12px': '18px',
    lineHeight: '125%',
    textAlign: 'center',

  };
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      ...searchBarStyles,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFFFFF !important', 
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFFFFF !important',
    }),
    input: (provided) => ({
      ...provided,
      color: '#FFFFFF !important', 
    }),
  };
  const handleInputChange = async (value) => {
    setInputValue(value);

    try {
      const results = await autocompleteCriteres( value,category,subCategory
      );
      const filteredResults = results.filter((result) => {
        return !criteresOptions.some(
          (critere) => critere.critere === result.name
        );
      });

      setAutocompleteResults(filteredResults);
    } catch (error) {
      console.error("Error fetching autocomplete results:", error);
    }
  };
  const autocompleteCriteres = async (input, category, subCategory) => {

    try {
      const response = await apiMTRecom.fetchCriteres(input,category,subCategory,)
      return response.data;
    } catch (error) {
      console.error("Error fetching criteres:", error);
      throw error; 
    }
  };
  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setnewSelectedList((prevList) => {
      const newSelection = selectedValues[selectedValues.length - 1];
      if (!prevList.some(item => item.name === newSelection.name)) {
        return [...prevList, newSelection];
      }
      return prevList;
    });

    setAutocompleteResults(prevResults => prevResults.filter(
      (result) => !selectedValues.some((option) => option.name === result.name)
    ));

    critereSelected(selectedValues[selectedValues.length - 1], true);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Select
      styles={customSelectStyles} 

      placeholder={strings.recommednation.Search_your_criteria}
      key="searchCriteria"
      className="select-option"
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      options={
        (autocompleteResults || [])
          .filter(result =>
            !newSelectedList.some(selected => selected.name === result.name)
          )
          .map(result => ({
            value: result,
            label: result.name,
          }))
      }
      value={[]}
      isMulti
    />
  )
}

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(SearchBarCritere);