const DataPolicy = {
    eng: {
        main: `At Mayview, we are committed to respecting the confidentiality and security of your personal data. This policy outlines the information we collect, how we use it, and the measures we take to protect it.`,
        whatdata: {
            main: `We collect the following data when you use Mayview:`,
            point1: `Personal Information: Name, email address, phone number (if you register or contact our support team).`,
            point2: `Browsing Data: Information about your use of the site, such as pages visited, products viewed, categories explored, your IP address, and your geographic location.`,
            point3: `Behavioral Data: How you interact with our content, your reviews and comments, and your product preferences.`
        },
        howToUse: {
            main: `We use your data for the following purposes:`,
            point1: `Improve Our Service: To analyze user behavior and tailor content to your needs.`,
            point2: `Personalize Your Experience: To provide product recommendations based on your preferences.`,
            point3: `Communicate with You: To respond to your requests, send you updates, or share information regarding Mayview.`
        },
        datashare: `We only share your personal data with service providers necessary for the operation of Mayview, in compliance with this privacy policy. We do not sell or rent your information to third parties.`,
        dataprotection: `We take appropriate security measures to protect your data from unauthorized disclosure, loss, or misuse. This includes encryption protocols and restricted access to sensitive information.`,
        datakeep: `We retain your data for as long as necessary to fulfill the purposes for which it was collected or as required by law.`,
        rights: {
            main: `You have the right to:`,
            point1: `Access your personal data that we have collected.`,
            point2: `Correct or update your personal information.`,
            point3: `Delete your personal data, as permitted by law.`,
            point4: `Object to the processing of your data on legitimate grounds.`,
            main2: `If you wish to exercise these rights or need more information on how we handle your data, please contact us at: contact@maydena.fr .`
        },
        editpolicy: `We may update this policy from time to time. Any changes will be posted on this page, and if necessary, we will inform you accordingly.`,

        titles: {
            WHAT_DATA: `1. What Data Do We Collect?`,
            HOW_TO_USE: `2. How Do We Use Your Data?`,
            DATA_SHARE: `3. Sharing Your Data`,
            DATA_PROTECTION: `4. Protecting Your Data`,
            DATA_KEEPING: `5. Data Retention`,
            RIGHTS: `6. Your Rights`,
            EDIT_POLICY: `7. Changes to This Policy`
        },
        policy: `Mayview's Data Policy`,
        lastmodif: "Last modification :"


    },

    fr: {
        main: `Chez <strong>Mayview</strong>, nous nous engageons à respecter la confidentialité et la sécurité de vos données personnelles. Cette politique décrit les informations que nous collectons, la manière dont nous les utilisons, et les mesures que nous prenons pour les protéger.`,
        whatdata: {
            main: `Nous collectons les données suivantes lorsque vous utilisez Mayview :`,
            point1: `<strong>Informations personnelles :</strong> Nom, adresse e-mail, numéro de téléphone (si vous vous inscrivez ou contactez notre support).`,
            point2: `<strong>Données de navigation :</strong> Informations sur votre utilisation du site, telles que les pages visitées, les produits consultés, les catégories explorées, votre adresse IP et votre emplacement géographique.`,
            point3: `<strong>Données comportementales :</strong> Comment vous interagissez avec notre contenu, vos commentaires et avis, ainsi que vos préférences sur les produits.`
        },
        howToUse: {
            main: `Vos données sont utilisées pour :`,
            point1: `<strong>Améliorer notre service :</strong> Pour analyser les comportements des utilisateurs et adapter notre contenu à vos besoins.`,
            point2: `<strong>Personnaliser votre expérience :</strong> Pour vous fournir des recommandations de produits basées sur vos préférences.`,
            point3: `<strong>Communiquer avec vous :</strong> Pour répondre à vos demandes, vous envoyer des mises à jour ou des informations concernant Mayview.`
        },
        datashare: `Nous ne partageons vos données personnelles qu’avec des prestataires de services nécessaires au bon fonctionnement de Mayview, dans le respect de cette politique de confidentialité. Nous ne vendons ni ne louons vos informations à des tiers.`,
        dataprotection: `Nous prenons des mesures de sécurité appropriées pour protéger vos données contre toute divulgation, perte ou utilisation non autorisée. Cela inclut des protocoles de cryptage et un accès restreint aux données sensibles.`,
        datakeep: `Nous conservons vos données aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées, ou conformément aux exigences légales.`,
        rights: {
            main: `Vous avez le droit de :`,
            point1: `<strong>Accéder</strong> à vos données personnelles que nous avons collectées.`,
            point2: `<strong>Rectifier</strong> ou <strong>mettre à jour</strong> vos informations personnelles.`,
            point3: `<strong>Supprimer</strong> vos données personnelles dans la mesure permise par la loi.`,
            point4: `<strong>Contester</strong> le traitement de vos données pour des raisons légitimes.`,
            main2: `Si vous souhaitez exercer ces droits ou obtenir plus d’informations sur la manière dont nous traitons vos données, contactez-nous à l'adresse suivante : contact@maydena.fr .`
        },
        editpolicy: `Nous pouvons mettre à jour cette politique de temps en temps. Toute modification sera affichée sur cette page, et, si nécessaire, nous vous en informerons.`,
    
        titles: {
            WHAT_DATA: `1. Quelles données collectons-nous ?`,
            HOW_TO_USE: `2. Comment utilisons-nous vos données ?`,
            DATA_SHARE: `3. Partage de vos données`,
            DATA_PROTECTION: `4. Protection de vos données`,
            DATA_KEEPING: `5. Conservation de vos données`,
            RIGHTS: `6. Vos droits`,
            EDIT_POLICY: `7. Modifications de cette politique`
        },
        policy:`Politique de Données de Mayview`,
        
        lastmodif: "Dernière modification :"
    }

}
export default DataPolicy;