import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logowhite from '../../../assets/images/logowhite.webp';
import localeFile from "../../../content/content";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const Footer = (props) => {
    strings.setLanguage(props.languageCode);

    return (
        <footer>
            <div className="footer-nav-main">

                <div className="footer-nav mobile">


                    <div className="footer-nav-part-one ">


                        <div className="footer-section">
                            <ul>
                                <h6>{strings.footer.company}</h6>
                                <li><Link to={`/${props.languageCode}`}>{strings.header.Acceuil}</Link></li>
                                <li><Link to={`${props.languageCode}/${strings.navigation.aboutUs}`}>{strings.header.aboutUs}</Link></li>
                                <li><Link to={`${props.languageCode}/${strings.navigation.blogs}`}>{strings.header.Articles}</Link></li>
                            </ul>
                        </div>
                        <div className="footer-section">
                            <ul>
                                <h6>{strings.footer.others}</h6>
                                <li><Link to={`${props.languageCode}/${strings.navigation.termsConditions}`}>{strings.footer.terms}</Link></li>
                                <li><Link to={`${props.languageCode}/${strings.navigation.userPrivacy}`}>{strings.footer.userPrivacy}</Link></li>
                                <li><Link to={`${props.languageCode}/${strings.navigation.datapolicy}`}>{strings.footer.datapolicy}</Link></li>
                                <li><Link to={`${props.languageCode}/${strings.navigation.dataRequest}`}>{strings.footer.dataRequest}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-section">
                        <ul>
                            <h6>{strings.footer.solutions}</h6>
                            <li><Link to="#">{strings.footer.whyChoose}</Link></li>
                            <li><Link to="#">{strings.footer.evaluationAndReviews}</Link></li>
                            <li><Link to="#">{strings.footer.productRecommendation}</Link></li>
                            <li><Link to="#">{strings.footer.carbonCalculator}</Link></li>
                            <li><Link to="#">{strings.footer.fakeReviewDetection}</Link></li>
                        </ul>
                    </div>



                </div>

                <div className="footer-nav notmobile">
                    <div className="footer-section">
                        <ul>
                            <h6>{strings.footer.company}</h6>
                            <li><Link to={`/${props.languageCode}`}>{strings.header.Acceuil}</Link></li>
                            <li><Link to={`${props.languageCode}/${strings.navigation.aboutUs}`}>{strings.header.aboutUs}</Link></li>
                            <li><Link to={`${props.languageCode}/${strings.navigation.blogs}`}>{strings.header.Articles}</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <ul>
                            <h6>{strings.footer.solutions}</h6>
                            <li><Link to="#">{strings.footer.whyChoose}</Link></li>
                            <li><Link to="#">{strings.footer.evaluationAndReviews}</Link></li>
                            <li><Link to="#">{strings.footer.productRecommendation}</Link></li>
                            <li><Link to="#">{strings.footer.carbonCalculator}</Link></li>
                            <li><Link to="#">{strings.footer.fakeReviewDetection}</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <ul>
                            <h6>{strings.footer.others}</h6>
                            <li><Link to={`${props.languageCode}/${strings.navigation.termsConditions}`}>{strings.footer.terms}</Link></li>
                            <li><Link to={`${props.languageCode}/${strings.navigation.userPrivacy}`}>{strings.footer.userPrivacy}</Link></li>
                            <li><Link to={`${props.languageCode}/${strings.navigation.datapolicy}`}>{strings.footer.datapolicy}</Link></li>
                            <li><Link to={`${props.languageCode}/${strings.navigation.dataRequest}`}>{strings.footer.dataRequest}</Link></li>
                        </ul>
                    </div>





                </div>
            </div>

            <div className="mayview-section">
                <div className="mayview-content-container">


                    <div className="mayview-content">
                        <p>{strings.footer.mayviewIntro1}</p>
                        <p>{strings.footer.mayviewIntro2}</p>
                        <p>{strings.footer.mayviewIntro3}</p>
                    </div>
                    <div className="mayview-button-container">
                        <button className="mayview-button">{strings.footer.mayviewButton}</button>
                    </div>
                </div>
            </div>

            <div className="mayview-footer">
                <div className="mayview-footer-container">


                    <div className="mayview-footer-content">
                        <div className='descrip'>
                            <div className="footer-line"></div>
                            <div className="footer-text">
                                <p>{strings.footer.joinCommunity}</p>
                            </div>
                        </div>
                        {/* <div className="footer-copyright">
                        <span>{strings.footer.copyright}</span> <span>{strings.footer.copyright_platform}</span>
                    </div> */}
                    </div>
                    <div className="footer-logo">
                        <img src={logowhite} alt="Mayview Logo" />
                    </div>
                </div>
                <div className="footer-copyright">
                    <span>{strings.footer.copyright}</span> <span>{strings.footer.copyright_platform}</span>
                </div>
            </div>
        </footer>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Footer);
