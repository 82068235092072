import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "./popup.css";
import ManageCookies from "./manageCookies";
import { MdOutlineCancel } from "react-icons/md";
import localeFile from "../../../content/content";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

const strings = new LocalizedStrings(localeFile);

const CookiesPopup = (props) => {
  strings.setLanguage(props.languageCode);

  const [showPop, setshowPop] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["cookiesAccepted"]);
  const [manageCookies, setManageCookie] = useState(false);


  // Date d'expiration de 90 jours
  const getCookieExpirationDate = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 90);
    return expirationDate;
  };


  const initializeAnalytics = () => {
    ReactGA.initialize('G-J305XN1017');

  };

  const loadHotjar = () => {

    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 5122424, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

  };

  const acceptCookies = () => {

    setCookie("cookiesAccepted", true, { path: "/", expires: getCookieExpirationDate() });
    setCookie("hotjar", true, { path: "/" });
    setCookie("googleAnlytics", true, { path: "/" });

    ReactGA.event({
      category: "User",
      action: "Clicked Accept cookies button",
    });
    loadHotjar(); // Load Hotjar after user accepts cookies
    initializeAnalytics(); // Initialise Google Analytics après acceptation

  };


  const ManageTheCookies = () => {
    setManageCookie(!manageCookies);
  };

  if (cookies.cookiesAccepted) {
    return null;
  }
  return (
    showPop && <div className="cookies-popup">
      {manageCookies ? (
        <>
          <div className="cookies-popup-manage-title">
            <h5> {strings.Cookies.Cookie_Consent_reference} </h5>
            <MdOutlineCancel
              onClick={ManageTheCookies}
              className="cookies-popup-manage-icon"
            />
          </div>
          <ManageCookies setshowPop={setshowPop}

          />
        </>
      ) : (
        <>
          <h5> {strings.Cookies.cookies_title}</h5>
          <p>
            {strings.Cookies.cookies_description}
            <Link className="cookie-policy" to={`${props.languageCode}/${strings.navigation.userPrivacy}`}>
          {strings.Cookies.cookie_Policy}
        </Link>
          </p>
          <button className="accpet-cookies" onClick={acceptCookies}>
            {strings.Cookies.accept_Cookies}
          </button>
          <div className="manage-cookies-button" onClick={ManageTheCookies}>
            <a> {strings.Cookies.manage_cookies}</a>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(CookiesPopup);