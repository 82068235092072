import React from 'react'

function WritingLoading() {
  return (
    <div className="writing-container">
    {" "}
    <div className="writing"> </div>
  </div>
  )
}

export default WritingLoading