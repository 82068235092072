import React from 'react'

function GreetMessage({closeMessage,strings}) {
  return (
    <div className="chat-greet-container">
    <div data-align="right" class="closed">
      <div data-mobile="false" class="greeting-wrapper">
        <div class="greeting-close">
          <div
            data-actions="true"
            data-size="small"
            class="tpl-close"
            onClick={closeMessage}
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </div>
        </div>
        <div class="greeting-content-wrapper" style={{}}>
       {strings.chatbot.greetmessage}
        </div>
      </div>
      <div class="bubble" style={{ background: "rgb(0, 102, 255)" }}>
        <div
          data-status="loaded"
          data-cover="true"
          class="lazy-img"
        ></div>
      </div>
    </div>
  </div>
  )
}

export default GreetMessage