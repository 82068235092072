import ReactGA from 'react-ga4';

const userInteractionTracker = () => {
  const trackButtonClick = (category, action, label) => {
    ReactGA.event({
      category: category, 
      action: action,     
      label: label        
    });
  };

  return { trackButtonClick };
};

export default userInteractionTracker;
