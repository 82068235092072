import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setShowSearchInput  ,setShowRecommend ,SETSHOWBUTTONS} from '../../../../../store/actions/actionCreators'
import './BannerActionButtons.css'


function BannerActionButtons({strings }) {
  const dispatch = useDispatch();

  const toggleView = (type) => {
    
    setTimeout(() => {
      if (type === 'search') {
        dispatch(setShowSearchInput (true));
        dispatch(SETSHOWBUTTONS(false));
        dispatch(setShowRecommend (false));
      } else if (type === 'recommend') {
        dispatch(setShowRecommend (true));
        dispatch(SETSHOWBUTTONS(false));
        dispatch(setShowSearchInput (false));     

      
      }
    }, 500);
  };


  return (
    <div className={`main-banner__buttons   `}>
      <button
        className=' main-banner__button--search'
        onClick={() => toggleView('search')}
      >
        {strings.mainBanner.searchButton}
      </button>
      <div className='buttons-div'></div>
      <button
        className=' main-banner__button--recommendation'
        onClick={() => toggleView('recommend')}
      >
        {strings.mainBanner.recommendButton}
      </button>
    </div>
  );
}

export default BannerActionButtons;
