import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/common/Header/Header";
import Footer from "../components/common/Footer/Footer";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchRecommend, resetSelectedProduct } from '../store/actions/actionCreators';
import Chat from "../components/common/Chat/chat";
import CookiesPopup from '../components/common/popCookies/popupCookies'
export default function Layout() {
  const location = useLocation();
  const selectedProductName = useSelector(state => state.selectedProductName);
  const searchRecommend= useSelector(state => state.searchRecommend);
  const dispatch = useDispatch();
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };


    scrollToTop();


    const handleClick = (event) => {
      if (event.target.getAttribute("href") === "/") {
        dispatch(resetSelectedProduct());
        dispatch(resetSearchRecommend());
        scrollToTop();
      }
    };

    document.addEventListener("click", handleClick);


    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [location]);


  return (
    <>
      <Header />
      <Outlet />
      <CookiesPopup/> 
      <Chat/> 
       <Footer />
    </>
  );
}
