import React, { useState, useEffect } from 'react';
import { IoIosPhonePortrait } from "react-icons/io";
import { FaTv } from "react-icons/fa";
import { CiCamera } from "react-icons/ci";
import { BsPrinter, BsMusicPlayer } from "react-icons/bs";
import { IoWatchOutline } from "react-icons/io5";
import { CiHome } from "react-icons/ci";
import { CgGames } from "react-icons/cg";
import BackButton from '../common/BackButton';
import Criteres from './criteres/Criteres';
import { useDispatch, useSelector } from 'react-redux';
import { SETSHOWBUTTONS, setSubCategorySelected, setShowRecommend } from '../../../../../store/actions/actionCreators';
import localeFile from "../../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import './recommendation.css'
import { IoClose } from "react-icons/io5";

const categories = [
  {
    id: "66dadda9ddce8e57ddefe578",
    namefr: "Appareils Mobiles",
    name: "Mobile Devices",
    icon: <IoIosPhonePortrait />,
    subcategories: [
      {
        _id: "66e03148521dd39776ff6f24",
        name: "smartphones",
        namefr: "Smartphones"
      },
      {
        _id: "66e03148521dd39776ff6f25",
        name: "tablets",
        namefr: "Tablettes"
      },
      {
        _id: "66e03148521dd39776ff6f26",
        name: "e-readers",
        namefr: "E-Readers"
      }
    ]
  },
  {
    id: "66dadda9ddce8e57ddefe579",
    namefr: "Montres et Accessoires Connectés",
    name: "Wearables",
    icon: <IoWatchOutline />,
    subcategories: [
      {
        _id: "66e03148521dd39776ff6f27",
        name: "smartwatches",
        namefr: "Montres Connectées"
      },
      {
        _id: "66e03148521dd39776ff6f28",
        name: "fitness trackers",
        namefr: "Bracelets de Fitness"
      },
      {
        _id: "66e03148521dd39776ff6f29",
        name: "sports watches",
        namefr: "Montres de Sport"
      }
    ]
  },
  {
    id: "66dadda9ddce8e57ddefe57a",
    namefr: "Photo & Vidéo",
    name: "Photo & Video",
    icon: <CiCamera />,
    subcategories: [
      {
        _id: "66e03148521dd39776ff6f2a",
        name: "cameras",
        namefr: "Appareils Photo"
      },
      {
        _id: "66e03148521dd39776ff6f2b",
        name: "camera lenses",
        namefr: "Objectifs d'Appareil Photo"
      },
      {
        _id: "66e03148521dd39776ff6f2c",
        name: "camcorders",
        namefr: "Caméscopes"
      },
      {
        _id: "66e03148521dd39776ff6f2d",
        name: "action cameras",
        namefr: "Caméras d'Action"
      }
    ]
  },
  {
    id: "66dadda9ddce8e57ddefe57b",
    namefr: "Audio",
    name: "Audio",
    icon: <BsMusicPlayer />,
    subcategories: [
      {
        _id: "66e0315a521dd39776ff6f2e",
        name: "headphones",
        namefr: "écouteurs"
      },
      {
        _id: "66e0315a521dd39776ff6f30",
        name: "PC and gaming headsets",
        namefr: "Casques PC et Gaming"
      },
      {
        _id: "66e0315a521dd39776ff6f31",
        name: "Bluetooth headsets",
        namefr: "Casques Bluetooth"
      },
      {
        _id: "66e0315a521dd39776ff6f32",
        name: "portable speakers",
        namefr: "Enceintes Portables"
      },
      {
        _id: "66e0315a521dd39776ff6f33",
        name: "smart speakers",
        namefr: "Enceintes Intelligentes"
      },
      {
        _id: "66e0315a521dd39776ff6f34",
        name: "soundbars",
        namefr: "Barres de Son"
      },
      {
        _id: "66e0315a521dd39776ff6f35",
        name: "microphones",
        namefr: "Microphones"
      },
      {
        _id: "66e0315a521dd39776ff6f36",
        name: "voice recorders",
        namefr: "Enregistreurs Vocaux"
      },
      {
        _id: "66e0315a521dd39776ff6f37",
        name: "DJ controllers",
        namefr: "Contrôleurs DJ"
      },
      {
        _id: "66e0315a521dd39776ff6f38",
        name: "keyboard controllers",
        namefr: "Claviers Contrôleurs"
      },
      {
        _id: "66e0315a521dd39776ff6f39",
        name: "record players",
        namefr: "Tourne-disques"
      }
    ]
  },
  {
    id: "66dadda9ddce8e57ddefe57c",
    namefr: "Ordinateurs & Accessoires",
    name: "Computers & Accessories",
    icon: <FaTv />,
    subcategories: [
      {
        _id: "66e0315a521dd39776ff6f3a",
        name: "laptops",
        namefr: "Ordinateurs Portables"
      },
      {
        _id: "66e0315a521dd39776ff6f3b",
        name: "gaming PCs",
        namefr: "PC Gaming"
      },
      {
        _id: "66e0315a521dd39776ff6f3c",
        name: "all-in-one computers",
        namefr: "Ordinateurs Tout-en-Un"
      },
      {
        _id: "66e0315a521dd39776ff6f3d",
        name: "mini PCs",
        namefr: "Mini PC"
      },
      {
        _id: "66e0315a521dd39776ff6f3e",
        name: "monitors",
        namefr: "Moniteurs"
      },
      {
        _id: "66e0315a521dd39776ff6f3f",
        name: "routers",
        namefr: "Routeurs"
      },
      {
        _id: "66e0315a521dd39776ff6f40",
        name: "Wi-Fi Extenders",
        namefr: "Répéteurs Wi-Fi"
      },
      {
        _id: "66e0315a521dd39776ff6f41",
        name: "mice",
        namefr: "Souris"
      },
      {
        _id: "66e0315a521dd39776ff6f42",
        name: "webcams",
        namefr: "Webcams"
      },
      {
        _id: "66e0315a521dd39776ff6f43",
        name: "drawing tablets",
        namefr: "Tablettes Graphiques"
      },
      {
        _id: "66e0315a521dd39776ff6f44",
        name: "single board computers",
        namefr: "Ordinateurs à Carte Unique"
      }
    ]
  },
  {
    id: "66dadda9ddce8e57ddefe57d",
    namefr: "Imprimantes & Scanners",
    name: "Printers & Scanners",
    icon: <BsPrinter />,
    subcategories: [
      {
        _id: "66e03306521dd39776ff6f70",
        name: "printers",
        namefr: "Imprimantes"
      },
      {
        _id: "66e03306521dd39776ff6f71",
        name: "3D printers",
        namefr: "Imprimantes 3D"
      },
      {
        _id: "66e03306521dd39776ff6f72",
        name: "scanners",
        namefr: "Scanners"
      }
    ]
  },
  {
    id: "66dadda9ddce8e57ddefe57e",
    namefr: "Home & Garden",
    name: "Home & Garden",
    icon: <CiHome />,
    subcategories: [
      {
        _id: "66e032f9521dd39776ff6f5f",
        name: "pressure cookers",
        namefr: "Autocuiseurs"
      },
      {
        _id: "66e032f9521dd39776ff6f60",
        name: "vacuum cleaners",
        namefr: "Aspirateurs"
      },
      {
        _id: "66e032f9521dd39776ff6f61",
        name: "robot vacuum cleaners",
        namefr: "Aspirateurs Robots"
      },
      {
        _id: "66e032f9521dd39776ff6f62",
        name: "washing machines",
        namefr: "Machines à Laver"
      },
      {
        _id: "66e032f9521dd39776ff6f63",
        name: "combo washer dryers",
        namefr: "Lave-Linge Séchants"
      },
      {
        _id: "66e032f9521dd39776ff6f64",
        name: "tumble dryers",
        namefr: "Sèche-Linge"
      },
      {
        _id: "66e032f9521dd39776ff6f65",
        name: "coffee machines",
        namefr: "Machines à Café"
      },
      {
        _id: "66e032f9521dd39776ff6f66",
        name: "commercial coffee machines",
        namefr: "Machines à Café Commerciales"
      },
      {
        _id: "66e032f9521dd39776ff6f67",
        name: "refrigerators",
        namefr: "Réfrigérateurs"
      },
      {
        _id: "66e032f9521dd39776ff6f68",
        name: "dishwashers",
        namefr: "Lave-Vaisselle"
      },
      {
        _id: "66e032f9521dd39776ff6f69",
        name: "robotic lawn mowers",
        namefr: "Tondeuses Robots"
      },
      {
        _id: "66e032f9521dd39776ff6f6a",
        name: "lawn mowers",
        namefr: "Tondeuses"
      },
      {
        _id: "66e032f9521dd39776ff6f6b",
        name: "heat pumps",
        namefr: "Pompes à Chaleur"
      },
      {
        _id: "66e03306521dd39776ff6f6e",
        name: "portable power stations",
        namefr: "Stations d'Énergie Portables"
      }
    ]
  },
  {
    id: "66dae3f2ddce8e57ddefe5c0",
    namefr: "Divertissement",
    name: "Entertainment",
    icon: <CgGames />,
    subcategories: [
      {
        _id: "66e03306521dd39776ff6f74",
        name: "game consoles",
        namefr: "Consoles De Jeu"
      },
      {
        _id: "66e03306521dd39776ff6f75",
        name: "VR headsets",
        namefr: "Casques De Réalité Virtuelle"
      },
      {
        _id: "66e03306521dd39776ff6f76",
        name: "projectors",
        namefr: "Projecteurs"
      },
      {
        _id: "66e03306521dd39776ff6f77",
        name: "TVs",
        namefr: "Téléviseurs"
      },
      {
        _id: "66e03306521dd39776ff6f78",
        name: "drones",
        namefr: "Drones"
      },
      {
        _id: "66e03306521dd39776ff6f79",
        name: "streaming devices",
        namefr: "Dispositifs de diffusion"
      }
    ]
  },
];



const strings = new LocalizedStrings(localeFile);
const Recommendations = (props) => {
  const { subCategory, setsubCategory } = props;

  strings.setLanguage(props.languageCode);

  const [activeCategory, setActiveCategory] = useState(null);
  const [category, setCategory] = useState(null);
  const subCategorySelected = useSelector(state => state.isSubCategorySelected);
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);


  const handleCategoryClick = (categoryId) => {
    setActiveCategory((prev) => (prev === categoryId ? null : categoryId));
  };
  const handleClickOutside = (event) => {
    if (!event.target.closest('.categorie-item')) {
      setIsClosing(true);
      setTimeout(() => {
        setActiveCategory(null);
        setIsClosing(false);
      }, 1000); // 1000ms to match 1s animation duration
    }
  };

  const handlesubCategoryClick = (category, subcategoryName) => {
    setsubCategory(subcategoryName)
    setCategory(category)
    dispatch(setSubCategorySelected(subcategoryName));
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleBackButtonClick = () => {
    dispatch(SETSHOWBUTTONS(true))
    dispatch(setShowRecommend(false))
  };

  return (
    <div>
      {!subCategorySelected ?
        <div className='recommend--input-back-container'>
          <div className={`recommend-container show`}>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                <div
                  className={`categorie-item ${activeCategory === category.id ? 'active' : ''} ${isClosing ? 'closing' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCategoryClick(category.id);
                  }}
                >
                  <span className='icon-category'>

                  </span>
                  {category.icon}
                  <span>{strings.hreflangCode == "fr" ? category.namefr : category.name}  </span>
                  {activeCategory === category.id && (

                    <div className="subcategorie">
                      <div className="subcategorie-container">
                        <div className='subcategorielist'>

                          {category.subcategories.map((subcategory) => (
                            <div key={subcategory._id} className='subcategorie-item' onClick={(e) => {
                              e.stopPropagation();
                              handlesubCategoryClick(category.name, subcategory.name);
                            }}>
                              <span>{strings.hreflangCode == 'fr' ? subcategory.namefr : subcategory.name}</span>
                            </div>
                          ))}
                        </div>

                        <IoClose onClick={handleClickOutside} size={38} className='ismobile' />
                      </div>

                    </div>

                  )}
                </div>
              </React.Fragment>
            ))}
          </div>

          <BackButton show={true} onClick={handleBackButtonClick} search={false} />
        </div> : <Criteres category={category} subCategory={subCategory} />
      }
    </div>

  );
};



const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Recommendations);
