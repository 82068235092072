import React, { useEffect, useRef, useState } from "react";

import "./HighSchoolBranches.css";
import { FaRegCheckCircle } from "react-icons/fa";

const SelectType = ({ myList, sendOption ,index}) => {




  const [finalrating, setFinalRating] = useState(null);

  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  const handleOptionClick = (currentRating, optionValue) => {

    setRating(currentRating);
    setFinalRating(currentRating)
    sendOption(optionValue,index);}


  const optionsMarkup = myList.map((option, index) => {
    const currentRating = index + 1;

    return (


      <div key={index}
        className={`learning-option-button ${
          currentRating == rating ? "click" : ""
        }`}
      >
        <div className="check-icon-container">
          {" "}
          <FaRegCheckCircle className={`check-icon ${
          currentRating == rating ? "click" : ""
        }`}  />
        </div>

        <button
          key={option._id}
          onClick={() => handleOptionClick(currentRating, option.value)}
          onMouseEnter={() => setHover(currentRating)}
          onMouseLeave={() => setHover(null)}
          className={finalrating !=null ? 'container-main-disabled': `container-main`}
        >
          {option.value}
        </button>
      </div>    
    );
  });

  return (
    <>
      {" "}
      <div className="learning-options-container">{optionsMarkup}</div>{" "}
    </>
  );
};

export default SelectType;
