import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../shared/utility';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const getDefaultLanguage = () => {
  const lang = navigator.language || navigator.userLanguage;
  return lang.split('-')[0] == 'fr' ? 'fr' : 'en';
};

const initialState = {
  languageCode: localStorage.getItem('languageCode') || getDefaultLanguage(),
  user: null,
  error: null,
  selectedProductName: false,
  searchRecommend: false,
  criteresListSelected: [],
  recomendedResult: [],
  loading:false,
  isButtonsVisible: true,
  isRecommendationVisible: false,
  RecommendResultNumber:0,
  isSearchInputVisible: false,
  isSubCategorySelected: false
};


const changeLanguage = (state, action) => {
  localStorage.setItem('languageCode', action.langCode);
  return updateObject(state, {
    languageCode: action.langCode
  });
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,

  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LANG_ACTION:
        return changeLanguage(state, action);

      case actionTypes.SET_SELECTED_PRODUCT:
        return {
          ...state,
          selectedProductName: action.payload,
        };
      case actionTypes.RESET_SELECTED_PRODUCT:
        return initialState;

      case actionTypes.SET_SEARCH_RECOMMEND:
        return {
          ...state,
          searchRecommend: action.payload,
        };
      case actionTypes.RESET_SEARCH_RECOMMEND:
        return initialState;



      case actionTypes.SET_SELECTED_PRODUCT_POINT_LIST:
        return {
          ...state,
          listspoints: action.payload,
        };
      case actionTypes.RESET_SELECTED_PRODUCT_POINT_LIST:
        return initialState;

      case actionTypes.SET_SHOW_BUTTONS:
        return {
          ...state,
          isButtonsVisible: action.payload,
        };
      case actionTypes.SET_SHOW_RECOMMENDATION:
        return {
          ...state,
          isRecommendationVisible: action.payload,
        };
      case actionTypes.SET_SHOW_SEARCH_INPUT:
        return {
          ...state,
          isSearchInputVisible: action.payload,
        };
      case actionTypes.SET_SUBCATEGORY_SELECTED:
        return {
          ...state,
          isSubCategorySelected: action.payload,
        };
        case actionTypes.SET_SELECTED_CRITERE_LIST:
          return {
            ...state,
            criteresListSelected: action.payload,
          };
        case actionTypes.RESET_SELECTED_CRITERE_LIST:
          return initialState;
         case actionTypes.SET_RECOMMENDED_RESULT:
          return {
            ...state,
            recomendedResult: action.payload,
          };
        case actionTypes.RESET_RECOMMENDED_RESULT:
          return initialState;


          case actionTypes.SET_LOADING:
            return {
              ...state,
              loading: action.payload,
            };
          case actionTypes.RESET_LOADING:
            return initialState;



            case actionTypes.SET_RECOMMEND_RESULT_NUMBE:
              return {
                ...state,
                RecommendResultNumber: action.payload,
              };
            case actionTypes.RESET_RECOMMEND_RESULT_NUMBER:
              return initialState;
      default:
        return state;
    }
  }
);

export default persistedReducer;
