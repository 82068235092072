import React from 'react';
import './utility.css';
import localeFile from "../../content/content";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
const strings = new LocalizedStrings(localeFile);

 function Button(props) {
  const  {title, action, color, visible = true,desactive}=props
  if (!visible) return null;

  strings.setLanguage(props.languageCode);

  return (
    <div className="container-button">
      <button 
        className={desactive ? "button desactive " :" button  "}
        style={{ background: color }} 
        onClick={action} 
      title={desactive ? strings.button_disabled :null}
      >
        {title}
      </button>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Button);