import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Sidebar.css";
import { connect } from "react-redux";
import localeFile from "../../content/TermsConditions.js";
import LocalizedStrings from "react-localization";
import PropTypes from "prop-types";
const strings = new LocalizedStrings(localeFile);
const SidebarItem = ({ id, title, isSelected, handleClick }) => (
    <p className={`item ${isSelected ? "selected" : ""}`} onClick={(event) => handleClick(event, id)}>
        <Link to={`#${id}`} smooth>
            {title}
        </Link>
    </p>
);
const Sidebar = ({ selectedId, selectedDetail, handleClick, languageCode }) => {
    strings.setLanguage(languageCode);
    const detailItems = [
        { id: "REGISTERED", title: strings.titles.registration },
        { id: "COMPETITORS", title: strings.titles.competitors },
        { id: "CONTRIBUTIONS", title: strings.titles.contributions },
        { id: "THIRD_PARTY", title: strings.titles.thirdparty },
    ];
    return (

        
        <div className="sidebar">
            <SidebarItem id="Maydena" title="1. Maydena" isSelected={selectedId === "Maydena"} handleClick={handleClick} />
            <SidebarItem id="ACCEPTANCE" title={`2. ${strings.titles.acceptance}`} isSelected={selectedId === "ACCEPTANCE"} handleClick={handleClick} />
            <ul className="details">
                {detailItems.map(({ id, title }) => (
                    <li key={id} onClick={(event) => handleClick(event, id)}>
                        <p className={selectedDetail === id ? "bold" : ""}>
                            {`2.${detailItems.findIndex(item => item.id === id) + 1} ${title}`}
                        </p>
                    </li>
                ))}
            </ul>
            <SidebarItem id="RIGHTS" title={`3. ${strings.titles.rights}`} isSelected={selectedId === "RIGHTS"} handleClick={handleClick} />
            <SidebarItem id="INDEMNITY" title={`4. ${strings.titles.indemnity}`} isSelected={selectedId === "INDEMNITY"} handleClick={handleClick} />
        </div>

    );
};
const mapStateToProps = (state) => ({
    languageCode: state.languageCode,
});
Sidebar.propTypes = {
    selectedId: PropTypes.string.isRequired,
    selectedDetail: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    languageCode: PropTypes.string.isRequired,
};
export default connect(mapStateToProps)(Sidebar);