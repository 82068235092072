import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from './Pagination';
import Button from '../../../ui/Button';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);
const PointsContainer = (props) => {
    strings.setLanguage(props.languageCode);
    const languageCode = props.languageCode;

    const listspoints = useSelector(state => state.listspoints);
    const [History, setHistory] = useState(true);

    const pointsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);


    const totalPages = Math.ceil(Math.max(listspoints && listspoints.positivePoints.length,listspoints && listspoints.negativePoints.length) / pointsPerPage);

    const startIndex = (currentPage - 1) * pointsPerPage;
    const endIndex = startIndex + pointsPerPage;

    const currentPositivePoints = listspoints && listspoints.positivePoints.slice(startIndex, endIndex);
    const currentNegativePoints = listspoints && listspoints.negativePoints.slice(startIndex, endIndex);

    return (
        <div className='results'>
        <div className='points-container'>
            <div className='points-list'>
                <div className='points-header positive-points-header'>
                    <h6> {strings.searchEngine.positive_points} </h6>
                </div>
                {currentPositivePoints && currentPositivePoints.map((point, index) => (
                    <div key={point[0]._id} className='point-item'>
                        <div className='point-index positive'>{startIndex + index + 1}</div>
                        <div className='point-text'>{languageCode === "fr" ? point[0].textFr : point[0].text}</div>
                    </div>
                ))}
            </div>

            <div className='points-list '>
                <div className='points-header negative-points-header'>
                    <h6>{strings.searchEngine.negative_points} </h6>
                </div>
                {currentNegativePoints && currentNegativePoints.map((point, index) => (
                    <div key={point[0]._id} className='point-item'>
                        <div className='point-index negative'>{startIndex + index + 1}</div>
                        <div className='point-text'>{languageCode === "fr" ? point[0].textFr : point[0].text}</div>
                    </div>
                ))}
            </div>


        </div>
     { History ?
                <Pagination   currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
           :
            <div className='login-message'>
                    <p>{strings.searchEngine.loginMessage} </p>
                    <Button title={strings.header.login} ></Button>
            </div>}
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
      languageCode: state.languageCode,
    };
  };

export default connect(mapStateToProps)(PointsContainer);