import React, { useEffect, useState, useRef } from 'react';
import rechercheImage from '../../../../assets/images/HomePage/Moteur de recherche - Resultat 1.png';
import rechercheImageeng from '../../../../assets/images/HomePage/eng.png';
import Stroke from '../../../../assets/images/HomePage/Vector (1).webp';
import Stroke2 from '../../../../assets/images/HomePage/ticker-star-svgrepo-com 1.webp';
import Stroke1 from '../../../../assets/images/HomePage/Vector@2x.webp';
import './OurService.css';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import Button from '../../../ui/Button.js';
import CountUp from 'react-countup';
import axios from 'axios'; // To make API calls
import { apiSearch } from '../../../../api/api.js';

const strings = new LocalizedStrings(localeFile);

function OurService(props) {
    const [isVisible, setIsVisible] = useState(false);
    const [liveUserCount, setLiveUserCount] = useState(0);
    const [productCount, setProductCount] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const counterRef = useRef(null);

    strings.setLanguage(props.languageCode);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
    
                    // Optional: Disconnect observer after triggering once
                    observer.disconnect();
                }
            },
            { threshold: 0.7 } // Trigger when 70% of the counters are visible
        );
    
        if (counterRef.current) {
            observer.observe(counterRef.current);
        }
    
        return () => observer.disconnect();
    }, []);
    
    useEffect(() => {
        if (isVisible) {
            const interval = setInterval(() => {
                // Increment counts slightly for periodic updates
                setLiveUserCount((prev) => Math.min(prev + Math.floor(Math.random() * 5), liveUserCount));
                setProductCount((prev) => Math.min(prev + Math.floor(Math.random() * 3), productCount));
            }, 3000); // Update every 3 seconds
    
            return () => clearInterval(interval);
        }
    }, [isVisible, liveUserCount, productCount]);
    

    const fetchUserCount = async () => {
        try {
            const response = await apiSearch.getUserCount(); // Your API endpoint to get the user count
            setLiveUserCount(response.data.count);
        } catch (error) {
            console.error('Error fetching user count:', error);
        }
    };

    const fetchProductCount = async () => {
        try {
            const response = await apiSearch.getProductCount();
            setProductCount(response.data.data.count);
        } catch (error) {
            console.error('Error fetching product count:', error);
        }
    };

    useEffect(() => {
        fetchUserCount(); // Fetch user count initially
        fetchProductCount();
    }, []);

    useEffect(() => {
        setCommentsCount(productCount * 227);
    }, [productCount]);

    return (
        <section className='Ourservice_section'>
            <div className='Ourservice_section_container'>
                <div>
                    <div className='Ourservice__heading'>
                        <h2>{strings.ourService.heading}</h2>
                    </div>
                    <div className='Ourservice__description'>
                        <p>{strings.ourService.description}</p>
                        {/* <Button title={strings.LearnMore} color="#6B92B5" /> */}
                    </div>
                </div>
                <div className='ourservice_image_container'>
                    {props.languageCode === 'fr' ? (
                        <img src={rechercheImage} alt="Team" />
                    ) : (
                        <img src={rechercheImageeng} alt="Team" />
                    )}
                </div>
            </div>

            <div className='services_items'>
                <div className='service_item'>
                    <div className='service_image_container'>
                        <img alt={strings.ourService.serviceItems[0].name} src={Stroke} />
                    </div>
                    <div className='service_content'>
                        <h6>{strings.ourService.serviceItems[0].name}</h6>
                        <p>{strings.ourService.serviceItems[0].description}</p>
                    </div>
                </div>
                <div className='service_item'>
                    <div className='service_image_container'>
                        <img alt={strings.ourService.serviceItems[1].name} src={Stroke1} />
                    </div>
                    <div className='service_content'>
                        <h6>{strings.ourService.serviceItems[1].name}</h6>
                        <p>{strings.ourService.serviceItems[1].description}</p>
                    </div>
                </div>
                <div className='service_item'>
                    <div className='service_image_container'>
                        <img alt={strings.ourService.serviceItems[2].name} src={Stroke2} />
                    </div>
                    <div className='service_content'>
                        <h6>{strings.ourService.serviceItems[2].name}</h6>
                        <p>{strings.ourService.serviceItems[2].description}</p>
                    </div>
                </div>
            </div>

            <div className='counter_container-main' ref={counterRef}>
    <div className='counter_container'>
        <div className={`counter_content ${isVisible ? "visible" : "hidden"}`}>
            {isVisible && (
                <>
                    <div className='counter'>
                        <div className='counter_number'>
                            <CountUp
                                start={0}
                                end={commentsCount}
                                duration={5}
                                separator=","
                            />
                        </div>
                        <div className='counter_description'>
                            <span title={strings.ourService.counter.tooltipDailySearchQueries}>
                                {strings.ourService.counter.dailySearchQueries}
                            </span>
                        </div>
                    </div>

                    <div className='line-counter'>.</div>

                    <div className='counter'>
                        <div className='counter_number'>
                            <CountUp
                                start={0}
                                end={liveUserCount}
                                duration={5}
                                separator=","
                            />
                        </div>
                        <div className='counter_description'>
                            <span title={strings.ourService.counter.tooltipActiveUsers}>
                                {strings.ourService.counter.activeUsers}
                            </span>
                        </div>
                    </div>

                    <div className='line-counter'>.</div>

                    <div className='counter'>
                        <div className='counter_number'>
                            <CountUp
                                start={0}
                                end={productCount}
                                duration={5}
                                separator=","
                            />
                        </div>
                        <div className='counter_description'>
                            <span title={strings.ourService.counter.tooltipDollarsSaved}>
                                {strings.ourService.counter.dollarsSaved}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    </div>
</div>

        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(OurService);
