import React, { useEffect } from 'react'
import Communities from '../../components/sections/aboutUs/Communities/Communities'
import CoreValues from '../../components/sections/aboutUs/CoreValues/CoreValues'
import OurMission from '../../components/sections/homePage/OurMissionVision/OurMission'
import AboutUs from '../../components/sections/homePage/AboutUs/AboutUs'
import OurService from '../../components/sections/aboutUs/OurServicceTow/OurService'
import { useDispatch,useSelector } from 'react-redux';
import { resetSearchRecommend } from '../../store/actions/actionCreators'
import localeFile from "../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

const strings = new LocalizedStrings(localeFile);
 function AboutUsPage(props) {
  strings.setLanguage(props.languageCode);
  const languageCode = props.languageCode;
  const isRecommendationVisible = useSelector(state => state.isRecommendationVisible);
  const dispatch = useDispatch();

  useEffect(() => {


      dispatch(resetSearchRecommend());

       document.body.classList.remove('no-scroll');

   
 }, []);
  return (
    <>
    {languageCode=='fr'? <Helmet>
    <title>À Propos de Mayview - Votre Guide d'Achat en Ligne    </title>
    <meta name="description" content="Découvrez la mission de Mayview : simplifier vos décisions d'achat avec des outils de recommandation, des comparatifs détaillés et des avis transparents pour mieux vous guider.
" />
    <meta name="keywords" content="Avis produits e-commerce, Comparatif de produits, Agrégateur d'avis utilisateurs, Conseils d'achat high-tech, Recherches collectives d’avis, Guide d'achat, outils de recommandation d'achat" />
  </Helmet>:
 <Helmet>
 <title>About Mayview - Your Trusted Online Shopping Guide
 </title>
 <meta name="description" content="Learn about Mayview's mission: helping you make smarter purchases with transparent reviews, detailed product comparisons, and recommendation tools.
" />
 <meta name="keywords" content=" Avis produits e-commerce , Comparatif de produits , Agrégateur d'avis utilisateurs, Conseils d'achat high-tech, Recherches collectives d’avis, Guide d'achat, outils de recommandation d'achat" />
</Helmet>
}
   
    <AboutUs visible={false}/>
    <CoreValues/>
    <OurMission/>
    <Communities/>
    <OurService/>
    </>
  )
}const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};
export default connect(mapStateToProps)(AboutUsPage);