const UserPolicy = {
    eng: {
            maydena: `Maydena, a business consulting and services company, is the creator and operator of Mayview. With expertise in digital transformation, data analysis, and customer engagement, we aim to drive change and innovation through our platform.
In other words , the factory is Maydena and the cake is Mayview .`,
            personaldata: {
                    main: `When you write a review on our Website, we'll create an account for you and collect:`,
                    point1: `Your Name (public unless anonymous)`,
                    point2: `Your Email`,
                    point3: `Social Media Profile (optional)`,
                    main2: `Name and email are required to post a review. Adding social media details can help verify your reviews, and we might use publicly available data from your profile. We share this information with the companies being reviewed.

                    Maydena is an open platform, meaning your name will appear with your review unless you choose anonymity. Even if anonymous, the reviewed company might see contact details if they subscribe to our services.
As the data processor, we use your info to:`,
                    point4: `Display your reviews and manage your account`,
                    point5: `Identify you as a reviewer`,
                    point6: `Verify your reviews`,
                    point7: `Respond to your inquiries`,
                    point8: `Comply with legal obligations`,
                    point9: `Enforce terms and conditions`,

            },
            legalbasis: `<strong>We need to process your personal information in order to:</strong>
Comply with our legal obligations as stated in Article 6.1.c of the GDPR and operate an online review platform in compliance with, for example, the Competition and Markets Authority's guidance on online reviews and endorsements.

If you would like more information about our legal basis for processing your personal information, please contact our Data Protection Officer (DPO) by emailing gdpr@Maydena.com .`,
            dataretention: {
                    main: `We retain your review data and other personal information for as long as your review remains publicly published on our platform. 
If you decide to remove your review, we will delete all associated personal data, including your name, email address, and the review itself. 
For reviews initiated by a Data Controller through our system, we automatically delete your information after 360 days, unless you request earlier deletion by contacting gdpr@Maydena.com .`,
                    accessing: `If you've written a review on our Website, you can log in to your Account using your email address to view the information we have about you, including your review. If you don't have an Account, you can email support@Maydena to request details about your personal data. We will inform you about the personal information we hold, how it was collected, the purposes for processing it, and with whom it is shared. Review authors can download their personal data, including reviews, by logging in to their account.`,
                    review: `When you write a review on our Website, we collect the information provided and associate it with the reviewed company or product. We may contact you via email for verification purposes. If your review was in response to a company invitation through our services, that company will have access to the review and the reviewer's personal data. Verified reviews with social media information will include publicly available social media statistics.`,
                    cookies: {
                            main: `Our Website uses cookies, which are digital data stored on your computer. Cookies do not identify you personally but recognize your computer, serving various purposes.`,
                            point1: `Third-Party Cookies :
We may share cookie data and de-identified information with trusted third parties for advertising relevance and personalization. Your email address is never shared. Third-party cookies may include:
<strong>*</strong> Google Analytics and AdSense cookies, including DoubleClick, for login and targeted ads.
<strong>*</strong> Intercom cookies for live chat support.
<strong>*</strong> Cloudflare cookies for client identification and security.
`,
                            point2: `Deleting Cookies :
You can delete cookies from your device through your browser's Privacy or History settings. To opt-out of third-party cookies, visit http://www.aboutads.info/choices or http://www.youronlinechoices.com/uk/.
`,
                    },
            },
            adresses: `When visiting the Website, the IP address and the browser settings of your computer are registered.

<strong>What is an IP Address?</strong> 
The IP address is the numerical address of the computer used when visiting the Website. Browser settings are e.g. the type of browser you use, browser language, timezone etc.

<strong>How?</strong>
The IP address and browser settings are registered to enable us to trace the used computer in case of misuse or unlawful actions in connection with the visit on or use of the Website. Further, the IP address is used to derive your approximate location and verify your review as genuine. IP data is removed after 30 days unless a review is written in which case the IP data is retained until the review is deleted or a removal request has been actioned.`,
            disclosure: {
                    main: `As the Data Controller, we provide companies under review with access to contact information provided by reviewers, all within a secure digital environment. Maydena also allows unrelated services to display reviews, but we do not share any personal contact information with these entities. The types of third parties that may receive this information include:`,
                    point1: `Search engines, such as Google and Bing`,
                    point2: `Companies or services reviewed on our website or that have a relationship with Maydena`,
                    main2: `<strong>Other Disclosures :</strong>
Maydena may be legally required to disclose information in response to a court order from a competent court or public authority, in accordance with existing laws. As the Data Controller, we ensure that companies being reviewed can securely access contact information provided by reviewers.`
            },
            dataprocessor: `Maydena acts as the Data Processor before a review is submitted by a reviewer. We assist users using our Website service in collecting genuine reviews.

If a user/company using our solution transfers data for review collection, they remain the data processor until the review is left and the reviewer agrees to our terms and conditions. This data may include your name, email address, purchase information, and social media information.

Once a reviewer submits a review, Maydena becomes the Data Controller. Users who signed up to our services and who have been reviewed gain access to this data as Data Processors.

We are not accountable for the privacy practices of third-party-operated or -owned companies. The information practices of such third-party websites or applications are governed by the operators' privacy policies.
All data processed as a Data Processor is deleted within 360 days. 

<strong>Who To Contact ?</strong>
Direct any queries regarding your personal data to Maydena at gdpr@Maydena.com 
Maydena is the "Data Controller," as outlined in this privacy policy.`,
            security: `‍Maydena will use commercially reasonable efforts to see that the recorded data, including the personal information under its control is handled using appropriate security measures. 

            Maydena regularly audits its system for possible vulnerabilities and attacks. 

            We do not store credit card numbers or related identifying information on any website server.`,
            correction: {
                    access: `Upon written request, we will inform you about the purpose of processing your data, the recipients of your information, and its source. Requests for this information can only be made if more than six months have passed since your last request, unless you demonstrate a special need for the information sooner.`,
                    deletion: `If you find that the information processed by Maydena as the Data Controller is incorrect or misleading, we will correct it upon your request.
You can update or delete any content and information on our website through your user profile at any time. When your profile is deleted, all data associated with it, including your master data and reviews, is permanently removed. If you delete a review, it is permanently erased.
Maydena reserves the right to block or delete your profile if we determine that it contains content that is discriminatory, racist, sexually oriented, unethical, threatening, offensive, harassing, or otherwise violates the law, third-party rights, or the website's purpose. If your profile is blocked, we will inform you via email, including the reason for the action, using the address provided in your profile.
`,
            },
            bank: `‍‍‍‍‍‍‍If you have written a review, we do not need any private banking details from you. The only contactable details we ask for are a name, email address & social media name to confirm that you are a real user. 
            
            We will never gain access to your credit card number. If you do choose to purchase something by clicking through to one of our retailers, we will not see any of your private information including banking details or credit card numbers.`,
            age: `‍We require our users to be 18 or older and anyone using the site must adhere to this rule. We never knowingly allow children under 18 to post personal information on our site. 
            
            If you know about someone using the website under the age of 18 please contact us via gdpr@Maydena.com .`,
            right: `Upon request to Maydena via the contact information stated on this page, Maydena shall inform you of the purpose of the processing; <strong>who receives the information and from where the information originates.</strong>`,
            links: `Our Website contains links to other websites. Our inclusion of such links does not imply that we endorse those websites. We do not control the content of those third party websites, and assume no responsibility for the third party or their policies or practices.`,
            changes: `Maydena may at any time and without notice make changes to its privacy policy with future effect. Maydena shall inform the users of the Website of such changes when the users log on to the Website. 
            
            You agree that the continued use of the Website after any posted modified version of the Privacy Policy is your acceptance of the modified Privacy Policy.`,
            enquiries: `‍If you have any questions or comments, or if you want to update, delete, or change any Personal Information you've submitted on the Website, please use our contact form to get in touch.

            In addition, you are welcome to contact our Data Protection Officer (DPO). 
            
            If you have any questions about the data processing activities performed by us, you are welcome to contact our DPO by email at:
privacy@Maydena.fr
support@maydena.fr
`,
            titles: {
                    Maydena: `1. Maydena`,
                    PERSONAL_DATA: `2. Personal Data`,
                    LEGAL_BASE: `3. On what legal basis do we process your personal data?`,
                    DATA_RETENTION: `4. Data Retention`,
                    accessing: `Accessing Your Personal Data`,
                    review: `Review and Rating Information`,
                    IP_SETTINGS: `5. IP Addresses and Browser Settings`,
                    DISCLOSURE: `6. Disclosure to Companies and Other Services`,
                    DATA_CONTROLLER: `7. Data Controller and Processor`,
                    SECURITY_MEASURES: `8. Security Measures`,
                    CORRECTION_DELETION: `9. Correction and Deletion`,
                    infoaccess: `9.1 Access to Information`,
                    correction: `9.2 Correction and Deletion`,
                    BANK_INFO: `10. Banking and Credit Card Information`,
                    AGE_CONDITIONS: `11. Age Conditions - 18 years or older`,
                    RIGHT_TO_FORGET: `12. Right to Forget`,
                    EXTERNAL_LINKS: `13. Links to External Sites`,
                    PRIVACY_POLICY_CHANGES: `14. Changes in the Privacy Policy`,
                    ENQUIRIES: `15. Enquiries Regarding this Privacy Policy`
            },
            userPolicy:"User privacy policy",
            lastmodif: "Last modification :"


    },
    fr: {

            maydena: `Maydena, une entreprise de conseil et de services, est le créateur et opérateur de Mayview. Avec une expertise en transformation numérique, analyse de données et engagement client, nous visons à encourager le changement et l'innovation à travers notre plateforme.
En d'autres termes, la boulangerie est Maydena et le gâteau est Mayview.`,
            personaldata: {
                    main: `Lorsque vous laissez un avis sur notre site, nous créons un compte pour vous et collectons :`,
                    point1: `Votre nom (public sauf anonymat)`,
                    point2: `Votre adresse e-mail`,
                    point3: `Profil sur les réseaux sociaux (facultatif)`,
                    main2: `Le nom et l’e-mail sont requis pour poster un avis. Ajouter des détails de réseaux sociaux peut aider à vérifier vos avis, et nous pouvons utiliser des données publiquement disponibles de votre profil. Nous partageons ces informations avec les entreprises faisant l’objet de l’avis.

Maydena est une plateforme ouverte, ce qui signifie que votre nom apparaîtra avec votre avis, à moins que vous ne choisissiez l’anonymat. Même en cas d’anonymat, l’entreprise concernée peut voir vos coordonnées si elle est abonnée à nos services.

En tant que responsable du traitement, nous utilisons vos informations pour :`,
                    point4: `Afficher vos avis et gérer votre compte`,
                    point5: `Vous identifier en tant qu’auteur d’avis`,
                    point6: `Vérifier vos avis`,
                    point7: `Répondre à vos questions`,
                    point8: `Respecter les obligations légales`,
                    point9: `Appliquer les termes et conditions`,

            },
            legalbasis: `<strong>Nous devons traiter vos informations personnelles pour :</strong>
Respecter nos obligations légales comme indiqué à l’article 6.1.c du RGPD et exploiter une plateforme d'avis en ligne conforme, par exemple, aux directives de la Competition and Markets Authority sur les avis et recommandations en ligne.

Si vous souhaitez plus d'informations sur la base légale du traitement de vos informations personnelles, veuillez contacter notre Délégué à la Protection des Données (DPO) par e-mail à gdpr@Maydena.com .`,
            dataretention: {
                    main: `Nous conservons vos données d’avis et autres informations personnelles tant que votre avis reste publié publiquement sur notre plateforme.
Si vous décidez de supprimer votre avis, nous effacerons toutes les données personnelles associées, y compris votre nom, votre adresse e-mail et l'avis lui-même.

Pour les avis initiés par un responsable du traitement via notre système, nous supprimons automatiquement vos informations après 360 jours, sauf si vous demandez une suppression anticipée en contactant gdpr@Maydena.com .`,
                    accessing: `Si vous avez écrit un avis sur notre site, vous pouvez vous connecter à votre compte en utilisant votre adresse e-mail pour consulter les informations que nous avons sur vous, y compris votre avis. 

Si vous n'avez pas de compte, vous pouvez envoyer un e-mail à support@Maydena pour demander des détails sur vos données personnelles. 

Nous vous informerons des informations personnelles que nous détenons, de la manière dont elles ont été collectées, des finalités de leur traitement et des tiers avec qui elles ont été partagées. Les auteurs d'avis peuvent télécharger leurs données personnelles, y compris les avis, en se connectant à leur compte.`,
                    review: `Lorsque vous écrivez un avis sur notre site, nous collectons les informations fournies et les associons à l'entreprise ou au produit concerné. Nous pouvons vous contacter par e-mail à des fins de vérification. 
Si votre avis répond à une invitation d'une entreprise via nos services, cette entreprise aura accès à l'avis et aux données personnelles du rédacteur. Les avis vérifiés contenant des informations de réseaux sociaux incluront des statistiques disponibles publiquement sur ces réseaux.`,
                    cookies: {
                            main: `Notre site utilise des cookies, qui sont des données numériques stockées sur votre ordinateur. Les cookies ne vous identifient pas personnellement mais reconnaissent votre appareil, servant divers objectifs.`,
                            point1: `Cookies tiers :
Nous pouvons partager des données de cookies et des informations dépersonnalisées avec des tiers de confiance pour la pertinence publicitaire et la personnalisation. Votre adresse e-mail n’est jamais partagée. Les cookies tiers peuvent inclure :
<strong>*</strong></strong> Cookies Google Analytics et AdSense, y compris DoubleClick, pour les connexions et les publicités ciblées.
<strong>*</strong> Cookies Intercom pour le support en chat en direct.
<strong>*</strong> Cookies Cloudflare pour l'identification du client et la sécurité.`,
                            point2: `Suppression des cookies :
Vous pouvez supprimer les cookies de votre appareil via les paramètres de confidentialité ou d'historique de votre navigateur. Pour vous désinscrire des cookies tiers, visitez http://www.aboutads.info/choices ou http://www.youronlinechoices.com/uk/.`,
                    },
            },
            adresses: `Lors de la visite du site, l'adresse IP et les paramètres du navigateur de votre ordinateur sont enregistrés.

<strong>Qu’est-ce qu’une adresse IP ?</strong>
L'adresse IP est l'adresse numérique de l'ordinateur utilisé lors de la visite du site. Les paramètres du navigateur incluent, par exemple, le type de navigateur utilisé, la langue du navigateur, le fuseau horaire, etc.

<strong>Comment ?</strong>
L'adresse IP et les paramètres du navigateur sont enregistrés pour nous permettre de tracer l'ordinateur utilisé en cas de mauvaise utilisation ou d'actions illégales liées à la visite ou à l'utilisation du site. 
De plus, l'adresse IP est utilisée pour déduire votre emplacement approximatif et vérifier que votre avis est authentique. 
Les données IP sont supprimées après 30 jours, sauf si un avis est rédigé, auquel cas les données IP sont conservées jusqu'à la suppression de l'avis ou la demande de suppression.`,
            disclosure: {
                    main: `En tant que responsable du traitement, nous fournissons aux entreprises faisant l'objet d’un avis l'accès aux informations de contact fournies par les auteurs d'avis, le tout dans un environnement numérique sécurisé. 
Maydena permet également à des services non affiliés d'afficher des avis, mais nous ne partageons aucune information de contact personnelle avec ces entités. Les types de tiers susceptibles de recevoir ces informations incluent :`,
                    point1: `Moteurs de recherche, tels que Google et Bing`,
                    point2: `Entreprises ou services évalués sur notre site ou ayant une relation avec Maydena`,
                    main2: `<strong>Autres divulgations:</strong>
                    Maydena peut être légalement tenue de divulguer des informations en réponse à une ordonnance d'un tribunal compétent ou d'une autorité publique, conformément aux lois en vigueur. 
En tant que responsable du traitement, nous veillons à ce que les entreprises évaluées puissent accéder en toute sécurité aux informations de contact fournies par les auteurs d'avis.`
            },
            dataprocessor: `Maydena agit en tant que sous-traitant avant qu’un avis ne soit soumis par un rédacteur.Nous assistons les utilisateurs de notre service à recueillir des avis authentiques.

            Si un utilisateur/entreprise utilisant notre solution transfère des données pour la collecte d’avis, ils restent sous-traitants jusqu'à ce que l’avis soit laissé et que le rédacteur accepte nos conditions générales. 
Ces données peuvent inclure votre nom, adresse e-mail, informations d'achat et informations de réseaux sociaux.

Une fois qu'un rédacteur soumet un avis, Maydena devient le responsable du traitement. Les utilisateurs inscrits à nos services et évalués ont accès à ces données en tant que sous-traitants.

Nous ne sommes pas responsables des pratiques de confidentialité des entreprises tierces opérées ou possédées. Les pratiques d’information de ces sites ou applications tiers sont régies par les politiques de confidentialité des opérateurs.
Toutes les données traitées en tant que sous-traitant sont supprimées sous 360 jours.

<strong>À qui s'adresser ?</strong>
Adressez toutes les questions concernant vos données personnelles à Maydena à gdpr@Maydena.com.
Maydena est le "responsable du traitement", comme indiqué dans cette politique de confidentialité.
`,
            security: `Maydena s'efforcera, dans la mesure du possible, de s'assurer que les données enregistrées, y compris les informations personnelles sous son contrôle, sont traitées en utilisant des mesures de sécurité appropriées.

            Maydena audite régulièrement son système pour détecter d'éventuelles vulnérabilités et attaques.

            Nous ne stockons pas les numéros de cartes de crédit ou les informations d'identification connexes sur aucun serveur du site.`,
            correction: {
                    access: `Sur demande écrite, nous vous informerons des finalités du traitement de vos données, des destinataires de vos informations et de leur source. Les demandes pour cette information ne peuvent être faites que si plus de six mois se sont écoulés depuis votre dernière demande, sauf si vous démontrez un besoin particulier d'obtenir les informations plus tôt.`,
                    deletion: `Si vous constatez que les informations traitées par Maydena en tant que Responsable du traitement sont incorrectes ou trompeuses, nous les corrigerons à votre demande.

Vous pouvez mettre à jour ou supprimer tout contenu et information sur notre site Web via votre profil utilisateur à tout moment. Lorsque votre profil est supprimé, toutes les données associées, y compris vos données principales et vos avis, sont définitivement supprimées. Si vous supprimez un avis, celui-ci est définitivement effacé.

Maydena se réserve le droit de bloquer ou supprimer votre profil si nous déterminons qu'il contient du contenu discriminatoire, raciste, à caractère sexuel, contraire à l'éthique, menaçant, offensant, harcelant, ou violant la loi, les droits de tiers, ou l'objectif du site. Si votre profil est bloqué, nous vous informerons par e-mail, en précisant la raison de cette action, en utilisant l'adresse fournie dans votre profil.`,
            },
            bank: `Si vous avez rédigé un avis, nous n'avons pas besoin de vos coordonnées bancaires privées. Les seules informations de contact que nous demandons sont un nom, une adresse e-mail, et un nom de réseau social pour confirmer que vous êtes un utilisateur réel.
            
            Nous n'aurons jamais accès à votre numéro de carte de crédit. Si vous choisissez d'acheter quelque chose en cliquant sur un lien vers l'un de nos détaillants, nous ne verrons aucune de vos informations privées, y compris les coordonnées bancaires ou numéros de carte de crédit.`,
            age: `Nous exigeons que nos utilisateurs aient 18 ans ou plus et toute personne utilisant le site doit respecter cette règle. Nous n'autorisons jamais sciemment des enfants de moins de 18 ans à publier des informations personnelles sur notre site. 
            
            Si vous savez qu'une personne de moins de 18 ans utilise le site, veuillez nous contacter via gdpr@Maydena.com .`,
            right: `Sur demande adressée à Maydena via les coordonnées indiquées sur cette page, Maydena vous informera de l'objectif du traitement ; <strong>qui reçoit les informations et d'où proviennent les informations.</strong>`,
            links: `Notre site Web contient des liens vers d'autres sites. L'inclusion de tels liens n'implique pas que nous approuvons ces sites. Nous ne contrôlons pas le contenu des sites tiers et n'assumons aucune responsabilité pour les politiques ou pratiques de ces tiers.`,
            changes: `Maydena peut à tout moment et sans préavis apporter des modifications à sa politique de confidentialité avec effet pour l'avenir. Maydena informera les utilisateurs du site de ces changements lorsqu'ils se connecteront au site. 

            Vous acceptez que l'utilisation continue du site après toute version modifiée de la Politique de Confidentialité publiée constitue votre acceptation de cette politique modifiée.`,
            enquiries: `Si vous avez des questions ou des commentaires, ou si vous souhaitez mettre à jour, supprimer ou modifier toute information personnelle que vous avez soumise sur le site, veuillez utiliser notre formulaire de contact pour nous joindre.

            En outre, vous pouvez contacter notre Responsable de la Protection des Données (DPO). 

            Si vous avez des questions concernant les activités de traitement des données que nous effectuons, vous pouvez contacter notre DPO par e-mail à :
privacy@Maydena.fr
support@maydena.fr`,
            titles: {
                    Maydena: `1. Maydena`,
                    PERSONAL_DATA: `2. Données personnelles`,
                    LEGAL_BASE: `3. Sur quelle base légale traitons-nous vos données personnelles ?`,
                    DATA_RETENTION: `4. Conservation des données`,
                    accessing: `Accès à vos données personnelles`,
                    review: `Informations sur les avis et notations`,
                    IP_SETTINGS: `5. Adresses IP et paramètres du navigateur`,
                    DISCLOSURE: `6. Divulgation aux entreprises et autres services`,
                    DATA_CONTROLLER: `7. Responsable du traitement et sous-traitant`,
                    SECURITY_MEASURES: `8. Mesures de sécurité`,
                    CORRECTION_DELETION: `9. Correction et suppression`,
                    infoaccess: `9.1 Accès aux informations`,
                    correction: `9.2 Correction et Suppression`,
                    BANK_INFO: `10. Informations Bancaires et de Carte de Crédit`,
                    AGE_CONDITIONS: `11. Conditions d'Âge - 18 ans ou plus`,
                    RIGHT_TO_FORGET: `12. Droit à l'Oubli`,
                    EXTERNAL_LINKS: `13. Liens vers des Sites Externes`,
                    PRIVACY_POLICY_CHANGES: `14. Changes in the Privacy Policy`,
                    ENQUIRIES: `15. Enquiries Regarding this Privacy Policy`
            },
            userPolicy:"Politique de confidentialité des utilisateurs",
            lastmodif: "Dernière modification :"


    },



};

export default UserPolicy;