import React from 'react';
import './CoreValues.css';
import view from '../../../../assets/images/AboutUs/view.png';
import personstanding from '../../../../assets/images/AboutUs/person-standing.png';
import Vector  from '../../../../assets/images/AboutUs/Vector (2).png';
import localeFile from "../../../../content/content.js";
import LocalizedStrings from "react-localization";
import { connect, useDispatch, useSelector } from "react-redux";

const strings = new LocalizedStrings(localeFile);
function CoreValues(props) {
  strings.setLanguage(props.languageCode);
  return (
    <section className="core-values-section">
      <div className="core-values-container">
        <div className="core-value-item-container">
          <div className="core-value-item">
           <img src={view} alt='Transparence' className="core-value-icon" />
          </div>
          <span className="core-value-title">{strings.CoreValues.Transparence} </span>
        </div>
        <div className="core-value-item-container">
        <div className="core-value-item">
          <img src={personstanding} alt='Accessibilité' className="core-value-icon" />
        </div>
        <span className="core-value-title">{strings.CoreValues.Accessibilite} </span>
        </div>
        <div className="core-value-item-container">
        <div className="core-value-item">
          <img src={Vector} alt='Pouvoir du collectif' className="core-value-icon" />
        </div>
        <span className="core-value-title">{strings.CoreValues.Pouvoir_du_collectif} </span>

        </div>
      </div>
    </section>
  );
}


const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(CoreValues);