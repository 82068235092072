import axios from 'axios';

const baseURL = "https://mayview.fr/";

const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
       
    },
});


api.interceptors.response.use(
    response => response,
    error => {
        
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);


const apiAuth = {
    login: (data) => api.post('/auth/login', data),
    logout: () => api.post('/auth/logout'),
};

const apiBlog = {
    getBlogs: () => api.get('/blog/public/getBlogs'),
    getBlog: (id) => api.get(`/blog/public/getBlogDetails/${id}`),
};

const apiChat = {
    getAllMessage: () => api.get(`/chat/private/getAll`),


    sendMessage: ({userId, chat}) => api.post(`/chat/private/addUserReponses`,  {
    userId:userId, chat 
      }),
    getMessages: (userId) => api.get(`/chat/private/getUserResponses/${userId}`),
};

const apiSearch = {
    searchByName: (name) => {
        if (!name) {
            throw new Error('Name parameter is required for search');
        }
        return api.get(`/api/public/autocomplete`, { params: { input: name } });
    },
    getPoints: ( productName,userId) => {
        if (! productName) {
            throw new Error('productName parameter is required for search');
        }
        return api.post("/api/public/getExistingPoints", { productName, userId });
    },
    getPartners: ( productName) => {
 
        return api.post("/partner/private/get-partners-by-product-name", { productName });
    },
    getProductCount: () => {
        return api.get("/api/public/distinct-products")
    },
    getUserCount: () => {
        return api.get("/api/public/get-user-count")
    },
    getvalues: () => {
        return api.get("/api/public/getConfigvalue")
    },

    saveNotFoundProduct: (productName) => {
        return api.post("/api/public/save-not-found-product", {productName});
    },

};
const apiMTRecom = {
    getrecommend:()=>api.get('/recommend/private/getCategories'),
    fetchtopCriteres:(category,
        subCategory)=>api.post("/recommend/private/topCriteres",{category,
            subCategory}),
            fetchCriteres: (input, category, subCategory) => 
                api.get("/recommend/private/autocompleteCriteres", { 
                    params: { input, category, subCategory }
                })
 
    
};

const apiPartner = {
    getPartners: () => api.get('/partners'),
};

// Exporter les fonctions d'API
export {baseURL, apiAuth, apiBlog, apiChat, apiSearch, apiMTRecom, apiPartner };
